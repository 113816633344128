import React, { Component } from "react";
import { Modal, Input, message, Button, Select, Alert } from "antd";
import "./index.scss";
import axios from "axios";
import { connect } from "react-redux";
const { Option } = Select;

class AddAnnouncementModal extends Component {
  state = {
    loading: false,
    announcement: {
      name: "",
      text: "",
      link_type: "external",
      url: "",
      category_id: "",
    },
    categories: [],
    libraryModal: {
      visible: false,
      tab: "upload",
    },
    errors: {},
  };

  componentDidUpdate(pp) {
    if (pp.visible !== this.props.visible) {
      this.setState({
        announcement: {
          name: "",
          text: "",
          link_type: "external",
          url: "",
          category_id: "",
        },
      });
    }
    if (
      this.props.visible &&
      this.props.data &&
      JSON.stringify(this.props.data) !== JSON.stringify(pp.data)
    ) {
      const announcement = {
        name: this.props.data.announcement_name || "",
        text: this.props.data.announcement_text || "",
        link_type: this.props.data.announcement_type || "external",
        url: this.props.data.announcement_link || "",
        category_id:
          this.props.data.category_id && this.props.data.category_id["$id"]
            ? this.props.data.category_id["$id"]
            : "",
        edit:
          this.props.data._id && this.props.data._id["$id"]
            ? this.props.data._id["$id"]
            : undefined,
      };
      this.setState({
        announcement,
      });
    }
  }

  fetchCategories() {
    axios({
      url: "/storeapi/get_categories",
      method: "post",
      data: {
        page: 1,
        pageSize: 100000,
        domid: this.props.match.params.domid,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            categories: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  toggleLibraryModal(tab = "upload") {
    this.setState((state) => ({
      libraryModal: {
        ...state.libraryModal,
        visible: !state.libraryModal.visible,
        tab,
      },
    }));
  }

  onChange = (k, v) => {
    this.setState((state) => ({
      announcement: {
        ...state.announcement,
        [k]: v,
      },
    }));
  };

  validate = () => {
    const announcement = this.state.announcement;
    const errors = {};
    if (!announcement.name) {
      errors.name = "This is a required field!";
    }
    if (!announcement.text) {
      errors.text = "This is a required field!";
    }
    if (!announcement.link_type) {
      errors.link_type = "This is a required field!";
    } else {
      if (announcement.link_type === "external") {
        if (!announcement.url) {
          errors.url = "This is a required field!";
        }
      } else if (announcement.link_type === "category") {
        if (!announcement.category_id) {
          errors.category_id = "This is a required field!";
        }
      } else {
        errors.link_type = "This is a required field!";
      }
    }

    this.setState({ errors });
    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };

  onSubmit = () => {
    if (this.validate()) {
      this.setState(
        {
          loading: true,
        },
        () => {
          axios({
            url: "/storeapi/add_announcement_ticker",
            method: "post",
            data: {
              ...this.state.announcement,
              domid: this.props.match.params.domid,
            },
          })
            .then((res) => {
              this.setState({
                loading: false,
              });
              return res;
            })
            .then((res) => {
              if (res.data.success === 1) {
                this.props.toggleAnnouncementModal();
                this.props.reloadList();
              } else {
                message.error(res.data.msg);
              }
            })
            .catch((err) => {
              message.error("There was an error!");
            });
        }
      );
    }
  };

  componentDidMount() {
    this.fetchCategories();
  }

  render() {
    const errors = this.state.errors;
    return (
      <Modal
        title="Create Announcement"
        visible={this.props.visible}
        className="announcementModal"
        onCancel={this.props.toggleAnnouncementModal}
        closable={!this.state.loading}
        maskClosable={false}
        footer={
          this.props.auth.plan.plan_features.ANNOUNCEMENT_TICKERS === 1
            ? [
                <Button
                  key="save"
                  onClick={() => this.onSubmit()}
                  style={{
                    border: "none",
                    background: "#37C627",
                    color: "white",
                  }}
                >
                  Save Announcement
                </Button>,
                <Button
                  key="cancel"
                  onClick={() => this.props.toggleAnnouncementModal()}
                  style={{ border: "1px solid #707070", color: "#707070" }}
                >
                  Cancel
                </Button>,
              ]
            : null
        }
      >
        {this.props.auth.plan.plan_features.ANNOUNCEMENT_TICKERS === 1 ? (
          <div>
            <div
              className={`form-item-group ${errors.name ? "has-error" : ""}`}
            >
              <label className="ant-form-item-label">Announcement Name:</label>
              <Input
                placeholder="Enter announcement name"
                value={this.state.announcement.name}
                onChange={(e) => this.onChange("name", e.target.value)}
              />
              {errors.name && <p className="error-label">{errors.name}</p>}
            </div>

            <div
              className={`form-item-group ${errors.text ? "has-error" : ""}`}
            >
              <label className="ant-form-item-label">Announcement Text:</label>
              <Input
                placeholder="Enter announcement text"
                value={this.state.announcement.text}
                onChange={(e) => this.onChange("text", e.target.value)}
              />
              {errors.text && <p className="error-label">{errors.text}</p>}
            </div>

            <div
              className={`form-item-group ${
                errors.link_type ? "has-error" : ""
              }`}
            >
              <label className="ant-form-item-label">Link Type:</label>
              <Select
                placeholder="Select link type"
                value={this.state.announcement.link_type}
                style={{ width: "100%" }}
                className="products-select"
                onChange={(val) => this.onChange("link_type", val)}
              >
                <Option key="external" value="external">
                  External Link
                </Option>
                <Option key="category" value="category">
                  Category Link
                </Option>
              </Select>
              {errors.link_type && (
                <p className="error-label">{errors.link_type}</p>
              )}
            </div>

            {this.state.announcement.link_type === "external" ? (
              <div
                className={`form-item-group ${errors.url ? "has-error" : ""}`}
              >
                <label className="ant-form-item-label">Link URL:</label>
                <Input
                  placeholder="Enter announcement name"
                  value={this.state.announcement.url}
                  onChange={(e) => this.onChange("url", e.target.value)}
                  onBlur={(e) => {
                    if (!/^https?:\/\//.test(e.target.value)) {
                      this.onChange("url", `http://${e.target.value}`);
                    }
                  }}
                />
                {errors.url && <p className="error-label">{errors.url}</p>}
              </div>
            ) : (
              <div
                className={`form-item-group ${
                  errors.category_id ? "has-error" : ""
                }`}
              >
                <label className="ant-form-item-label">Select Category:</label>
                <Select
                  placeholder="Select category"
                  value={this.state.announcement.category_id}
                  style={{ width: "100%" }}
                  className="products-select"
                  onChange={(val) => this.onChange("category_id", val)}
                >
                  {this.state.categories.map((c) => (
                    <Option key={c._id["$id"]} value={c._id["$id"]}>
                      {c.name}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
          </div>
        ) : (
          <Alert
            message="Error"
            description="Your plan does not allow you to access this feature!"
            type="error"
            showIcon
          />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});
export default connect(mapStateToProps)(AddAnnouncementModal);
