import React, { Component } from "react";
import { Modal, Input, message, Button, Select, Alert } from "antd";
import { connect } from "react-redux";
import "./index.scss";
import axios from "axios";
import { ReactComponent as StarRatingIcon } from "../../../../assets/images/nps/star_rating.svg";
import { ReactComponent as EmojiRatingIcon } from "../../../../assets/images/nps/emoji_rating.svg";
import { ReactComponent as ProgressRatingIcon } from "../../../../assets/images/nps/progress_rating.svg";
import { ReactComponent as ThumbsRatingIcon } from "../../../../assets/images/nps/thumbs_rating.svg";
const { Option } = Select;

class NPSModal extends Component {
  state = {
    loading: false,
    nps: {
      question: "",
      type: "stars",
      catalog: undefined,
    },
    catalogs: [],
    errors: {},
  };

  onChange = (k, v) => {
    this.setState((state) => ({
      nps: {
        ...state.nps,
        [k]: v,
      },
    }));
  };

  componentDidUpdate(pp) {
    if (pp.visible && !this.props.visible) {
      this.setState({
        nps: {
          question: "",
          type: "star",
          catalog: "",
        },
      });
    }
    if (
      this.props.visible &&
      this.props.data &&
      JSON.stringify(this.props.data) !== JSON.stringify(pp.data)
    ) {
      this.setState({
        station: {
          ...this.state.nps,
          ...this.props.data,
        },
      });
    }
  }

  fetchCatalogs() {
    axios({
      url: "/storeapi/get_catalog",
      method: "post",
      data: {
        page: 1,
        pageSize: 100000,
        domid: this.props.match.params.domid,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            catalogs: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount = () => {
    this.fetchCatalogs();
  };

  validate = () => {
    const nps = this.state.nps;
    const errors = {};
    if (!nps.question) {
      errors.question = "This is a required field!";
    }
    if (!nps.type) {
      errors.type = "This is a required field!";
    }
    if (!nps.catalog) {
      errors.catalog = "This is a required field!";
    }

    this.setState({ errors });
    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };

  onSubmit = () => {
    if (this.validate()) {
      this.setState(
        {
          loading: true,
        },
        () => {
          axios({
            url: "/storeapi/add_nps",
            method: "post",
            data: {
              domid: this.props.match.params.domid,
              ...this.state.nps,
            },
          })
            .then((res) => {
              this.setState({
                loading: false,
              });
              return res;
            })
            .then((res) => {
              if (res.data.success === 1) {
                this.props.toggleNPSModal();
                this.props.reloadList();
              } else {
                message.error(res.data.msg);
              }
            })
            .catch((err) => {
              message.error("There was an error!");
            });
        }
      );
    }
  };

  render() {
    const errors = this.state.errors;
    return (
      <Modal
        title={
          <>
            <h1>Configure NPS</h1>
            <p>Add questions/ feedback that you want to ask your customers:</p>
          </>
        }
        visible={this.props.visible}
        className="npsModal"
        onCancel={this.props.toggleNPSModal}
        maskClosable={false}
        footer={null}
      >
        {this.props.auth.plan.plan_features.NPS === 1 ? (
          <div>
            <div
              className={`form-item-group ${
                errors.question ? "has-error" : ""
              }`}
            >
              <label className="ant-form-item-label">Add Question:</label>
              <Input
                placeholder="Add a new feedback question"
                value={this.state.nps.question}
                onChange={(e) => this.onChange("question", e.target.value)}
              />
              {errors.question && (
                <p className="error-label">{errors.question}</p>
              )}
            </div>

            <div
              className={`form-item-group ${errors.type ? "has-error" : ""}`}
            >
              <label className="ant-form-item-label">
                Select Question Type:
              </label>
              <Select
                placeholder="Select rating"
                value={this.state.nps.type}
                onChange={(val) => this.onChange("type", val)}
                style={{ width: "100%" }}
              >
                <Option value="stars">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span>Stars</span>
                    <span style={{ flex: 1, textAlign: "right" }}>
                      <StarRatingIcon />
                    </span>
                  </div>
                </Option>
                <Option value="emoji">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span>Emoji</span>
                    <span style={{ flex: 1, textAlign: "right" }}>
                      <EmojiRatingIcon />
                    </span>
                  </div>
                </Option>
                <Option value="rating">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span>Rating</span>
                    <span style={{ flex: 1, textAlign: "right" }}>
                      <ProgressRatingIcon />
                    </span>
                  </div>
                </Option>
                <Option value="thumbs">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span>Thumbs</span>
                    <span style={{ flex: 1, textAlign: "right" }}>
                      <ThumbsRatingIcon />
                    </span>
                  </div>
                </Option>
              </Select>
              {errors.type && <p className="error-label">{errors.type}</p>}
            </div>

            <div
              className={`form-item-group ${errors.catalog ? "has-error" : ""}`}
            >
              <label className="ant-form-item-label">
                Add to following catalog:
              </label>
              <Select
                placeholder="Select catalog"
                value={this.state.nps.catalog}
                onChange={(val) => this.onChange("catalog", val)}
                style={{ width: "100%" }}
              >
                {this.state.catalogs.map((catalog) => (
                  <Option value={catalog._id["$id"]}>{catalog.name}</Option>
                ))}
              </Select>
              {errors.catalog && (
                <p className="error-label">{errors.catalog}</p>
              )}
            </div>

            <div>
              <Button
                size="large"
                type="primary"
                style={{
                  border: "none",
                  background: "#37C627",
                  color: "white",
                  width: "100%",
                  fontWeight: 500,
                }}
                loading={this.state.loading}
                onClick={() => this.onSubmit()}
              >
                Save & add to catalog
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <Alert
              message="Error"
              description="Your plan does not allow you to create NPS Questions!"
              type="error"
              showIcon
            />
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});
export default connect(mapStateToProps)(NPSModal);
