import React, { Component } from "react";
import { Modal, Input, message, Button, Select } from "antd";
import "./index.scss";
import axios from "axios";
const { Option } = Select;

class AddWebhookModal extends Component {
  state = {
    loading: false,
    webhook: {
      type: "ALL",
      url: "",
    },
    errors: {},
  };

  onChange = (k, v) => {
    this.setState((state) => ({
      webhook: {
        ...state.webhook,
        [k]: v,
      },
    }));
  };

  componentDidUpdate(pp) {
    if (pp.visible !== this.props.visible) {
      this.setState({
        loading: false,
        webhook: {
          type: "ALL",
          url: "",
        },
        errors: {},
      });
    }
    if (
      this.props.visible &&
      this.props.data &&
      JSON.stringify(this.props.data) !== JSON.stringify(pp.data)
    ) {
      this.setState({
        webhook: {
          ...this.state.webhook,
          ...this.props.data,
        },
      });
    }
  }

  validate = () => {
    const webhook = this.state.webhook;
    const errors = {};
    if (!webhook.url) {
      errors.url = "This is a required field!";
    }
    if (!webhook.type) {
      errors.type = "This is a required field!";
    }

    this.setState({ errors });
    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };

  onSubmit = () => {
    if (this.validate()) {
      this.setState(
        {
          loading: true,
        },
        () => {
          axios({
            url: "/storeapi/add_webhook",
            method: "post",
            data: {
              ...this.state.webhook,
              domid: this.props.match.params.domid,
            },
          })
            .then((res) => {
              this.setState({
                loading: false,
              });
              return res;
            })
            .then((res) => {
              if (res.data.success === 1) {
                message.success(
                  `${
                    this.state.webhook.edit ? "Updated" : "Added"
                  } Successfully!`
                );
                this.props.toggleWebhookModal();
                this.props.reloadList();
              } else {
                message.error(res.data.msg);
              }
            })
            .catch((err) => {
              message.error("There was an error!");
            });
        }
      );
    }
  };

  render() {
    const errors = this.state.errors;
    return (
      <Modal
        title={`${this.state.webhook.edit ? "Edit" : "Add"} Webhook`}
        visible={this.props.visible}
        className="categoryModal"
        onCancel={this.props.toggleWebhookModal}
        closable={false}
        footer={[
          <Button
            key="submit"
            onClick={() => this.onSubmit()}
            style={{ border: "none", background: "#37C627", color: "white" }}
          >
            {this.state.webhook.edit ? "Update" : "Add"} Webhook
          </Button>,
          <Button
            key="cancel"
            onClick={() => this.props.toggleWebhookModal()}
            style={{ border: "1px solid #707070", color: "#707070" }}
          >
            Cancel
          </Button>,
        ]}
      >
        <div>
          <div className={`form-item-group`}>
            <label className="ant-form-item-label">Event Type:</label>
            <Select
              placeholder="Select Event"
              value={this.state.webhook.type}
              onChange={(val) => this.onChange("type", val)}
              style={{ width: "100%" }}
            >
              <Option value="ALL">All</Option>
              <Option value="ORDER_CREATED">Order Created</Option>
              <Option value="ORDER_UPDATED">Order Status Updated</Option>
              <Option value="ORDER_PAYMENT_UPDATED">
                Order Payment Status Updated
              </Option>
            </Select>
          </div>

          <div className={`form-item-group ${errors.url ? "has-error" : ""}`}>
            <label className="ant-form-item-label">URL:</label>
            <Input
              placeholder="Webhook URL"
              value={this.state.webhook.url}
              onChange={(e) => this.onChange("url", e.target.value)}
              onBlur={(e) => {
                if (
                  e.target.value.trim().length > 0 &&
                  !/^https?:\/\//.test(e.target.value)
                ) {
                  this.onChange("url", "https://" + e.target.value);
                }
              }}
            />
            {errors.url && <p className="error-label">{errors.url}</p>}
          </div>
        </div>
      </Modal>
    );
  }
}

export default AddWebhookModal;
