import React, { Component } from "react";
import MainHeadingBar from "../Partials/MainHeadingBar";
import {
  Row,
  Skeleton,
  Alert,
  Button,
  Dropdown,
  Icon,
  Menu,
  Card,
  Table,
  Pagination,
  Switch,
  Modal,
  message,
} from "antd";
import { connect } from "react-redux";
import "./index.scss";
import Axios from "axios";
import NPSModal from "./NPSModal";
import { ReactComponent as StarRatingIcon } from "../../../assets/images/nps/star_rating.svg";
import { ReactComponent as EmojiRatingIcon } from "../../../assets/images/nps/emoji_rating.svg";
import { ReactComponent as ProgressRatingIcon } from "../../../assets/images/nps/progress_rating.svg";
import { ReactComponent as ThumbsRatingIcon } from "../../../assets/images/nps/thumbs_rating.svg";
import { Link, Redirect } from "react-router-dom";

const npsTypes = {
  stars: <StarRatingIcon />,
  emoji: <EmojiRatingIcon />,
  rating: <ProgressRatingIcon />,
  thumbs: <ThumbsRatingIcon />,
};
class NPS extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: "",
    nps: [],
    paging: {
      page: 1,
      total: 0,
      pageSize: 10,
      order: -1,
    },
    catalogs: [],
    npsModal: {
      visible: false,
      data: {},
    },
  };

  toggleNPSModal(data = {}) {
    this.setState({
      npsModal: {
        visible: !this.state.npsModal.visible,
        data,
      },
    });
  }

  fetchNPS() {
    this.setState(
      {
        loading: true,
      },
      () => {
        Axios({
          url: "/storeapi/get_nps",
          method: "post",
          data: {
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
            order: this.state.paging.order,
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                nps: res.data.data,
                paging: { ...this.state.paging, total: res.data.total },
              });
            } else {
              this.setState({
                loading: false,
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
              error: true,
              errorMsg: err.message,
            });
          });
      }
    );
  }

  componentDidMount = () => {
    this.fetchNPS();
  };

  goToPage(pagination) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          page: pagination.current,
        },
      },
      () => this.fetchNPS()
    );
  }

  setSortOrder(order) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          order: order === "asc" ? 1 : -1,
        },
      },
      () => this.fetchNPS()
    );
  }

  setNPSStatus(id, status) {
    Modal.confirm({
      content: (
        <div style={{ textAlign: "center" }}>
          <Icon type="warning" theme="filled" style={{ color: "#FFCC5C" }} />
          <h3 className="heading">
            {status === 1 ? "Enable" : "Disable"} This NPS?
          </h3>
          <p className="content">
            Are you sure you want to {status === 1 ? "enable" : "disable"} this
            nps?
          </p>
        </div>
      ),
      className: "product-status-change-confirm-modal",
      icon: <span />,
      centered: true,
      okText: `${status === 1 ? "Enable" : "Disable"} NPS`,
      cancelText: "Cancel",
      onOk: () => {
        Axios({
          url: "/storeapi/toggle_nps_status",
          method: "post",
          data: {
            id,
            status,
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.fetchNPS();
              message.success("Status updated successfully!");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((err) => {
            message.error("There was an error!");
            console.log(err);
          });
      },
    });
  }

  render() {
    const { loading, error, errorMsg } = this.state;
    if (this.props.auth.domain.role === "store_user") {
      return <Redirect to={`/${this.props.auth.domain.id}/orders`} />;
    }
    const sortMenu = (
      <Menu>
        <Menu.Item key={0}>
          <a href="#/" onClick={() => this.setSortOrder("asc")}>
            Asc. Order
          </a>
        </Menu.Item>
        <Menu.Item key={1}>
          <a href="#/" onClick={() => this.setSortOrder("desc")}>
            Desc. Order
          </a>
        </Menu.Item>
      </Menu>
    );

    const PageWrapper = (children) => (
      <div className="categories-container">
        <MainHeadingBar
          title="NPS"
          pageCTA={
            <span className="sort">
              Sort By Date:{" "}
              <Dropdown overlay={sortMenu}>
                <Button size="default">
                  {this.state.paging.order === 1 ? "Asc." : "Desc."} Order{" "}
                  <Icon type="down" />
                </Button>
              </Dropdown>
            </span>
          }
          cta={
            <>
              <div className="actions">
                <Button size="large" onClick={() => this.toggleNPSModal()}>
                  Create NPS Question
                </Button>
              </div>
            </>
          }
          {...this.props}
        />
        {children}
        <NPSModal
          {...this.props}
          reloadList={() => this.fetchNPS()}
          toggleNPSModal={() => this.toggleNPSModal()}
          visible={this.state.npsModal.visible}
          data={this.state.npsModal.data}
        />
      </div>
    );

    if (loading) {
      return PageWrapper(
        <Card style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </Card>
      );
    }

    if (!loading && error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }
    if (!loading && !error && this.state.nps.length <= 0) {
      return PageWrapper(
        <Card>
          <div className="campaign-empty-state" style={{ textAlign: "center" }}>
            <h3>Here for the first time?</h3>
            <p>Looks like you have not created any NPS questions yet. </p>
            <p>No worries! We've got you covered! </p>
            {this.props.auth.domain.role !== "analyst" && (
              <Button
                type="primary"
                className="red-button"
                onClick={() => this.toggleNPSModal()}
              >
                Create NPS Question
              </Button>
            )}
          </div>
        </Card>
      );
    }
    const columns = [
      {
        title: "Feedback Question",
        dataIndex: "question",
      },
      {
        title: "Answer Type",
        dataIndex: "type",
        render: (type) => npsTypes[type],
      },
      {
        title: "Selected Catalog",
        dataIndex: "catalog",
      },
      {
        title: "Actions",
        dataIndex: null,
        render: (_, row) => (
          <div className="category-actions">
            {this.props.auth.domain.role !== "analyst" && (
              <>
                <p style={{ marginRight: 10 }}>
                  {row.enabled === 1 ? "Enabled" : "Disabled"}
                </p>
                <Switch
                  checked={row.enabled === 1 ? true : false}
                  onChange={() =>
                    this.setNPSStatus(row._id["$id"], row.enabled === 1 ? 0 : 1)
                  }
                />
                <span style={{ margin: 10 }}>|</span>
              </>
            )}
            <Link
              to={`/${this.props.match.params.domid}/nps_answers/${
                row._id["$id"]
              }`}
              style={{ color: "#1655C0" }}
            >
              View Answers
            </Link>
          </div>
        ),
      },
    ];

    const pagination = {
      current: this.state.paging.page,
      pageSize: this.state.paging.pageSize,
      total: this.state.paging.total,
    };

    return PageWrapper(
      <div className="category-list">
        {this.state.nps.map((nps) => (
          <Row gutter={30} style={{ margin: "0 0 30px 0" }}>
            <h1>{nps.catalog}</h1>
            <Table
              columns={columns}
              rowKey={(_, i) => i}
              dataSource={nps.nps}
              pagination={false}
              loading={this.state.loading}
              onChange={(pagination) => this.goToPage(pagination)}
            />
          </Row>
        ))}
        <div style={{ padding: "15px 0", textAlign: "center" }}>
          <Pagination
            {...pagination}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(NPS);
