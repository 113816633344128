import React from "react";
import { Layout, Menu, Icon, Dropdown, Avatar, Tag } from "antd";
import { Link } from "react-router-dom";
import "./index.scss";
import { connect } from "react-redux";
// import { ReactComponent as InfoCircle } from "../../../../assets/images/icons/info-cirlce-o.svg";
import { CDN_URL } from "../../../../config";
import { ROLES } from "../../Settings/UserSettings";

const { Header } = Layout;
const { Item } = Menu;

const Navbar = (props) => {
  const { user, domain, plan } = props;
  const { user_fname } = user;
  let showBonuses = false;
  if (
    domain.role === "owner" &&
    (user.origin === "JVZOO" || user.origin === "THRIVECART")
  ) {
    showBonuses = true;
  }

  const userDropdownMenu = (
    <Menu className="navbar-user-dropdown">
      <Item key="account" onClick={() => props.toggleSwitchAccountModal()}>
        <div className="current-account-details">
          <span className="account-name">
            {domain && domain.dom_name
              ? domain.dom_name
              : user_fname.split(" ")[0] + "'s Domain"}
          </span>
          <Tag color="#FF8200">
            {ROLES[user.user_domains[domain.id]["role"]]}
          </Tag>
        </div>
        <p className="switch-account-label">
          <b>Switch Account</b>
        </p>
      </Item>
      {/* <Item key="plan">Plan: {props.plan.plan_name.join(" + ")}</Item> */}
      <Item key="profile_settings">
        <Link to="/settings">
          Profile Settings <Icon type="user" />
        </Link>
      </Item>
      {showBonuses && (
        <Item key="bonuses">
          <Link to="/bonus">
            Bonuses <Icon type="gift" />
          </Link>
        </Item>
      )}
      {/* <Item key="add_account">
        <Link to="/onboarding">
          Add Account <Icon type="usergroup-add" />
        </Link>
      </Item>
    */}
      {domain.role === "owner" && (
        <Item key="add_user">
          <Link to={`/${domain.id}/adduser`}>
            Add User <Icon type="usergroup-add" />
          </Link>
        </Item>
      )}
      {(plan.plan_features.DOM_COUNT > 1 ||
        plan.plan_features.DOM_COUNT === -1) && (
        <Item key="manage_accounts">
          <Link to="/manage-accounts">
            Manage Accounts <Icon type="usergroup-add" />
          </Link>
        </Item>
      )}
      {/* {user.origin && user.origin === "PG" && (
        <Item key="activation_codes">
          <Link to="/activation-codes">
            Activation Codes <Icon type="key" />
          </Link>
        </Item>
      )} */}
      <Item key="signouy">
        <Link to="/logout">
          Sign Out <Icon type="poweroff" />
        </Link>
      </Item>
    </Menu>
  );

  return (
    <Header className="navbar autoresponder">
      <div className="logo">
        <Link
          to={`/${domain.id}/${
            domain.role === "store_user" ? "orders" : "snapshot"
          }`}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={`${CDN_URL}global/images/${
                props.auth.host.logos.small_logo
              }`}
              alt="logo"
              style={{ width: 120, padding: 0, maxWidth: "unset" }}
            />
          </div>
        </Link>
      </div>

      <Menu className="left-menu" theme="dark" mode="horizontal">
        {domain.role !== "store_user" && (
          <Item key={1}>
            <Link to={`/${domain.id}/snapshot`}>Snapshot</Link>
          </Item>
        )}
        {domain.role !== "store_user" && (
          <Item key={4}>
            <Dropdown
              placement="bottomCenter"
              overlay={
                <Menu className="navbar-user-dropdown">
                  <Item key={1}>
                    <Link to={`/${domain.id}/catalog`}>Catalog</Link>
                  </Item>
                  <Item key={3}>
                    <Link to={`/${domain.id}/categories`}>Categories</Link>
                  </Item>
                  <Item key={2}>
                    <Link to={`/${domain.id}/products`}>Products</Link>
                  </Item>
                  <Item key={4}>
                    <Link to={`/${domain.id}/announcements`}>
                      Announcements
                    </Link>
                  </Item>
                  <Item key={5}>
                    <Link to={`/${domain.id}/nps`}>NPS</Link>
                  </Item>
                  <Item key={6}>
                    <Link to={`/${domain.id}/announcement_tickers`}>
                      Announcement Tickers
                    </Link>
                  </Item>
                  <Item key={7}>
                    <Link to={`/${domain.id}/coupons`}>Coupons</Link>
                  </Item>
                </Menu>
              }
              trigger={["hover"]}
            >
              <Link className="ant-dropdown-link" to={`/${domain.id}/catalog`}>
                Catalog
              </Link>
            </Dropdown>
          </Item>
        )}
        {domain.role !== "store_user" && (
          <Item key={5}>
            <Link to={`/${domain.id}/stations`}>Stations</Link>
          </Item>
        )}
        <Item key={6}>
          <Link to={`/${domain.id}/orders`}>Orders</Link>
        </Item>
        <Item key={7}>
          <Link to={`/${domain.id}/customers`}>Customers</Link>
        </Item>
        {/* <Item key={4}>
          <Link to={`/${domain.id}/autoresponder/contacts`}>
            List & Contacts
          </Link>
        </Item>
        <Item key={5}>
          <Link to={`/${domain.id}/autoresponder/smtps`}>Your SMTPs</Link>
        </Item> */}
        {domain.role !== "store_user" && (
          <Item key={8}>
            <Dropdown
              placement="bottomCenter"
              overlay={
                <Menu className="navbar-user-dropdown">
                  <Item key={41}>
                    <Link to={`/${domain.id}/library`}>Your Library</Link>
                  </Item>
                  <Item key={44}>
                    <Link to={`/${domain.id}/library/search-gifs`}>
                      Search GIFs
                    </Link>
                  </Item>
                </Menu>
              }
              trigger={["hover"]}
            >
              <Link className="ant-dropdown-link" to={`/${domain.id}/library`}>
                Library
              </Link>
            </Dropdown>
          </Item>
        )}
        {/* {domain.role !== "store_user" && (
          <Item key={9}>
            <Dropdown
              placement="bottomCenter"
              overlay={
                <Menu className="navbar-user-dropdown">
                  <Item key={51}>
                    <Link to={`/${domain.id}/tools/qrcode`}>
                      QR Code Generator
                    </Link>
                  </Item>
                  <Item key={52}>
                    <Link to={`/dfy-cue-cards`}>
                      DFY Cue Cards <Icon type="gift" />
                    </Link>
                  </Item>
                  <Item key={52}>
                    <Link to={`/dfy-catalog`}>
                      DFY Catalog Templates <Icon type="gift" />
                    </Link>
                  </Item>
                </Menu>
              }
              trigger={["hover"]}
            >
              <Link
                className="ant-dropdown-link"
                to={`/${domain.id}/tools/qrcode`}
              >
                Tools
              </Link>
            </Dropdown>
          </Item>
        )} */}
        {(domain.role === "admin" || domain.role === "owner") && (
          <Item key={10}>
            <Dropdown
              placement="bottomCenter"
              overlay={
                <Menu className="navbar-user-dropdown">
                  <Item key={51}>
                    <Link to={`/${domain.id}/settings/accounts`}>
                      Domain Profile
                    </Link>
                  </Item>
                  <Item key={54}>
                    <Link to={`/${domain.id}/settings/integrations`}>
                      Integrations
                    </Link>
                  </Item>
                  <Item key={52}>
                    <Link to={`/${domain.id}/settings/payments`}>
                      Payment Gateways
                    </Link>
                  </Item>
                  <Item key={53}>
                    <Link to={`/${domain.id}/settings/domains`}>
                      Custom Domain
                    </Link>
                  </Item>
                  <Item key={54}>
                    <Link to={`/${domain.id}/settings/invoice`}>Invoices</Link>
                  </Item>
                  <Item key={54}>
                    <Link to={`/${domain.id}/settings/translations`}>
                      Translations
                    </Link>
                  </Item>
                  <Item key={55}>
                    <Link to={`/${domain.id}/settings/qrcode`}>
                      Custom QR Code
                    </Link>
                  </Item>
                </Menu>
              }
              trigger={["hover"]}
            >
              <Link
                className="ant-dropdown-link"
                to={`/${domain.id}/settings/accounts`}
              >
                Settings
              </Link>
            </Dropdown>
          </Item>
        )}
      </Menu>

      <Menu className="right-menu" theme="dark" mode="horizontal">
        {domain.role === "owner" && (
          <Item key="getting-started">
            <a
              id="geminii-getting-started"
              href="#!"
              onClick={() => props.toggleCampaignStepsModal()}
            >
              Getting Started
            </a>
          </Item>
        )}

        {/* {domain.role === "owner" &&
          (plan.plan_features.AGENCY === 1 ||
            plan.plan_features.BIZFINDER === 1 ||
            plan.plan_features["3IN1TOOL"] === 1) && (
            <Item key={65}>
              <Dropdown
                overlay={
                  <Menu className="navbar-user-dropdown">
                    {domain.role === "owner" &&
                      plan.plan_features.AGENCY === 1 && (
                        <Item key="agency">
                          <Link to={`/${domain.id}/agency-toolkit`}>
                            Agency Toolkit <Icon type="gift" />
                          </Link>
                        </Item>
                      )}
                    {domain.role === "owner" &&
                      (plan.plan_features.BIZFINDER === 1 ||
                        plan.plan_features["3IN1TOOL"] === 1) && (
                        <Item key="cft">
                          <Link to={`/${domain.id}/bonus-access-credentials`}>
                            Client Finder Tools <Icon type="gift" />
                          </Link>
                        </Item>
                      )}
                  </Menu>
                }
                trigger={["hover"]}
              >
                <a
                  className="ant-dropdown-link"
                  href="#!"
                  style={{ fontSize: "1.05rem" }}
                >
                  Addons
                </a>
              </Dropdown>
            </Item>
          )} */}
        {/* <Item key={6}>
          <Dropdown
            overlay={
              <Menu className="navbar-user-dropdown">
                <Item key="kb">
                  <a
                    href={props.auth.host.kb_url}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Knowledgebase
                  </a>
                </Item>
                <Item key="support">
                  <a
                    href={props.auth.host.support_url}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Support
                  </a>
                </Item>
              </Menu>
            }
            trigger={["hover"]}
          >
            <a
              className="ant-dropdown-link"
              href="#!"
              style={{ fontSize: "1.05rem" }}
            >
              <Icon component={InfoCircle} style={{ margin: "-5px 5px 0 0" }} />{" "}
              Help
            </a>
          </Dropdown>
        </Item> */}

        <Item key={7}>
          <Dropdown overlay={userDropdownMenu} trigger={["hover"]}>
            <a className="ant-dropdown-link" href="#/">
              <Avatar
                style={{ backgroundColor: "#FC4747", verticalAlign: "middle" }}
                size="large"
              >
                {user_fname[0]}
              </Avatar>

              <span className="avatar-details">
                {user_fname}
                <br />
                <span className="domain-name">
                  {domain && domain.dom_name
                    ? domain.dom_name
                    : user_fname.split(" ")[0] + "'s Domain"}
                </span>
              </span>
            </a>
          </Dropdown>
        </Item>
      </Menu>
    </Header>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});
export default connect(mapStateToProps)(Navbar);
