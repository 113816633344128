import React, { Component } from "react";
import "./index.scss";
import { Modal, Button, Icon, Alert } from "antd";
import { ReactComponent as ArrowRight } from "../../assets/images/icons/arrowright.svg";
import { connect } from "react-redux";
import OnboardingForm from "./OnboardingForm";
import { ReactComponent as OnboardingSuccessIllustration } from "../../assets/images/geminii/onboarding_success.svg";
import { ReactComponent as Cross } from "../../assets/images/icons/cross-circle-filled.svg";
import { Link, Redirect } from "react-router-dom";
import { addDomain } from "../../helpers/ajax/onboardingAjax";
import OnboardingFooter from "../Dashboard/Partials/OnboardingFooter";
import { CDN_URL } from "../../config";

class Onboarding extends Component {
  state = {
    welcomeModalVisible: true,
    successModalVisible: false,
    failureModalVisible: false,
    errorMsg: "",
    id: "",
  };

  constructor(props) {
    super(props);
    document.title = props.title;
  }

  toggleState = (field) => {
    this.setState({
      [field]: !this.state[field],
    });
  };

  add_domain = (dom_name, dom_domain) => {
    addDomain(dom_name, dom_domain).then((res) => {
      if (res.success === "1" || res.success === 1) {
        this.setState(
          {
            id: res.id,
          },
          () => this.toggleState("successModalVisible")
        );
        // setTimeout(
        //   () =>
        //     this.props.history.push(
        //       `/switch-account/${res.id}?showWelcome=true`
        //     ),
        //   2000
        // );
      } else {
        this.setState(
          {
            ...this.state,
            errorMsg: res.reason,
          },
          () => this.toggleState("failureModalVisible")
        );
      }
    });
  };

  componentDidMount() {
    let firstAccount = true;
    const user = this.props.auth.user;
    if (user.user_domains && Object.keys(user.user_domains).length > 0) {
      firstAccount = false;
    }
    this.setState({
      welcomeModalVisible: firstAccount,
    });
  }

  render() {
    const { user, logged_in } = this.props.auth;
    const plan = this.props.auth.loginplan;

    if (!logged_in) return <Redirect to="/switch-account" />;

    let firstAccount = true;
    let addAllowed = false;
    let ownerAccounts = 0;
    if (user.user_domains && Object.keys(user.user_domains).length > 0) {
      firstAccount = false;

      ownerAccounts = Object.keys(user.user_domains).reduce((prev, d) => {
        if (user.user_domains[d].role === "owner") {
          return prev + 1;
        } else {
          return prev;
        }
      }, 0);
    }
    if (
      ownerAccounts < plan.plan_features.DOM_COUNT ||
      plan.plan_features.DOM_COUNT === -1
    ) {
      addAllowed = true;
    }

    const firstName = this.props.auth.user.user_fname.split(" ")[0];
    return (
      <React.Fragment>
        <div className="onboarding-container">
          <span style={{ position: "absolute", top: 10, right: 10 }}>
            <Link to="/logout">Logout</Link>
          </span>
          {!firstAccount && (
            <span style={{ position: "absolute", top: 10, left: 10 }}>
              <a href="#!" onClick={() => this.props.history.goBack()}>
                <Icon type="arrow-left" /> Back
              </a>
            </span>
          )}
          <div className="onboarding">
            <img
              src={`${CDN_URL}global/images/${
                this.props.auth.host.logos.full_logo
              }`}
              className="logo"
              alt="logo"
            />

            {addAllowed ? (
              this.state.successModalVisible ? (
                <div
                  className="ant-modal onboarding-modal onboarding-success-modal"
                  style={{
                    width: 600,
                    position: "relative",
                    top: "unset",
                  }}
                >
                  <div className="ant-modal-content">
                    <div className="ant-modal-body">
                      <OnboardingSuccessIllustration />
                      <h3 className="heading">
                        {firstName ? `${firstName}, ` : ""} Welcome To{" "}
                        {this.props.auth.host.franchise_name}!
                      </h3>

                      <p className="disclaimer">
                        We are excited to see you here!! With{" "}
                        {this.props.auth.host.franchise_name}, we are focussed
                        to enable any business out there to leverage the
                        "contactless technology" to manage & bring orders to
                        their services / products / online, with our store &
                        order management system.
                        <br />
                        <br />
                        Let's get you going now and we are pumped to see you
                        growing with this. Lots of love & wishes.
                        <br />
                        <br />
                        XOXO,
                        <br />
                        <b>Team {this.props.auth.host.franchise_name}</b>
                      </p>
                      <Button
                        onClick={() =>
                          this.props.history.push(
                            `/switch-account/${this.state.id}`
                          )
                        }
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <OnboardingForm add_domain={this.add_domain} />
              )
            ) : (
              <Alert
                style={{ width: "100%", maxWidth: 500 }}
                message="Error"
                description="Your plan does not allow you to add more accounts"
                type="error"
                showIcon
              />
            )}
            <OnboardingFooter />
          </div>

          <Modal
            className="onboarding-modal onboarding-failure-modal"
            visible={this.state.failureModalVisible}
            footer={null}
            closable={false}
            width={536}
          >
            <Cross />

            <h3 className="heading">Error!</h3>

            <p className="disclaimer">{this.state.errorMsg}</p>

            <Button
              className="below-disclaimer"
              type="default"
              onClick={() => this.toggleState("failureModalVisible")}
            >
              Try Again <Icon component={ArrowRight} />
            </Button>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(Onboarding);
