import React, { Component } from "react";
import MainHeadingBar from "../Partials/MainHeadingBar";
import {
  Row,
  Skeleton,
  Alert,
  Button,
  Dropdown,
  Icon,
  Menu,
  Card,
  Table,
  Switch,
  message,
  Modal,
} from "antd";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import "./index.scss";
import { CDN_URL } from "../../../config";
import Axios from "axios";
import CreateCategoryModal from "./CreateCategoryModal";

class Categories extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: "",
    categories: [],
    paging: {
      page: 1,
      total: 0,
      pageSize: 10,
      order: -1,
    },
    categoryModal: {
      visible: false,
      data: {},
    },
  };

  toggleCategoryModal(data) {
    this.setState({
      categoryModal: {
        visible: !this.state.categoryModal.visible,
        data,
      },
    });
  }

  fetchCategory() {
    this.setState(
      {
        loading: true,
      },
      () => {
        Axios({
          url: "/storeapi/get_categories",
          method: "post",
          data: {
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
            order: this.state.paging.order,
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                categories: res.data.data,
                paging: { ...this.state.paging, total: res.data.total },
              });
            } else {
              this.setState({
                loading: false,
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
              error: true,
              errorMsg: err.message,
            });
          });
      }
    );
  }

  componentDidMount = () => {
    this.fetchCategory();
  };

  goToPage(pagination) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          page: pagination.current,
        },
      },
      () => this.fetchCategory()
    );
  }

  setSortOrder(order) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          order: order === "asc" ? 1 : -1,
        },
      },
      () => this.fetchCategory()
    );
  }

  setCategoryStatus(id, status) {
    Modal.confirm({
      content: (
        <div style={{ textAlign: "center" }}>
          <Icon type="warning" theme="filled" style={{ color: "#FFCC5C" }} />
          <h3 className="heading">
            {status === 1 ? "Enable" : "Disable"} This Category?
          </h3>
          <p className="content">
            Are you sure you want to {status === 1 ? "enable" : "disable"} this
            category?{" "}
            {status === 1
              ? ""
              : " The products in this category would also stop showing up on your website."}
          </p>
        </div>
      ),
      className: "product-status-change-confirm-modal",
      icon: <span />,
      centered: true,
      okText: `${status === 1 ? "Enable" : "Disable"} Category`,
      cancelText: "Cancel",
      onOk: () => {
        Axios({
          url: "/storeapi/set_category_status",
          method: "post",
          data: {
            id,
            status,
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState((state) => ({
                categories: state.categories.map((p) => {
                  if (p._id["$id"] === id) {
                    return { ...p, category_status: status };
                  } else {
                    return p;
                  }
                }),
              }));
              message.success("Status updated successfully!");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((err) => {
            message.error("There was an error!");
            console.log(err);
          });
      },
    });
  }

  render() {
    const { loading, error, errorMsg } = this.state;
    if (this.props.auth.domain.role === "store_user") {
      return <Redirect to={`/${this.props.auth.domain.id}/orders`} />;
    }
    const sortMenu = (
      <Menu>
        <Menu.Item key={0}>
          <a href="#/" onClick={() => this.setSortOrder("asc")}>
            Asc. Order
          </a>
        </Menu.Item>
        <Menu.Item key={1}>
          <a href="#/" onClick={() => this.setSortOrder("desc")}>
            Desc. Order
          </a>
        </Menu.Item>
      </Menu>
    );

    const PageWrapper = (children) => (
      <div className="categories-container">
        <MainHeadingBar
          title="Categories"
          pageCTA={
            <span className="sort">
              Sort By Date:{" "}
              <Dropdown overlay={sortMenu}>
                <Button size="default">
                  {this.state.paging.order === 1 ? "Asc." : "Desc."} Order{" "}
                  <Icon type="down" />
                </Button>
              </Dropdown>
            </span>
          }
          cta={
            <>
              <div className="actions">
                <Button size="large" onClick={() => this.toggleCategoryModal()}>
                  Create New Category
                </Button>
              </div>
            </>
          }
          {...this.props}
        />
        {children}
        <CreateCategoryModal
          {...this.props}
          reloadList={() => this.fetchCategory()}
          toggleCategoryModal={() => this.toggleCategoryModal()}
          visible={this.state.categoryModal.visible}
          data={this.state.categoryModal.data}
        />
      </div>
    );

    if (loading) {
      return PageWrapper(
        <Card style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </Card>
      );
    }

    if (!loading && error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }
    if (!loading && !error && !this.state.categories.length > 0) {
      return PageWrapper(
        <Card>
          <div className="campaign-empty-state" style={{ textAlign: "center" }}>
            <h3>Here for the first time?</h3>
            <p>Looks like you have not created any categories yet. </p>
            <p>No worries! We've got you covered! </p>
            {this.props.auth.domain.role !== "analyst" && (
              <Button
                className="red-button"
                onClick={() => this.toggleCategoryModal()}
              >
                Create New Category
              </Button>
            )}
          </div>
        </Card>
      );
    }
    const columns = [
      {
        title: "Category Name",
        dataIndex: "image",
        width: "400px",
        render: (_, row) => (
          <div className="category-details">
            <img src={`${CDN_URL}images/${row.icon}`} alt="not found" />
            <p>{row.name}</p>
          </div>
        ),
      },
      {
        title: "Products In Category",
        width: "400px",
        dataIndex: "quantity",
        render: (_, row) => (
          <div className="product-in-category">
            <p>
              Quantity:{" "}
              <span style={{ fontWeight: "600" }}>"{row.product_count}"</span>
            </p>
            <Link
              to={`/${this.props.match.params.domid}/products?category=${
                row._id["$id"]
              }`}
              className="ant-btn ant-btn-primary"
            >
              View Category Products
            </Link>
          </div>
        ),
      },
      {
        title: "Actions",
        dataIndex: "category_status",
        render: (status, row) =>
          this.props.auth.domain.role !== "analyst" ? (
            <div className="category-actions">
              <Switch
                checked={status === 1 ? true : false}
                onChange={() =>
                  this.setCategoryStatus(row._id["$id"], status === 1 ? 0 : 1)
                }
              />{" "}
              <p>{status === 1 ? "Enabled" : "Disabled"}</p>
              <Button
                type="primary"
                onClick={() => this.toggleCategoryModal(row)}
              >
                Edit Category
              </Button>
            </div>
          ) : (
            "No Actions"
          ),
      },
    ];

    const pagination = {
      current: this.state.paging.page,
      pageSize: this.state.paging.pageSize,
      total: this.state.paging.total,
    };

    return PageWrapper(
      <div className="category-list">
        <Row gutter={30}>
          <Table
            columns={columns}
            rowKey={(_, i) => i}
            dataSource={this.state.categories}
            pagination={{
              ...pagination,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            loading={this.state.loading}
            onChange={(pagination) => this.goToPage(pagination)}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(Categories);
