import React, { Component } from "react";
import { Table } from "antd";
import { connect } from "react-redux";
import "./index.scss";
import { formatNumber } from "../../../../helpers/numbers";
import { CDN_URL } from "../../../../config";
import Axios from "axios";

class TopProducts extends Component {
  state = {
    loading: false,
    products: [],
  };

  fetchProducts() {
    this.setState(
      {
        loading: true,
      },
      () => {
        Axios({
          url: "/snapshot/get_top_products",
          method: "post",
          data: {
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                products: res.data.data,
              });
            } else {
              this.setState({
                loading: false,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  }

  componentDidMount = () => {
    this.fetchProducts();
  };

  render() {
    const { loading } = this.state;
    const columns = [
      {
        title: "Product Details",
        dataIndex: "image",
        width: "400px",
        render: (_, row) => {
          return (
            <div className="product-details">
              <img
                src={`${CDN_URL}images/${row.featured_image}`}
                alt="product"
              />
              <div className="product-info">
                <h4>{row.name}</h4>
                <p>{row.code}</p>
              </div>
            </div>
          );
        },
      },
      {
        title: "Orders",
        width: "400px",
        dataIndex: "order_count",
        render: (sales) => formatNumber(sales, 0),
      },
    ];

    return (
      <div className="top-products">
        <h2>Top Products</h2>
        <Table
          columns={columns}
          rowKey={(_, i) => i}
          dataSource={this.state.products}
          pagination={false}
          loading={loading}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(TopProducts);
