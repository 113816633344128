import React, { Component } from 'react';
import './index.scss';
import { Card, Table, Button, Icon, message, Modal, Tooltip } from 'antd';
import { ReactComponent as DeleteReviewIcon } from '../../../assets/images/icons/delete.svg'
import MainHeadingBar from '../Partials/MainHeadingBar';
import { getAccounts, deleteAccount } from '../../../helpers/ajax/accountAjax';

class ManageAccounts extends Component {
  state = {
    accounts: [],
    planAlloted: 0,
    loading: true,
    error: false,
    errorMsg: '',
    editViewsModal: {
      id: undefined,
      visible: false,
      accountName: '',
      value: 0
    }
  };


  fetchAccounts() {
    getAccounts()
      .then(res => this.setState(res));
  }

  componentDidMount() {
    this.fetchAccounts();
  }

  confirmDelete(ids) {
    const that = this;
    Modal.confirm({
      title: 'Are you sure',
      content: 'Are you sure you want to delete the selected review pools(s). Once deleted, review pools cannot be recovered',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        that.deleteSelectedReviewPools(ids);
      }
    });
  }

  confirmDeleteAccount(id) {
    Modal.confirm({
      title: 'Confirm Delete',
      content: 'Are you sure you want to delete this account? Once deleted, your account cannot be recovered!',
      okText: 'Yes',
      cancelText: 'Cancel',
      onOk: () => {
        deleteAccount(id)
          .then(res => {
            if (res.data.success === 1) {
              message.success("Account deleted successfully!");
              this.setState({
                accounts: this.state.accounts.filter((a) => a.id !== id)
              })
            } else {
              message.error(res.data.msg)
            }
          })
          .catch(e => message.error(e.message))
      }
    });
  }

  render() {

    const columns = [{
      title: 'Account Name',
      dataIndex: "name",
    }, {
      title: 'Actions',
      dataIndex: 'id',
      width: '230px',
      render: (_, record) => <div className="actions-div">
        <Tooltip placement="top" title="Delete"><Button type="danger" className="delete-btn" onClick={() => this.confirmDeleteAccount(record.id)}><Icon component={DeleteReviewIcon} />Delete</Button></Tooltip>
      </div>
    }];

    return (
      <div className="manage-accounts">
        <MainHeadingBar title="Manage Accounts" helpText="Manage your accounts added here." hideCTA />
        <Card>
          <Table
            rowKey={record => record.id}
            dataSource={this.state.accounts}
            columns={columns}
            pagination={false}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />
        </Card>
      </div >
    );
  }
}

export default ManageAccounts;