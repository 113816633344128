import React, { Component } from 'react';
import { Modal, Tabs } from 'antd';
import UploadMedia from '../UploadMedia';
import UploadVideo from '../UploadVideo';
import ChooseImageFromLibrary from '../ChooseImageFromLibrary';
import YoutubeS3Uploader from '../YoutubeS3Uploader';
import './index.scss';

const { TabPane } = Tabs;

class LibraryModal extends Component {
  shouldComponentUpdate(props) {
    if (
      this.props.uploadType !== props.uploadType ||
      this.props.visible !== props.visible ||
      this.props.tab !== props.tab ||
      this.props.onChoose !== props.onChoose
    ) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          className="library-modal"
          visible={this.props.visible}
          title="My Library"
          onCancel={() => this.props.onCancel()}
          footer={null}
          destroyOnClose={true}
        >
          <Tabs defaultActiveKey={this.props.tab && ["upload", "library"].indexOf(this.props.tab) !== -1 ? this.props.tab : 'upload'}>
            <TabPane tab="Upload" key="upload">
              {this.props.uploadType === "videonew" ? <UploadVideo {...this.props} /> : <UploadMedia {...this.props} />}
            </TabPane>
            {this.props.uploadType === "videonew" && <TabPane tab="Youtube/S3" key="yts3">
              <YoutubeS3Uploader {...this.props} />
            </TabPane>}
            {!this.props.hidePicker &&
              <TabPane tab="Library" key="library">
                <ChooseImageFromLibrary {...this.props} />
              </TabPane>
            }
          </Tabs>
        </Modal>
      </React.Fragment>
    )
  }

}

export default LibraryModal;