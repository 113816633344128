import React, { Component } from "react";
import "./index.scss";
import "ant-design-pro/dist/ant-design-pro.css";
import OverallStats from "./OverallStats";
import Last30DayStats from "./Last30DayStats";
import TopProducts from "./TopProducts";
import TopCustomers from "./TopCustomers";
// import NextSteps from "./NextSteps";
import { connect } from "react-redux";
import { Skeleton, Alert, Row, Card, Modal, Icon, Button } from "antd";
import MainHeadingBar from "../Partials/MainHeadingBar";
import queryString from "query-string";
import { Redirect } from "react-router-dom";

class Snapshot extends Component {
  state = {
    activation_status: 0,
    product_updates_available: 1,
    loading: false,
    error: false,
    elements: [],
    errorMsg: "",
  };

  render() {
    const { loading, error, errorMsg } = this.state;

    if (this.props.auth.domain.role === "store_user") {
      return <Redirect to={`/${this.props.auth.domain.id}/orders`} />;
    }

    if (loading) {
      return (
        <div style={{ marginBottom: "30px" }}>
          <Card>
            <Skeleton active />
          </Card>
        </div>
      );
    }

    if (!loading && error) {
      return (
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    const parsed = queryString.parse(window.location.search);
    return (
      <div className="snapshot">
        <MainHeadingBar title="Your Summary" cta={<span />} {...this.props} />
        <OverallStats {...this.props} />
        {/* <NextSteps /> */}
        <Last30DayStats {...this.props} />
        <TopProducts {...this.props} />
        <TopCustomers {...this.props} />

        <Modal
          visible={parsed["showWelcome"] === "true"}
          footer={null}
          closable={false}
          width={536}
          style={{ textAlign: "center" }}
        >
          <Icon
            type="check-circle"
            theme="filled"
            style={{ color: "#00a154", fontSize: 60 }}
          />

          <h3 className="heading" style={{ fontSize: 30 }}>
            Success!
          </h3>

          <p className="disclaimer">
            Your account setup is complete. We have allocated 1,000 monthly
            impressions to your account. To manage your monthly impressions you
            can visit the Manage Accounts Page.
          </p>
          <Button
            style={{
              background: "#00a154",
              borderColor: "#00a154",
              color: "#fff",
            }}
            onClick={() => this.props.history.push("/manage-accounts")}
          >
            Manage Accounts
          </Button>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(Snapshot);
