import React, { Component } from "react";
import { Dropdown, Menu } from "antd";
import { connect } from "react-redux";

class FeedbackWidget extends Component {
  render() {
    return (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.auth.host.kb_url}
              >
                Knowledgebase
              </a>
            </Menu.Item>
            <Menu.Item>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.auth.host.support_url}
              >
                Support
              </a>
            </Menu.Item>
          </Menu>
        }
        placement="bottomLeft"
      >
        <a
          href="#!"
          style={{
            position: "fixed",
            bottom: 25,
            right: 25,
            color: "black",
          }}
        >
          <svg
            viewBox="0 0 20 20"
            fill="currentColor"
            style={{ width: 64, height: 64 }}
          >
            <circle cx="10" cy="10" r="8" fill="white" />
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
              clipRule="evenodd"
            />
          </svg>
        </a>
      </Dropdown>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});
export default connect(mapStateToProps)(FeedbackWidget);
