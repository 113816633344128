import React from "react";
import axios from "axios";
import { notification as antNotification } from "antd";
import { connect } from "react-redux";
import { CDN_URL } from "../../../config";

class NewOrderNotifier extends React.Component {
  state = {
    total: undefined,
  };

  fetchOrderRequest() {
    return axios({
      url: "/storeapi/get_orders",
      method: "post",
      data: {
        page: 1,
        pageSize: 1,
        domid: this.props.domid,
        filters: {},
      },
    });
  }

  ordersAutoRefresh() {
    this.autoRefreshTimeoutId = setTimeout(() => {
      this.fetchOrderRequest().then((res) => {
        if (this.state.total === undefined) {
          this.setState({ total: res.data.total }, () =>
            this.ordersAutoRefresh()
          );
        } else {
          if (res.data.total > this.state.total) {
            const newOrderCount = res.data.total - this.state.total;
            this.createNotification(newOrderCount);
            this.setState({ total: res.data.total }, () =>
              this.ordersAutoRefresh()
            );
          } else {
            this.ordersAutoRefresh();
          }
        }
      });
    }, 3000);
  }

  componentWillUnmount() {
    if (this.autoRefreshTimeoutId) {
      clearTimeout(this.autoRefreshTimeoutId);
    }
  }

  checkNotificationPromise() {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }

    return true;
  }

  askNotificationPermission() {
    // function to actually ask the permissions
    function handlePermission(permission) {
      console.log("Push Notification Permission: " + permission);
    }

    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
      console.log("This browser does not support notifications.");
    } else {
      if (Notification.permission === "granted") {
        handlePermission(Notification.permission);
      } else if (Notification.permission !== "denied") {
        if (this.checkNotificationPromise()) {
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              handlePermission(permission);
            }
          });
        } else {
          Notification.requestPermission(function(permission) {
            handlePermission(permission);
          });
        }
      }
    }
  }

  createNotification(count) {
    const audio = new Audio(
      "https://d38dkka8zuv0g4.cloudfront.net/global/audio/tabsound.mp3"
    );
    audio.play();
    if ("Notification" in window && Notification.permission === "granted") {
      const notification = new Notification("New Orders", {
        requireInteraction: true,
        tag: "newOrderNotification",
        body: `You have ${count} new order(s). Click this notification to view your orders`,
        icon: `${CDN_URL}global/images/${
          this.props.auth.host.logos.small_logo
        }`,
      });
      notification.onclick = () => {
        notification.close();
        window.focus();
        if (this.props.location.pathname.includes("/orders")) {
          this.props.history.push(`/${this.props.domid}/`);
          this.props.history.push(`/${this.props.domid}/orders`);
        } else {
          this.props.history.push(`/${this.props.domid}/orders`);
        }
      };
    } else {
      antNotification.destroy();
      antNotification.info({
        message: `New Orders`,
        description: `You have ${count} new order(s). Click this notification to view your orders`,
        placement: "topRight",
        duration: 0,
        onClick: () => {
          antNotification.destroy();
          if (this.props.location.pathname.includes("/orders")) {
            this.props.history.push(`/${this.props.domid}/`);
            this.props.history.push(`/${this.props.domid}/orders`);
          } else {
            this.props.history.push(`/${this.props.domid}/orders`);
          }
        },
      });
    }
  }

  componentDidMount() {
    this.askNotificationPermission();
    this.ordersAutoRefresh();
  }

  render() {
    return <></>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});
export default connect(mapStateToProps)(NewOrderNotifier);
