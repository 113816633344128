import axios from "axios";

export const getOverallStats = (domid) => {
  return axios({
    url: "/snapshot/get_dashboard_daily_stats",
    method: "post",
    data: {
      domid,
    },
  });
};

export const getDaywiseOrders = (domid, startdate, enddate) => {
  return axios({
    url: "/snapshot/order_graph_data",
    method: "post",
    data: {
      domid,
      startdate,
      enddate,
    },
  });
};

export const getDaywiseSales = (domid, startdate, enddate) => {
  return axios({
    url: "/snapshot/sales_graph_data",
    method: "post",
    data: {
      domid,
      startdate,
      enddate,
    },
  });
};

export const getDomainAnalytics = async (domid) => {
  return await axios({
    url: "/snapshot/",
    method: "post",
    data: {
      domid,
    },
  })
    .then((res) => {
      if (res.data.success === 1) {
        return {
          activation_status: res.data.activation_status,
          product_updates_available: res.data.product_updates_available,
          overallStats: res.data.analytics,
          loading: false,
        };
      } else {
        return {
          error: true,
          errorMsg: res.data.msg,
          loading: false,
        };
      }
    })
    .catch((e) => {
      return {
        error: true,
        errorMsg: e.message,
        loading: false,
      };
    });
};

export const getSnapshotStats = async (domid, start_date, end_date) => {
  return await axios({
    url: "/snapshot/get_snapshot_stats",
    method: "post",
    data: {
      domid,
      start_date,
      end_date,
    },
  })
    .then((res) => {
      return {
        unique_engagements: res.data.unique_engagements,
        unique_clicks: res.data.unique_clicks,
        loading: false,
      };
    })
    .catch((e) => {
      return {
        error: true,
        errorMsg: e.message,
        loading: false,
      };
    });
};

export const getLastThreeCampaigns = async (domid) => {
  return await axios({
    url: "/snapshot/get_last_three_campaigns",
    method: "post",
    data: {
      domid,
    },
  })
    .then((res) => {
      return {
        campaigns: res.data.campaigns,
        translations: res.data.translations,
        loading: false,
      };
    })
    .catch((e) => {
      return {
        error: true,
        errorMsg: e.message,
        loading: false,
      };
    });
};

export const getDomainNextSteps = async (domid) => {
  return await axios({
    url: "/snapshot/get_domain_nextsteps",
    method: "post",
    data: {
      domid,
    },
  })
    .then((res) => {
      return {
        nextsteps: res.data.nextsteps,
        loading: false,
      };
    })
    .catch((e) => {
      return {
        error: true,
        errorMsg: e.message,
        loading: false,
      };
    });
};
