import React, { Component } from "react";
import "./index.scss";
import { Modal, Form, Input, Button, Select, Popover, message } from "antd";
import { ReactComponent as CloseIcon } from "../../../../../assets/images/icons/close.svg";
import { ReactComponent as CheckIcon } from "../../../../../assets/images/icons/check-circle.svg";
import { ReactComponent as CrossIcon } from "../../../../../assets/images/icons/cross-circle.svg";
import { addUser, editUser } from "../../../../../helpers/ajax/settingsAjax";

class AddUserForm extends React.Component {
  state = {
    loading: false,
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { name, role, email } = values;
        const { domid, user, closeModal } = this.props;
        this.setState(
          {
            loading: true,
          },
          () => {
            if (this.props.modalType === "add_modal") {
              addUser(domid, name, role, email).then((res) => {
                this.setState({ loading: false });
                if (!res.error) {
                  closeModal();
                } else {
                  message.error(res.errorMsg);
                }
              });
            } else {
              editUser(domid, user.id, role).then((res) => {
                this.setState({ loading: false });
                if (!res.error) {
                  closeModal();
                } else {
                  message.error("Could not edit user!");
                }
              });
            }
          }
        );
      }
    });
  };

  render() {
    const { modalType, user } = this.props;
    const { getFieldDecorator } = this.props.form;
    const adminContent = (
      <div className="role-permissions-data">
        <h3 className="title">Permissions Granted:</h3>
        <p>
          <CheckIcon />
          Super Access
        </p>
        <p>
          <CheckIcon />
          Full Catalog Access
        </p>
        <p>
          <CheckIcon />
          Full Station Access
        </p>
        <p>
          <CheckIcon />
          Full Order Access
        </p>
        <p>
          <CheckIcon />
          Full Customer Access
        </p>
      </div>
    );
    const editorContent = (
      <div className="role-permissions-data">
        <h3 className="title">Permissions Granted:</h3>
        <p>
          <CrossIcon />
          Super Access
        </p>
        <p>
          <CheckIcon />
          Full Catalog Access
        </p>
        <p>
          <CheckIcon />
          Full Station Access
        </p>
        <p>
          <CheckIcon />
          Full Order Access
        </p>
        <p>
          <CheckIcon />
          Full Customer Access
        </p>
      </div>
    );
    const storeUserContent = (
      <div className="role-permissions-data">
        <h3 className="title">Permissions Granted:</h3>
        <p>
          <CrossIcon />
          Super Access
        </p>
        <p>
          <CrossIcon />
          Catalog Access
        </p>
        <p>
          <CrossIcon />
          Station Access
        </p>
        <p>
          <CheckIcon />
          Full Order Access
        </p>
        <p>
          <CheckIcon />
          Full Customer Access
        </p>
      </div>
    );
    const analystContent = (
      <div className="role-permissions-data">
        <h3 className="title">Permissions Granted:</h3>
        <p>
          <CrossIcon />
          Super Access
        </p>
        <p>
          <CheckIcon />
          View Only Catalog Access
        </p>
        <p>
          <CheckIcon />
          View Only Station Access
        </p>
        <p>
          <CheckIcon />
          View Only Order Access
        </p>
        <p>
          <CheckIcon />
          View Only Customer Access
        </p>
      </div>
    );

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item>
          <Input.Group>
            <p className="label">User’s Name</p>
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "User's name is required!" }],
              initialValue: modalType === "edit_modal" ? user.name : "",
            })(
              <Input
                placeholder="Enter User’s Name"
                disabled={modalType === "edit_modal"}
              />
            )}
          </Input.Group>
        </Form.Item>

        <Form.Item>
          <Input.Group>
            <p className="label">Role</p>
            {getFieldDecorator("role", {
              rules: [{ required: true, message: "User's role is required!" }],
              initialValue: modalType === "edit_modal" ? user.role : "admin",
            })(
              <Select className="add-user-role">
                <Select.Option value="admin">
                  <Popover placement="right" content={adminContent}>
                    <p className="option-span">Admin</p>
                  </Popover>
                </Select.Option>
                <Select.Option value="editor">
                  <Popover placement="right" content={editorContent}>
                    <p className="option-span">Manager</p>
                  </Popover>
                </Select.Option>
                <Select.Option value="store_user">
                  <Popover placement="right" content={storeUserContent}>
                    <p className="option-span">Store User</p>
                  </Popover>
                </Select.Option>
                <Select.Option value="analyst">
                  <Popover placement="right" content={analystContent}>
                    <p className="option-span">Analyst</p>
                  </Popover>
                </Select.Option>
              </Select>
            )}
          </Input.Group>
        </Form.Item>

        <Form.Item>
          <Input.Group>
            <p className="label">Email Id</p>
            {getFieldDecorator("email", {
              rules: [
                {
                  type: "email",
                  message: "Invalid email address!",
                },
                {
                  required: true,
                  message: "User's email address is required!",
                },
              ],
              initialValue: modalType === "edit_modal" ? user.email : "",
            })(
              <Input
                placeholder="Enter User’s Email Id"
                disabled={modalType === "edit_modal"}
              />
            )}
          </Input.Group>
        </Form.Item>

        <Form.Item>
          <Button
            className="submit-button"
            type="default"
            size="large"
            htmlType="submit"
            loading={this.state.loading}
          >
            {modalType === "edit_modal" ? "Edit User" : "Invite User"}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
const WrappedAddUserForm = Form.create({ name: "add_user" })(AddUserForm);

class UserModal extends Component {
  render() {
    const { modalVisible, modalType, closeModal, domid, user } = this.props;

    return (
      <Modal
        className="user-modal"
        visible={modalVisible}
        onOk={closeModal}
        title={
          <p className="ant-modal-title custom">
            {modalType === "add_modal" ? "Add New User" : "Edit User"}
            <CloseIcon onClick={closeModal} />
          </p>
        }
        footer={null}
      >
        <WrappedAddUserForm
          modalType={modalType}
          closeModal={closeModal}
          domid={domid}
          user={user}
        />
      </Modal>
    );
  }
}

export default UserModal;
