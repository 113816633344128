import React, { Component } from "react";
import "./index.scss";
import {
  Card,
  Button,
  Icon,
  Row,
  Col,
  message,
  Modal,
  Checkbox,
  Pagination,
  Skeleton,
  Alert,
} from "antd";
import {
  getLibraryMedia,
  deleteLibraryMedia,
} from "../../../helpers/ajax/libraryAjax";
import { ReactComponent as NoDataIcon } from "../../../assets/images/icons/no_data_found.svg";
import { CDN_URL } from "../../../config";
import MainHeadingBar from "../Partials/MainHeadingBar";
import LibraryModal from "./LibraryModal";
import moment from "moment";
const { Meta } = Card;

class LibraryViewer extends Component {
  state = {
    selectedMedia: [],
    media: [],
    total: 0,
    loading: true,
    error: false,
    errorMsg: "",
    paging: {
      pageSize: 12,
      page: 1,
    },
  };

  reloadViewer() {
    this.setState(
      {
        media: [],
        selectedMedia: [],
        paging: {
          pageSize: 12,
          page: 1,
        },
      },
      () => this.fetchLibraryMedia()
    );
  }

  onSelectChange = (selectedMedia) => {
    this.setState({ selectedMedia });
  };

  fetchLibraryMedia() {
    getLibraryMedia(
      this.props.match.params.domid,
      this.state.paging.pageSize,
      (this.state.paging.page - 1) * this.state.paging.pageSize,
      true
    ).then((res) => this.setState(res));
  }

  componentDidMount() {
    this.fetchLibraryMedia();
  }

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;

    this.setState(
      {
        media: [],
        loading: true,
        paging: {
          ...this.state.pagination,
          page: pager.current,
        },
      },
      () => this.fetchLibraryMedia()
    );
  };

  deleteSelectedMedia(ids) {
    deleteLibraryMedia(this.props.match.params.domid, ids)
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            media: this.state.media.filter((r) => ids.indexOf(r._id) === -1),
          });
          message.success("Image(s) deleted successfully!");
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  }

  confirmDelete(ids) {
    const that = this;
    Modal.confirm({
      title: "Are you sure",
      content:
        "Are you sure you want to delete the selected image(s). Once deleted, the image(s) cannot be recovered",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        that.deleteSelectedMedia(ids);
      },
    });
  }

  onSelect(id) {
    const checked =
      this.state.selectedMedia.findIndex((m) => m === id) > -1 ? false : true;
    if (checked) {
      this.setState({
        selectedMedia: [...this.state.selectedMedia, id],
      });
    } else {
      this.setState({
        selectedMedia: this.state.selectedMedia.filter((m) => m !== id),
      });
    }
  }

  selectAll(checked) {
    if (checked) {
      this.setState({
        selectedMedia: this.state.media.map((m) => m._id),
      });
    } else {
      this.setState({
        selectedMedia: [],
      });
    }
  }

  goToPage(page) {
    this.setState(
      {
        media: [],
        selectedMedia: [],
        loading: true,
        paging: {
          ...this.state.paging,
          page,
        },
      },
      () => this.fetchLibraryMedia()
    );
  }

  toggleLibraryModal() {
    this.props.toggleLibraryModal();
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active />;
    }

    if (!this.state.loading && this.state.error) {
      return (
        <Alert
          message="Error"
          description={this.state.errorMsg}
          type="error"
          showIcon
        />
      );
    }

    if (this.state.media.length === 0) {
      return (
        <Row style={{ textAlign: "center" }}>
          <NoDataIcon />
          <p style={{ margin: 0 }}>No Images Found!</p>
        </Row>
      );
    }

    return (
      <React.Fragment>
        <Row style={{ marginBottom: "2.5rem" }}>
          <Checkbox
            defaultChecked={
              this.state.selectedMedia.length === this.state.media
            }
            indeterminate={
              this.state.selectedMedia.length > 0 &&
              this.state.selectedMedia.length < this.state.media.length
            }
            onChange={(e) => this.selectAll(e.target.checked)}
          >
            {" "}
            Select All
          </Checkbox>
          {this.state.selectedMedia.length > 0 && (
            <Button
              style={{
                background: "#F84B3D",
                color: "white",
                marginLeft: "1rem",
              }}
              onClick={() => this.confirmDelete(this.state.selectedMedia)}
            >
              Delete Selected
            </Button>
          )}
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {this.state.media.map((media) => {
            let media_link = `${CDN_URL}images/${media.media_filename}`;
            return (
              <Col xs={24} sm={12} md={6} key={media._id}>
                <Card
                  style={{ margin: "0 auto 2.9rem auto", cursor: "pointer" }}
                  cover={
                    <div
                      style={{
                        background: `url(${media_link})`,
                        height: "100%",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                  }
                  onClick={() => this.onSelect(media._id)}
                >
                  {this.state.selectedMedia.indexOf(media._id) !== -1 && (
                    <div className="selected-overlay" />
                  )}
                  <Meta
                    title={
                      <div className="media-meta">
                        <p>
                          {moment(media.media_created).format("MMM DD, YYYY")}
                        </p>
                        <Checkbox
                          size="large"
                          checked={
                            this.state.selectedMedia.indexOf(media._id) !== -1
                          }
                        />
                      </div>
                    }
                  />
                </Card>
              </Col>
            );
          })}
        </Row>
        <Row style={{ textAlign: "right" }}>
          <Pagination
            current={this.state.paging.page}
            pageSize={this.state.paging.pageSize}
            onChange={(page) => this.goToPage(page)}
            total={this.state.total}
          />
        </Row>
      </React.Fragment>
    );
  }
}

class Library extends Component {
  state = {
    libraryModal: {
      visible: false,
    },
  };

  toggleLibraryModal() {
    this.setState({
      libraryModal: {
        visible: !this.state.libraryModal.visible,
      },
    });
  }

  uploadSuccess() {
    this.refs.imageViewer.reloadViewer();
    this.toggleLibraryModal();
  }

  render() {
    return (
      <div className="library">
        <MainHeadingBar
          title="Library"
          helpText="Here you can manage all your images."
          pageCTA={
            <span>
              <Button
                size="large"
                onClick={() => this.toggleLibraryModal("image")}
              >
                <Icon type="plus" /> Upload
              </Button>
            </span>
          }
          cta={<span />}
          {...this.props}
        />

        <Card>
          <LibraryViewer
            toggleLibraryModal={() => this.toggleLibraryModal()}
            ref="imageViewer"
            {...this.props}
          />
        </Card>

        <LibraryModal
          {...this.props}
          uploadType={this.state.libraryModal.type}
          onCancel={() => this.toggleLibraryModal()}
          visible={this.state.libraryModal.visible}
          hidePicker={true}
          onChoose={() => this.uploadSuccess()}
        />
      </div>
    );
  }
}

export default Library;
