import React, { Component } from "react";
import { Icon, Dropdown, Menu, Button, Input } from "antd";

class FormattingField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultValue ? props.defaultValue : "",
    };
    this.el = React.createRef();
  }

  getInputSelection(el) {
    let start = 0,
      end = 0,
      normalizedValue,
      range,
      textInputRange,
      len,
      endRange;
    // eslint-disable-next-line
    if (
      typeof el.selectionStart == "number" &&
      typeof el.selectionEnd == "number"
    ) {
      start = el.selectionStart;
      end = el.selectionEnd;
    } else {
      range = document.selection.createRange();
      // eslint-disable-next-line
      if (range && range.parentElement() == el) {
        len = el.value.length;
        normalizedValue = el.value.replace(/\r\n/g, "\n");

        // Create a working TextRange that lives only in the input
        textInputRange = el.createTextRange();
        textInputRange.moveToBookmark(range.getBookmark());

        // Check if the start and end of the selection are at the very end
        // of the input, since moveStart/moveEnd doesn't return what we want
        // in those cases
        endRange = el.createTextRange();
        endRange.collapse(false);

        if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
          start = end = len;
        } else {
          start = -textInputRange.moveStart("character", -len);
          start += normalizedValue.slice(0, start).split("\n").length - 1;

          if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
            end = len;
          } else {
            end = -textInputRange.moveEnd("character", -len);
            end += normalizedValue.slice(0, end).split("\n").length - 1;
          }
        }
      }
    }

    return {
      start: start,
      end: end,
    };
  }

  replaceSelectedText(el, text) {
    let sel = this.getInputSelection(el),
      val = el.value;
    return val.slice(0, sel.start) + text + val.slice(sel.end);
  }

  insertTextAtCursor(el, text) {
    let sel = this.getInputSelection(el),
      val = el.value;
    return val.slice(0, sel.start) + text + val.slice(sel.end);
  }

  addFormatting(tag) {
    var bounds = this.getInputSelection(this.el.current.input);
    var selected = this.state.value.substring(bounds.start, bounds.end);
    this.onChange(
      this.replaceSelectedText(
        this.el.current.input,
        "<" + tag + ">" + selected + "</" + tag + ">"
      )
    );
  }

  addPersonalization(p) {
    this.onChange(this.insertTextAtCursor(this.el.current.input, p));
  }

  onChange(value) {
    if (
      this.props.maxChars !== undefined &&
      Number.isInteger(this.props.maxChars) &&
      value.length > this.props.maxChars
    ) {
      value = value.substring(0, this.props.maxChars);
    }
    this.setState(
      {
        value,
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.value);
        }
      }
    );
  }

  componentDidUpdate(pp) {
    if (this.props.value !== pp.value) {
      this.setState({ value: this.props.value });
    }
  }

  render() {
    return (
      <div
        className={`ant-form-item-control ${
          this.props.error ? "has-error" : ""
        }`}
      >
        <p className="form-labels">{this.props.label}</p>
        <Input.Group compact>
          {this.props.field({
            ref: this.el,
            value: this.state.value,
            style: { width: this.props.personalizations ? "70%" : "100%" },
            onChange: (e) => this.onChange(e.target.value),
          })}
          {this.props.personalizations && (
            <Dropdown
              overlay={
                <Menu>
                  {this.props.personalizations.map((p) => (
                    <Menu.Item
                      key={p.tag}
                      onClick={() => this.addPersonalization(p.tag)}
                    >
                      {p.title}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <Button style={{ height: 36, width: "30%" }}>
                {this.props.dropdownLabel || `Add Personalizations`}{" "}
                <Icon type="down" />
              </Button>
            </Dropdown>
          )}
        </Input.Group>
        {this.props.showFormatting && (
          <div>
            <span
              onClick={() => this.addFormatting("b")}
              style={{ cursor: "pointer", marginRight: 7 }}
            >
              <b>B</b>
            </span>
            <span
              onClick={() => this.addFormatting("i")}
              style={{ cursor: "pointer", marginRight: 7 }}
            >
              <i>I</i>
            </span>
            <span
              onClick={() => this.addFormatting("u")}
              style={{ cursor: "pointer", marginRight: 18 }}
            >
              <u>U</u>
            </span>
            <span
              onClick={() => this.addFormatting("h")}
              style={{ cursor: "pointer" }}
            >
              Highlight
            </span>
          </div>
        )}
        {this.props.error && (
          <div className="ant-form-explain">{this.props.error}</div>
        )}
      </div>
    );
  }
}

export default FormattingField;
