import React, { Component } from "react";
import { Form, Input, Select, Button, message, Card } from "antd";
import { Link } from "react-router-dom";
import { CDN_URL } from "../../config";
import { connect } from "react-redux";
import OnboardingFooter from "../Dashboard/Partials/OnboardingFooter";
import "./index.scss";
import { saveSignupQuestions } from "../../helpers/ajax/accountAjax";
import { ISDCodes } from "../../helpers/ISD";

const { Option } = Select;

class UserProfileForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        saveSignupQuestions(values)
          .then((res) => {
            if (res.data.success) {
              this.props.history.push(`/switch-account`);
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => message.error(e.message));
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="onboarding-container">
        <span style={{ position: "absolute", top: 10, right: 10 }}>
          <Link to="/logout">Logout</Link>
        </span>
        <div className="onboarding">
          <img
            src={`${CDN_URL}global/images/${
              this.props.auth.host.logos.full_logo
            }`}
            className="logo"
            alt="logo"
          />

          <Card>
            <Form onSubmit={this.handleSubmit} className="user-profile-form">
              <h3>Complete Your Profile</h3>
              <p>Complete your profile for us to set up an account for you</p>
              <Form.Item>
                <span className="label">Your Contact Number</span>
                {getFieldDecorator("phone", {
                  rules: [],
                })(
                  <Input
                    addonBefore={getFieldDecorator("prefix", {
                      initialValue: "US +1",
                    })(
                      <Select
                        style={{ width: 100 }}
                        showSearch
                        filterOption={true}
                      >
                        {ISDCodes.map((isd) => (
                          <Option
                            key={`${isd.iso2.toUpperCase()} +${isd.dialCode}`}
                          >
                            {isd.iso2.toUpperCase()} +{isd.dialCode}
                          </Option>
                        ))}
                      </Select>
                    )}
                    placeholder="Enter your contact number"
                  />
                )}
              </Form.Item>

              <Form.Item>
                <span className="label">
                  How many people work at your company? *
                </span>
                {getFieldDecorator("company_size", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your company name!",
                    },
                  ],
                })(
                  <Select placeholder="Select a number">
                    <Option value="1">1</Option>
                    <Option value="2-5">2 - 5</Option>
                    <Option value="6-10">6 - 10</Option>
                    <Option value="11-25">11 - 25</Option>
                    <Option value="26-50">26 - 50</Option>
                    <Option value="51-200">51 - 200</Option>
                    <Option value="201-1000">201 - 1,000</Option>
                    <Option value="1001-10000">1,001 - 10,000</Option>
                    <Option value="10000+">10,000+</Option>
                  </Select>
                )}
              </Form.Item>

              <Form.Item>
                <span className="label">
                  Which industry best describes your company? *
                </span>
                {getFieldDecorator("company_niche", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your company size!",
                    },
                  ],
                })(
                  <Select placeholder="Choose an industry">
                    <Option value="Software">Software</Option>
                    <Option value="Internet/Affiliate Marketing">
                      Internet/Affiliate Marketing
                    </Option>
                    <Option value="Marketing Agency/Services">
                      Marketing Agency/Services
                    </Option>
                    <Option value="Consulting/Advisory">
                      Consulting/Advisory
                    </Option>
                    <Option value="Business Services">Business Services</Option>
                    <Option value="eCommerce/Retail">eCommerce/Retail</Option>
                    <Option value="Education">Education</Option>
                    <Option value="Non-Profit">Non-Profit</Option>
                    <Option value="Finance / Insurance">
                      Finance / Insurance
                    </Option>
                    <Option value="Health / Medical">Health / Medical</Option>
                    <Option value="Real Estate / Construction">
                      Real Estate / Construction
                    </Option>
                    <Option value="Hardware or Manufacturing">
                      Hardware or Manufacturing
                    </Option>
                  </Select>
                )}
              </Form.Item>

              <Form.Item>
                <span className="label">
                  Which of the following best describes your role? *
                </span>
                {getFieldDecorator("company_role", {
                  rules: [
                    { required: true, message: "Please input your role!" },
                  ],
                })(
                  <Select placeholder="Choose your role">
                    <Option value="Owner">Owner</Option>
                    <Option value="Executive / Manager ">
                      Executive / Manager
                    </Option>
                    <Option value="Marketer / Content Creator">
                      Marketer / Content Creator
                    </Option>
                    <Option value="Salesperson">Salesperson</Option>
                    <Option value="Customer Service / Support">
                      Customer Service / Support
                    </Option>
                    <Option value="Data / Operations">Data / Operations</Option>
                    <Option value="I do a little of Everything">
                      I do a little of Everything
                    </Option>
                    <Option value="Part Time / Freelance ">
                      Part Time / Freelance{" "}
                    </Option>
                    <Option value="Others">Others</Option>
                  </Select>
                )}
              </Form.Item>

              <Form.Item>
                <Button
                  type="default"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Continue
                </Button>
              </Form.Item>
            </Form>
          </Card>
          <OnboardingFooter />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});
export default Form.create({ name: "user_profile" })(
  connect(mapStateToProps)(UserProfileForm)
);
