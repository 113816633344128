import React, { Component } from "react";
import { Alert } from "antd";

class AutoresponderNoAccess extends Component {
  render() {
    return (
      <div style={{ marginTop: 60 }}>
        <Alert
          message="Upgarde"
          description="Your plan does not allow you to access Autoresponder!"
          type="error"
          showIcon
        />
      </div>
    );
  }
}

export default AutoresponderNoAccess;
