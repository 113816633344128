import React, { Component } from "react";
import "./index.scss";
import {
  Button,
  Divider,
  Row,
  Col,
  Icon,
  Skeleton,
  Alert,
  message,
  Tooltip,
} from "antd";
import { CDN_URL } from "../../../../config";
import LibraryModal from "../../Library/LibraryModal";
import ColorPicker from "../../ColorPicker";
import { connect } from "react-redux";
import axios from "axios";

class CustomQRCode extends Component {
  state = {
    error: false,
    errorMsg: "",
    loading: false,
    saving: false,
    domain: {
      dom_customqrcode: {
        bg: "#fff",
        fg: "#000",
        logo: "",
      },
    },
    libraryModal: {
      visible: false,
      tab: "upload",
    },
  };

  toggleLibraryModal(tab = "upload") {
    this.setState((state) => ({
      libraryModal: {
        ...state.libraryModal,
        visible: !state.libraryModal.visible,
        tab,
      },
    }));
  }

  onChange = (k, v) => {
    this.setState((state) => ({
      domain: {
        ...state.domain,
        dom_customqrcode: {
          ...state.domain.dom_customqrcode,
          [k]: v,
        },
      },
    }));
  };

  onChoose(data) {
    this.onChange("logo", data.media_filename);
    this.toggleLibraryModal();
  }

  fetchSettings() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: "/storeapi/get_store_settings",
          method: "post",
          data: {
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            this.setState({ loading: false });
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                domain: {
                  ...this.state.domain,
                  ...res.data.data,
                },
              });
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((err) => {
            this.setState({
              error: true,
              errorMsg: "There was an error!",
            });
            console.log(err);
          });
      }
    );
  }

  saveSettings() {
    this.setState(
      {
        saving: true,
      },
      () => {
        axios({
          url: "/storeapi/save_custom_qrcode",
          method: "post",
          data: {
            domid: this.props.match.params.domid,
            data: this.state.domain.dom_customqrcode,
          },
        })
          .then((res) => {
            this.setState({ saving: false });
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              message.success("Settings Saved Successfully!");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((err) => {
            message.error("There was an error!");
            console.log(err);
          });
      }
    );
  }

  componentDidMount = () => {
    this.fetchSettings();
  };

  render() {
    const { error, errorMsg, loading } = this.state;
    if (loading) {
      return (
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!loading && error) {
      return (
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }
    const domain = this.state.domain.dom_customqrcode;
    return (
      <div className="account-settings">
        <h3 className="heading">
          <span className="text">
            Custom QR Code{" "}
            <Tooltip title="Here you can manage the settings for your custom QR Code">
              <Icon type="info-circle" style={{ fontSize: "1.5rem" }} />
            </Tooltip>
          </span>
          {this.props.auth.plan.plan_features.CUSTOM_QRCODES === 1 && (
            <Button
              type="default"
              onClick={() => this.saveSettings()}
              loading={this.state.saving}
            >
              Save Settings
            </Button>
          )}
        </h3>

        <Divider />

        {this.props.auth.plan.plan_features.CUSTOM_QRCODES === 1 ? (
          <Row gutter={24}>
            <Col xs={24}>
              <div className="input-container">
                <p className="label">Select Logo:</p>
                <div className="upload-actions">
                  {domain.logo && (
                    <img
                      alt="store logo"
                      src={`${CDN_URL}images/${domain.logo}`}
                      style={{
                        maxWidth: "7.14rem",
                        marginRight: 10,
                        border: "1px solid #ccc",
                      }}
                    />
                  )}
                  <div
                    className="upload-action"
                    onClick={() => this.toggleLibraryModal("upload")}
                  >
                    <p>
                      <Icon type="upload" style={{ color: "#D9D9D9" }} /> <br />
                      Upload
                    </p>
                  </div>
                  <div
                    className="upload-action"
                    onClick={() => this.toggleLibraryModal("library")}
                  >
                    <p>
                      <Icon type="folder-open" style={{ color: "#D9D9D9" }} />{" "}
                      <br />
                      Library
                    </p>
                  </div>
                </div>
              </div>

              <div className="input-container">
                <p className="label">Background Color:</p>
                <ColorPicker
                  label=""
                  defaultValue={domain.bg}
                  onChange={(color) => this.onChange("bg", color)}
                  style={{ display: "inline-block", margin: "0 0 0 10px" }}
                />
              </div>

              <div className="input-container">
                <p className="label">Foreground Color:</p>
                <ColorPicker
                  label=""
                  defaultValue={domain.fg}
                  onChange={(color) => this.onChange("fg", color)}
                  style={{ display: "inline-block", margin: "0 0 0 10px" }}
                />
              </div>
            </Col>
          </Row>
        ) : (
          <Alert
            message="Error"
            description="Your plan does not allow you to access this feature!"
            type="error"
            showIcon
          />
        )}

        <LibraryModal
          {...this.props}
          tab={this.state.libraryModal.tab}
          onCancel={() => this.toggleLibraryModal()}
          visible={this.state.libraryModal.visible}
          onChoose={(data) => this.onChoose(data)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(CustomQRCode);
