import React, { Component } from "react";
import { Modal, Input, message, Button } from "antd";
import "./index.scss";
import axios from "axios";

class CreateStationModal extends Component {
  state = {
    loading: false,
    station: {
      station_name: "",
      station_code: "",
    },
    errors: {},
  };

  onChange = (k, v) => {
    this.setState((state) => ({
      station: {
        ...state.station,
        [k]: v,
      },
    }));
  };

  componentDidUpdate(pp) {
    if (pp.visible !== this.props.visible) {
      this.setState({
        station: {
          station_name: "",
          station_code: "",
        },
      });
    }
    if (
      this.props.visible &&
      this.props.data &&
      JSON.stringify(this.props.data) !== JSON.stringify(pp.data)
    ) {
      this.setState({
        station: {
          ...this.state.station,
          ...this.props.data,
        },
      });
    }
  }

  validate = () => {
    const station = this.state.station;
    const errors = {};
    if (!station.station_name) {
      errors.station_name = "This is a required field!";
    }
    if (!station.station_code) {
      errors.station_code = "This is a required field!";
    }

    this.setState({ errors });
    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };

  onSubmit = () => {
    if (this.validate()) {
      this.setState(
        {
          loading: true,
        },
        () => {
          axios({
            url: "/storeapi/add_station",
            method: "post",
            data: {
              station: this.state.station,
              domid: this.props.match.params.domid,
            },
          })
            .then((res) => {
              this.setState({
                loading: false,
              });
              return res;
            })
            .then((res) => {
              if (res.data.success === 1) {
                this.props.toggleStationModal();
                this.props.reloadList();
              } else {
                message.error(res.data.msg);
              }
            })
            .catch((err) => {
              message.error("There was an error!");
            });
        }
      );
    }
  };

  render() {
    const errors = this.state.errors;
    return (
      <Modal
        title={`${this.state.station.edit ? "Update" : "Create"} Station`}
        visible={this.props.visible}
        className="categoryModal"
        onCancel={this.props.toggleStationModal}
        closable={false}
        footer={[
          <Button
            onClick={() => this.onSubmit()}
            style={{ border: "none", background: "#37C627", color: "white" }}
          >
            {this.state.station.edit ? "Update" : "Save"} Station
          </Button>,
          <Button
            onClick={() => this.props.toggleStationModal()}
            style={{ border: "1px solid #707070", color: "#707070" }}
          >
            Cancel
          </Button>,
        ]}
      >
        <div>
          <div
            className={`form-item-group ${
              errors.station_name ? "has-error" : ""
            }`}
          >
            <label className="ant-form-item-label">Station Name:</label>
            <Input
              placeholder="Enter station name"
              value={this.state.station.station_name}
              onChange={(e) => this.onChange("station_name", e.target.value)}
            />
            {errors.station_name && (
              <p className="error-label">{errors.name}</p>
            )}
          </div>

          <div
            className={`form-item-group ${
              errors.station_code ? "has-error" : ""
            }`}
          >
            <label className="ant-form-item-label">Station Code:</label>
            <Input
              placeholder="Enter station code"
              value={this.state.station.station_code}
              onChange={(e) => this.onChange("station_code", e.target.value)}
            />
            {errors.station_code && (
              <p className="error-label">{errors.name}</p>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default CreateStationModal;
