import React from "react";
import { connect } from "react-redux";
import "./index.scss";

const Footer = (props) => {
  return (
    <footer
      className="app-footer"
      style={{ textAlign: props.centerFooter ? "center" : "left" }}
    >
      <ul>
        {/* <li><a href="#/">Getting Started</a></li> */}
        <li>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={props.auth.host.support_url}
          >
            Support
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={props.auth.host.tnc_url}
          >
            Terms of Service
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={props.auth.host.privacypolicy_url}
          >
            Privacy Policy
          </a>
        </li>
      </ul>
      <div id="google_translate_element" style={{ textAlign: "center" }} />
    </footer>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});
export default connect(mapStateToProps)(Footer);
