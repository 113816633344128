import React from "react";
import "./index.scss";
import { connect } from "react-redux";
import { CDN_URL } from "../../../config";

const FormContainer = (props) => {
  return (
    <div className="login-form-container">
      <div className="content-container">
        <img
          src={`${CDN_URL}global/images/${props.auth.host.logos.full_logo}`}
          className="logo"
          alt="logo"
        />

        <div className="content">{props.children}</div>

        <div className="footer">
          <p style={{ textAlign: "center" }}>
            Copyright &copy; 2020 {props.auth.host.franchise_name}
          </p>
          <div className="links">
            <a
              href={props.auth.host.tnc_url}
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms
            </a>
            <a
              href={props.auth.host.privacypolicy_url}
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy
            </a>
            <a
              href={props.auth.host.support_url}
              rel="noopener noreferrer"
              target="_blank"
            >
              Help
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});
export default connect(mapStateToProps)(FormContainer);
