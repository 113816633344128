import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import { Row, Col, Form, Icon, Input, Button, Alert } from "antd";
import { forgotPassword } from "../../../helpers/ajax/authAjax";
import FormContainer from "../FormContainer";

class ForgotPassword extends Component {
  state = {
    success: "",
    error: "",
    loading: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true }, () => {
          forgotPassword(values.email)
            .then(() => {
              this.setState({
                success:
                  "Please check your inbox for password reset instructions",
                error: "",
                loading: false,
              });
            })
            .catch((e) => {
              this.setState({
                error: "There was an error sending the password reset mail",
                success: "",
                loading: false,
              });
            });
        });
      }
    });
  };

  componentDidMount() {
    document.title = this.props.title;
  }
  componentWillReceiveProps(props) {
    document.title = props.title;
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Row className="forgot-password-form">
        <Col xs={15} className="sidebar" />
        <Col xs={9} style={{ height: "100%" }}>
          <FormContainer>
            <h5 className="heading">Forgot Password?</h5>

            <hr className="heading-separator" />

            <p className="sub-heading" style={{ fontSize: 16 }}>
              Well, let's fix that! Enter the email address for your account and
              we'll send you an email with the reset link.
            </p>

            <Form onSubmit={this.handleSubmit} className="login-form">
              <Form.Item>
                {getFieldDecorator("email", {
                  rules: [
                    { required: true, message: "Please input your username!" },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Your Registered Email Id"
                  />
                )}
              </Form.Item>

              {this.state.error && (
                <Alert
                  className="ant-row ant-form-item"
                  message={this.state.error}
                  type="error"
                />
              )}
              {this.state.success && (
                <Alert
                  className="ant-row ant-form-item"
                  message={this.state.success}
                  type="success"
                />
              )}

              <Form.Item className="flex-div">
                <Link to="/login">
                  <Icon type="left-circle" />
                </Link>
                <span className="gap" />
                <Button
                  type="primary"
                  htmlType="submit"
                  className="form-button"
                  loading={this.state.loading}
                >
                  Request Link
                </Button>
              </Form.Item>
            </Form>
          </FormContainer>
        </Col>
      </Row>
    );
  }
}

export default Form.create()(ForgotPassword);
