import React, { Component } from "react";
import "./index.scss";
import {
  Button,
  Divider,
  Icon,
  Table,
  Skeleton,
  Row,
  Alert,
  Modal,
  Tooltip,
  message,
} from "antd";
import { ReactComponent as DeleteReviewIcon } from "../../../../assets/images/icons/delete.svg";
import { connect } from "react-redux";
import {
  getWLBLDomains,
  deleteWLBLDomain,
  checkWLBLDomainStatus,
} from "../../../../helpers/ajax/settingsAjax";
import UserModal from "./WhitelabelDomainModal";

class WhitelabelDomains extends Component {
  state = {
    error: false,
    errorMsg: "",
    loading: true,
    doms: [],
    total: 0,
    modalVisible: false,
    modalType: "add_modal",
    userToEdit: {},
  };

  fetch = () => {
    getWLBLDomains(this.props.auth.domain.id).then((res) => {
      this.setState({
        loading: false,
        doms: res.data.domains,
      });
    });
  };

  componentDidMount = () => {
    this.fetch();
  };

  confirmDelete = (id) => {
    Modal.confirm({
      title: "Are you sure",
      content: `Are you sure you want to delete this domain?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () =>
        deleteWLBLDomain(id, this.props.auth.domain.id)
          .then((res) => {
            if (res.data.success === 1) {
              this.fetch();
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
          }),
    });
  };

  checkStatus = (id) => {
    checkWLBLDomainStatus(id, this.props.auth.domain.id)
      .then((res) => {
        if (res.data.success === 1) {
          this.fetch();
          message.success("Domain Pointed Successfully!");
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  changeState = (newState) => {
    this.setState({
      ...this.state,
      ...newState,
    });
  };

  render() {
    const {
      error,
      errorMsg,
      loading,
      modalType,
      modalVisible,
      userToEdit,
    } = this.state;

    if (loading) {
      return (
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!loading && error) {
      return (
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    const columns = [
      {
        title: "Domain Name",
        dataIndex: "domain_name",
      },
      {
        title: "Domain Status",
        dataIndex: "domain_status",
      },
      {
        title: "Actions",
        dataIndex: "id",
        key: "actions",
        render: (_, record) => (
          <div className="actions-div">
            <Tooltip title="Check Status">
              <Button
                type="primary"
                onClick={() => this.checkStatus(record._id["$id"])}
              >
                <Icon type="reload" />
              </Button>
            </Tooltip>
            <Tooltip title="Delete Domain">
              <Button
                type="danger"
                className="delete-btn"
                onClick={() => this.confirmDelete(record._id["$id"])}
              >
                <Icon component={DeleteReviewIcon} />
              </Button>
            </Tooltip>
          </div>
        ),
        width: 200,
      },
    ];

    return (
      <div className="user-settings">
        <h3 className="heading">
          <span className="text">
            Custom Domains{" "}
            <Tooltip
              title={`Here you can add and manage your custom domains for your store.`}
            >
              <Icon type="info-circle" style={{ fontSize: "1.5rem" }} />
            </Tooltip>
          </span>

          <Button
            className="add-user-btn"
            onClick={() => {
              this.changeState({ modalVisible: true, modalType: "add_modal" });
            }}
          >
            <Icon type="plus" size="large" />
            Add New Domain
          </Button>
        </h3>

        <Divider />

        <Table
          className="records-table"
          dataSource={this.state.doms}
          columns={columns}
          pagination={false}
        />

        <Alert
          style={{ marginTop: 30 }}
          message="How To Point your Domain"
          description={
            <div class="whitelabel-instructions">
              <p>
                1. Copy the following URL: wlbl.{this.props.auth.host.wildcard}
              </p>
              <p>2. Navigate to your domain provider</p>
              <p>3. Visit your domain management page</p>
              <p>
                4. Create a new CNAME record for your domain{" "}
                <i>(sometimes referred to as an alias)</i>
              </p>
              <p>
                5. In the name field, set the CNAME to the same value as your
                subdomain{" "}
                <i>
                  (for example, if you selected 'store' as your desired
                  subdomain, your CNAME would also be set to 'store')
                </i>
              </p>
              <p>
                6. In the field labeled value, paste the URL link copied above
              </p>
              <p>
                7. Leave all other options set at their recommended default
                settings
              </p>
            </div>
          }
          type="info"
        />

        <UserModal
          modalType={modalType}
          modalVisible={modalVisible}
          closeModal={() => {
            this.changeState({ modalVisible: false });
            this.fetch();
          }}
          domid={this.props.auth.domain.id}
          user={userToEdit}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(WhitelabelDomains);
