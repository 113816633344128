import React, { Component } from 'react';
import './index.scss';
import { Modal, Form, Input, Button, message } from 'antd';
import { ReactComponent as CloseIcon } from '../../../../../assets/images/icons/close.svg';
import { addWLBLDomain } from '../../../../../helpers/ajax/settingsAjax';

class AddDomainForm extends React.Component {
    state = {
        loading: false
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { domid, closeModal } = this.props;
                this.setState({
                    loading: true
                }, () => {
                    addWLBLDomain(domid, values.domain).then(res => {
                        this.setState({ loading: false });
                        if (res.data.success === 1) {
                            closeModal();
                        } else {
                            message.error(res.data.msg);
                        }
                    }).catch(e => { message.error(e.message) });
                })
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item>
                    <Input.Group>
                        <p className="label">Whitelabel Domain</p>
                        {getFieldDecorator('domain', {
                            rules: [{ required: true, message: 'Please enter a valid domain' }],
                        })(
                            <Input placeholder="Enter your domain" />
                        )}
                    </Input.Group>
                </Form.Item>
                <Form.Item>
                    <Button className="submit-button" type="default" size="large" htmlType="submit" loading={this.state.loading}>
                        Add Domain
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}
const WrappedAddDomainForm = Form.create({ name: 'add_user' })(AddDomainForm);

class WhitelabelDomainModal extends Component {
    render() {
        const { modalVisible, modalType, closeModal, domid, user } = this.props;

        return (
            <Modal
                className="user-modal"
                visible={modalVisible}
                destroyOnClose={true}
                onOk={closeModal}
                title={
                    <p className="ant-modal-title custom">Add New Whitelabel Domain <CloseIcon onClick={closeModal} /></p>
                }
                footer={null}
            >
                <WrappedAddDomainForm modalType={modalType} closeModal={closeModal} domid={domid} user={user} />
            </Modal>
        );
    }
}

export default WhitelabelDomainModal;