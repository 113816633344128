import React, { Component } from "react";
import MainHeadingBar from "../Partials/MainHeadingBar";
import {
  Row,
  Skeleton,
  Alert,
  Button,
  Dropdown,
  Icon,
  Menu,
  Card,
  Table,
  Modal,
  message,
} from "antd";
import { connect } from "react-redux";
import "./index.scss";
import { ReactComponent as EmptyStateImage } from "../../../assets/images/nocontacts.svg";
import CustomerDetailsModal from "./CustomerDetailsModal";
import Axios from "axios";
import moment from "moment-timezone";

class Customers extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: "",
    customers: [],
    paging: {
      page: 1,
      total: 0,
      pageSize: 10,
      order: -1,
    },
    tz: "UTC",
    customerDetailsModal: {
      visible: false,
      id: null,
    },
    // stationModal: {
    //   visible: false,
    // },
  };

  toggleCustomerDetailsModal(id = null) {
    this.setState({
      customerDetailsModal: {
        visible: id ? true : false,
        id,
      },
    });
  }

  fetchCustomers() {
    this.setState(
      {
        loading: true,
      },
      () => {
        Axios({
          url: "/storeapi/get_customers",
          method: "post",
          data: {
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
            order: this.state.paging.order,
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                customers: res.data.data,
                paging: { ...this.state.paging, total: res.data.total },
                tz: res.data.tz,
              });
            } else {
              this.setState({
                loading: false,
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
              error: true,
              errorMsg: err.message,
            });
          });
      }
    );
  }

  componentDidMount = () => {
    this.fetchCustomers();
  };

  goToPage(pagination) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          page: pagination.current,
        },
      },
      () => this.fetchCustomers()
    );
  }

  setSortOrder(order) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          order: order === "asc" ? 1 : -1,
        },
      },
      () => this.fetchCustomers()
    );
  }

  removeCustomer(customer) {
    if (this.props.auth.domain.role === "analyst") {
      return;
    }
    Modal.confirm({
      title: "Remove Customer?",
      content: "Are you sure you want to remove this customer?",
      onOk: () => {
        Axios({
          url: "/storeapi/remove_customer",
          method: "post",
          data: {
            id: customer._id["$id"],
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.fetchCustomers();
              message.success("Customer Removed Successfully!");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((err) => {
            message.error("There was an error!");
            console.log(err);
          });
      },
    });
  }

  render() {
    const { loading, error, errorMsg } = this.state;
    const sortMenu = (
      <Menu>
        <Menu.Item key={0}>
          <a href="#/" onClick={() => this.setSortOrder("asc")}>
            Asc. Order
          </a>
        </Menu.Item>
        <Menu.Item key={1}>
          <a href="#/" onClick={() => this.setSortOrder("desc")}>
            Desc. Order
          </a>
        </Menu.Item>
      </Menu>
    );

    const PageWrapper = (children) => (
      <div className="categories-container">
        <MainHeadingBar
          title="Customer List"
          pageCTA={
            <span className="sort">
              Sort By Date:{" "}
              <Dropdown overlay={sortMenu}>
                <Button size="default">
                  {this.state.paging.order === 1 ? "Asc." : "Desc."} Order{" "}
                  <Icon type="down" />
                </Button>
              </Dropdown>
            </span>
          }
          cta={<span />}
          {...this.props}
        />
        {children}
        {/* <CreateStationModal
          {...this.props}
          reloadList={() => this.fetchCustomers()}
          toggleStationModal={() => this.toggleStationModal()}
          visible={this.state.stationModal.visible}
        /> */}
      </div>
    );

    if (loading) {
      return PageWrapper(
        <Card style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </Card>
      );
    }

    if (!loading && error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }
    if (!loading && !error && !this.state.customers.length > 0) {
      return PageWrapper(
        <Card>
          <div className="order-empty-state" style={{ textAlign: "center" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1, textAlign: "left", paddingLeft: 80 }}>
                <h2>No Customers To Show!</h2>
                <p>
                  Looks like no customers have provided <br />
                  their contact details yet.
                </p>
              </div>
              <div style={{ flex: 1 }}>
                <EmptyStateImage style={{ padding: "23px 0 54px 0" }} />
              </div>
            </div>
          </div>
        </Card>
      );
    }
    const columns = [
      {
        title: "Customer Name",
        dataIndex: "customer_name",
      },
      {
        title: "Email ID",
        dataIndex: "customer_email",
      },
      {
        title: "Contact Number",
        dataIndex: "customer_phone",
      },
      {
        title: "Added On",
        dataIndex: "date_created",
        render: (date) =>
          moment
            .utc(date)
            .tz(this.state.tz)
            .format("DD MMM, YYYY"),
      },
      {
        title: "Actions",
        dataIndex: null,
        render: (_, row) => (
          <div>
            <a
              href="#!"
              style={{ color: "#1655C0" }}
              onClick={() => this.toggleCustomerDetailsModal(row._id["$id"])}
            >
              View Analytics
            </a>{" "}
            {this.props.auth.domain.role !== "analyst" && (
              <>
                |{" "}
                <a
                  href="#!"
                  style={{ color: "#F81E1E" }}
                  onClick={() => this.removeCustomer(row)}
                >
                  Remove Customer
                </a>
              </>
            )}
          </div>
        ),
      },
    ];

    const pagination = {
      current: this.state.paging.page,
      pageSize: this.state.paging.pageSize,
      total: this.state.paging.total,
    };

    return PageWrapper(
      <div className="category-list">
        <Row gutter={30}>
          <Table
            columns={columns}
            rowKey={(_, i) => i}
            dataSource={this.state.customers}
            pagination={{
              ...pagination,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            loading={this.state.loading}
            onChange={(pagination) => this.goToPage(pagination)}
          />
          <CustomerDetailsModal
            {...this.props}
            {...this.state.customerDetailsModal}
            toggleCustomerDetailsModal={() => this.toggleCustomerDetailsModal()}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(Customers);
