import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  Icon,
  Button,
  Tooltip,
  Modal,
  message,
  Dropdown,
  Menu,
  Alert,
} from "antd";
import { CDN_URL } from "../../../../config";
import "./index.scss";
import moment from "moment-timezone";
import Axios from "axios";
import { connect } from "react-redux";

const paymentStatuses = {
  paid: { text: "Paid", color: "#1AA73B" },
  pending: { text: "Not Paid", color: "#FD7A45" },
  refunded: { text: "Refunded", color: "#ce1126" },
};
const orderStatuses = {
  pending: { text: "Pending", color: "#FFE200" },
  cancelled: { text: "Cancelled", color: "#ce1126" },
  ready: { text: "Ready", color: "#CDDC39" },
  delivered: { text: "Delivered", color: "#1AA73B" },
};
// const orderTypes = {
//   dinein: "Dine-In",
//   takeaway: "Takeaway",
//   delivery: "Delivery",
// };

class OrderCard extends Component {
  state = { order: this.props.order || null };

  markAsPaid() {
    if (this.props.auth.domain.role === "analyst") {
      return;
    }
    Modal.confirm({
      title: "Mark as Paid?",
      content: "Are you sure you want to mark this order as paid?",
      onOk: () => {
        Axios({
          url: "/orders/order_pay_cash",
          method: "post",
          data: {
            order_id: this.state.order.order_id,
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                order: {
                  ...this.state.order,
                  order_payment_status: "paid",
                },
              });
              message.success("Order Updated Successfully!");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((err) => {
            message.error("There was an error!");
            console.log(err);
          });
      },
    });
  }

  updateOrderStatus(status) {
    if (this.props.auth.domain.role === "analyst") {
      return;
    }
    if (!orderStatuses[status]) {
      return;
    }
    const statusName = orderStatuses[status].text;
    Modal.confirm({
      title: `Mark as ${statusName}?`,
      content: `Are you sure you want to mark this order as ${statusName}?`,
      onOk: () => {
        message.loading("Updating Status...", 0);
        Axios({
          url: "/orders/update_order_status",
          method: "post",
          data: {
            order_id: this.state.order.order_id,
            domid: this.props.match.params.domid,
            status,
          },
        })
          .then((res) => {
            message.destroy();
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                order: {
                  ...this.state.order,
                  order_status: status,
                },
              });
              message.success("Order Updated Successfully!");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((err) => {
            message.error("There was an error!");
            console.log(err);
          });
      },
    });
  }

  cancelOrder(amount = 0) {
    const cancelModal = Modal.confirm({
      className: "order-cancel-confirm",
      content: (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="48.994"
            viewBox="0 0 52 48.994"
          >
            <g
              id="Group_2170"
              data-name="Group 2170"
              transform="translate(-3971 371)"
            >
              <path
                id="Path_400"
                data-name="Path 400"
                d="M50.93,52.276,32.551,18.517a7.631,7.631,0,0,0-13.1,0L1.07,52.276a7.608,7.608,0,0,0-.061,7.678,7.517,7.517,0,0,0,6.612,3.839H44.379a7.517,7.517,0,0,0,6.612-3.839A7.608,7.608,0,0,0,50.93,52.276Z"
                transform="translate(3971 -385.8)"
                fill="#3e3e3e"
              />
              <path
                id="Path_402"
                data-name="Path 402"
                d="M75.264,80.784,56.885,47.024a4.585,4.585,0,0,0-7.861,0L30.646,80.784A4.59,4.59,0,0,0,34.576,87.7H71.334a4.59,4.59,0,0,0,3.93-6.916Z"
                transform="translate(3944.045 -412.753)"
                fill="#ffde33"
              />
              <path
                id="Path_404"
                data-name="Path 404"
                d="M229.047,383.294a3.047,3.047,0,1,1,3.047-3.047A3.051,3.051,0,0,1,229.047,383.294Z"
                transform="translate(3767.953 -711.394)"
                fill="#3e3e3e"
              />
              <path
                id="Path_405"
                data-name="Path 405"
                d="M232.094,155.481a3.047,3.047,0,0,1-6.094,0V140.247a3.047,3.047,0,0,1,6.094,0Z"
                transform="translate(3767.953 -495.769)"
                fill="#3e3e3e"
              />
            </g>
          </svg>
          <h3>Cancel Order</h3>
          <p>
            Are you sure that you want to cancel the order:{" "}
            {this.state.order.order_id}
          </p>
          <form
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
            onSubmit={(e) => {
              e.preventDefault();
              message.loading("Processing Refund...", 0);
              Axios({
                url: "/orders/cancel_order",
                method: "post",
                data: {
                  id: this.state.order._id["$id"],
                  domid: this.props.match.params.domid,
                  amount: e.target.amount.value,
                },
              })
                .then((res) => {
                  message.destroy();
                  return res;
                })
                .then((res) => {
                  if (res.data.success === 1) {
                    this.props.reloadList();
                    cancelModal.destroy();
                    message.success("Order Updated Successfully!");
                  } else {
                    message.error(res.data.msg);
                  }
                })
                .catch((err) => {
                  message.error("There was an error!");
                  console.log(err);
                });
            }}
          >
            <div style={{ display: "flex" }}>
              <label style={{ marginRight: 16 }}>Refund Amount:</label>
              <input
                name="amount"
                defaultValue={this.state.order.order_amount}
                style={{ flex: 1 }}
              />
            </div>
            <div>
              <Button onClick={() => cancelModal.destroy()}>Go Back</Button>
              <Button
                htmlType="submit"
                type="primary"
                style={{ marginTop: 10, marginLeft: 10 }}
              >
                Cancel & Refund
              </Button>
            </div>
          </form>
        </div>
      ),
      okButtonProps: { style: { display: "none" } },
      cancelButtonProps: { style: { display: "none" } },
      icon: null,
    });
  }

  render() {
    const order = this.state.order;
    if (!order) {
      return null;
    }
    const itemCount = order.items.reduce((a, b) => a + b.quantity, 0);
    return (
      <Card className="order-card">
        <p className="order-id-label">{order.order_id}</p>
        <Row style={{ marginBottom: 25 }}>
          <Col xs={8}>
            <div className="header-item">
              <span className="label">Type: </span>
              {order.order_type === "dinein" ? (
                <span className="value" style={{ color: "#0068FF" }}>
                  Dine-In {order.station_name}{" "}
                  {order.station_id && `(${order.station_id})`}
                </span>
              ) : null}
              {order.order_type === "takeaway" ? (
                <span className="value" style={{ color: "#0068FF" }}>
                  Takeaway
                </span>
              ) : null}
              {order.order_type === "delivery" ? (
                <span className="value" style={{ color: "#0068FF" }}>
                  Delivery (
                  <a
                    href="#!"
                    onClick={() => {
                      Modal.info({
                        title: "Delivery Address",
                        content: order.order_deliveryaddress
                          .split("\n")
                          .map((l, i) => (
                            <p style={{ margin: 0 }} key={i}>
                              {l}
                            </p>
                          )),
                        onOk() {},
                      });
                    }}
                  >
                    View Address
                  </a>
                  )
                </span>
              ) : null}
            </div>
            <div className="header-item">
              <span className="label">Catalog:</span>
              <span className="value" style={{ color: "#0068FF" }}>
                {order.catalog_name}{" "}
                {order.catalog_id && `(${order.catalog_id})`}
              </span>
            </div>
          </Col>
          <Col xs={7}>
            <div className="header-item">
              <span className="label" style={{ minWidth: 117 }}>
                Payment Status:
              </span>
              <span
                className="value"
                style={{
                  color: paymentStatuses[order.order_payment_status].color,
                }}
              >
                {paymentStatuses[order.order_payment_status].text}
              </span>
            </div>
            <div className="header-item">
              <span className="label" style={{ minWidth: 117 }}>
                Order Status:
              </span>
              <span className="value">
                <span
                  style={{
                    background: orderStatuses[order.order_status].color,
                    width: 14,
                    height: 14,
                    borderRadius: "100%",
                    display: "inline-block",
                    marginRight: 5,
                    verticalAlign: "middle",
                  }}
                />
                {orderStatuses[order.order_status].text}
                <Dropdown
                  disabled={this.props.auth.domain.role === "analyst"}
                  overlay={
                    <Menu onClick={({ key }) => this.updateOrderStatus(key)}>
                      <Menu.Item key="ready">Mark as Ready</Menu.Item>
                      <Menu.Item key="delivered">Mark as Delivered</Menu.Item>
                    </Menu>
                  }
                >
                  <Icon type="down" style={{ fontSize: 10, marginLeft: 5 }} />
                </Dropdown>
              </span>
            </div>
          </Col>
          <Col xs={5}>
            {order.customer.name}
            {order.customer.phone && `(${order.customer.phone})`} <br />
            {moment
              .utc(order.date_created)
              .tz(this.props.tz)
              .format("DD MMM, YYYY HH:mm:ss")}
          </Col>
          <Col xs={4}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginLeft: "auto",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {order.order_payment_status === "paid" ? (
                    <div
                      style={{
                        background: "#1AA73B",
                        color: "white",
                        width: "170px",
                        margin: "0 0 0 auto",
                        textAlign: "center",
                        padding: "8px 12px",
                        fontWeight: 500,
                      }}
                    >
                      <Icon type="check-circle" theme="filled" /> Paid{" "}
                      {order.order_payment_mode === "cash"
                        ? `via Cash`
                        : `Online`}
                    </div>
                  ) : (
                    <Tooltip title="Mark as Paid">
                      <Button
                        style={{
                          background: "#20AAE3",
                          color: "white",
                          width: "170px",
                          display: "block",
                          margin: "0 0 0 auto",
                          textAlign: "center",
                          padding: "8px 12px",
                          fontWeight: 500,
                        }}
                        disabled={this.props.auth.domain.role === "analyst"}
                        onClick={() => this.markAsPaid()}
                      >
                        Payment via Cash
                      </Button>
                    </Tooltip>
                  )}
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item>
                          <a
                            rel="noopener noreferrer"
                            href="#!"
                            onClick={() => this.cancelOrder()}
                          >
                            Cancel Order
                          </a>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Icon style={{ fontSize: "1.5rem" }} type="more" />
                  </Dropdown>
                </div>
                <a
                  href={`http://${this.props.auth.host.domain}/getpdf?orderid=${
                    order._id["$id"]
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download Invoice
                </a>
              </div>
            </div>
          </Col>
        </Row>

        {order.order_instructions && (
          <Alert
            message="Order Instructions"
            description={order.order_instructions}
            type="info"
            style={{ marginBottom: 15 }}
          />
        )}

        <Row style={{ padding: "24px 32px", background: "#F2F5F7" }}>
          <Col xs={4}>
            Total Amount:{" "}
            <span style={{ color: "#0068FF" }}>
              {order.order_currency} {order.order_amount}
            </span>
          </Col>
          <Col xs={4}>
            Total Items: <span style={{ color: "#0068FF" }}>{itemCount}</span>
          </Col>
          <Col xs={4}>
            Delivery Charge:{" "}
            <span style={{ color: "#0068FF" }}>
              {order.order_deliveryamount
                ? `${order.order_currency} ${order.order_deliveryamount}`
                : "NA"}
            </span>
          </Col>
          <Col xs={4}>
            Tip Amount:{" "}
            <span style={{ color: "#0068FF" }}>
              {order.order_tipamount
                ? `${order.order_currency} ${order.order_tipamount}`
                : "NA"}
            </span>
          </Col>
          <Col xs={4}>
            <>
              Discount:{" "}
              <span style={{ color: "#0068FF" }}>
                {order.coupon_amount
                  ? `${order.order_currency} ${order.coupon_amount} (
                  ${order.coupon_code})`
                  : "NA"}
              </span>
            </>
          </Col>
          <Col xs={4}>
            Payment Info: {order.pg_type}{" "}
            <span style={{ color: "#0068FF" }}>
              {order.pg_order_id && `(ID: ${order.pg_order_id})`}
            </span>
          </Col>
        </Row>
        {order.items.map((item, index) => (
          <div>
            <Row
              key={index}
              style={{
                display: "flex",
                padding: "24px 32px",
                background: index % 2 === 0 ? "#E7EBEF" : "#F2F5F7",
              }}
            >
              <Col xs={8}>
                <div style={{ display: "flex" }}>
                  <img
                    alt="ordered product"
                    src={`${CDN_URL}images/${item.image}`}
                    style={{
                      width: 48,
                      height: 48,
                      objectFit: "cover",
                      border: "1px solid black",
                    }}
                  />
                  <p style={{ flex: 1, paddingLeft: 10 }}>
                    <span style={{ fontWeight: 600 }}>{item.name}</span> <br />
                    ID: {item.product_id}
                  </p>
                </div>
              </Col>
              <Col xs={8} style={{ display: "flex", alignItems: "center" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#232324",
                    margin: 0,
                    width: "100%",
                  }}
                >
                  {item.currency} {item.price / item.quantity} X {item.quantity}
                </p>
              </Col>
              <Col xs={8} style={{ display: "flex", alignItems: "center" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#232324",
                    margin: 0,
                    width: "100%",
                  }}
                >
                  {item.currency} {item.price}
                </p>
              </Col>
            </Row>
            {item.addons && Object.keys(item.addons).length
              ? Object.entries(item.addons).map(([_, addonsGroup], idx) => {
                  if (addonsGroup.length <= 0) return null;
                  return (
                    <>
                      <Row>
                        <p>Addons for Item {idx + 1}</p>
                      </Row>
                      {addonsGroup.map((addon) => (
                        <Row
                          style={{
                            padding: "1rem 50px",
                            background: index % 2 === 0 ? "#E7EBEF" : "#F2F5F7",
                          }}
                        >
                          <Col xs={8}>{addon.name}</Col>
                          <Col xs={8} style={{ textAlign: "center" }}>
                            {addon.currency} {addon.price} X 1
                          </Col>
                          <Col xs={8} style={{ textAlign: "center" }}>
                            {addon.currency} {addon.price}
                          </Col>
                        </Row>
                      ))}
                    </>
                  );
                })
              : null}
          </div>
        ))}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});
export default connect(mapStateToProps)(OrderCard);
