import React, { Component } from "react";
import "./index.scss";
import Navbar from "./Partials/Navbar";
import { Layout } from "antd";
import Footer from "./Partials/Footer";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import SwitchAccountModal from "./SwitchAccountModal";
import { ReactComponent as ErrorIcon } from "../../assets/images/icons/no-graph.svg";
import CampaignStepsModal from "../Dashboard/Products/CampaignStepsModal";
import NoData from "./Partials/NoData";
import axios from "axios";
import FeedbackWidget from "./FeedbackWidget";
import NewOrderNotifier from "./NewOrderNotifier";

const { Content } = Layout;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    axios({
      url: "/user/log_error",
      method: "post",
      data: {
        error,
        info,
        domid: this.props.auth.domain.id,
        location: this.props.location.pathname,
      },
    });
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <NoData
          image={ErrorIcon}
          heading="Oops! Something went wrong!"
          content="If you continue to see this error, please contact support"
        />
      );
    }

    return this.props.children;
  }
}

class Dashboard extends Component {
  state = {
    switchAccountModalVisible: false,
    campaignStepsModal: {
      visible: false,
    },
  };

  toggleCampaignStepsModal() {
    this.setState((state) => ({
      campaignStepsModal: {
        visible: !state.campaignStepsModal.visible,
      },
    }));
  }

  constructor(props) {
    super(props);
    document.title = props.title;
  }

  // checkForExhausted() {
  //   if (
  //     this.props.location.pathname !== "/logout" &&
  //     this.props.location.pathname !== "/manage-accounts" &&
  //     this.props.location.pathname !== "/exhausted"
  //   ) {
  //     axios({
  //       url: "/user/check_account_exhausted",
  //       method: "post",
  //       data: {
  //         domid: this.props.auth.domain.id,
  //       },
  //     })
  //       .then((res) => {
  //         if (res.data.exhausted === 1) {
  //           this.props.history.push("/exhausted");
  //         }
  //       })
  //       .catch((e) => {});
  //   }
  // }

  componentDidUpdate() {
    document.title = this.props.title;
    // this.checkForExhausted();
  }

  componentDidMount() {
    // this.checkForExhausted();
    window.googleTranslateElementInit();
  }

  toggleSwitchAccountModal() {
    this.setState({
      switchAccountModalVisible: !this.state.switchAccountModalVisible,
    });
  }

  render() {
    const { children, auth } = this.props;
    const { user, domain, logged_in } = auth;
    let domid = "";
    if (domain && domain.id) {
      domid = domain.id;
    }

    if (!logged_in) return <Redirect to={`/switch-account/${domid}`} />;
    if (!user) return <Redirect to={`/switch-account/${domid}`} />;
    if (
      user.user_domains === undefined ||
      Object.keys(user.user_domains).length === 0
    )
      return <Redirect to={`/switch-account/${domid}`} />;
    if (!domain || !domain.id) return <Redirect to={`/switch-account/`} />;
    if (!this.props.children) {
      if (domain.role === "store_user") {
        return <Redirect to={`/${domain.id}/orders`} />;
      } else {
        return <Redirect to={`/${domain.id}/snapshot`} />;
      }
    }

    if (
      !this.props.location.pathname.match(
        /[a-fA-F0-9]{24}\/autoresponder\/no-access/
      ) &&
      this.props.location.pathname.match(/[a-fA-F0-9]{24}\/autoresponder/) &&
      !auth.plan.plan_features.AUTORESPONDERTECH
    ) {
      return <Redirect to={`/${domain.id}/autoresponder/no-access`} />;
    }

    return (
      <Layout style={{ height: "100%" }}>
        <Navbar
          user={user}
          domain={domain}
          plan={auth.plan}
          loginplan={auth.loginplan}
          toggleCampaignStepsModal={() => this.toggleCampaignStepsModal()}
          toggleSwitchAccountModal={() => this.toggleSwitchAccountModal()}
        />
        <Layout style={{ height: "100%", overflow: "scroll" }}>
          <Content
            style={{
              width: "100%",
              padding: "0 3rem",
              margin: "auto",
              maxWidth: "100%",
              minHeight: "unset",
            }}
          >
            <ErrorBoundary {...this.props}>{children}</ErrorBoundary>
          </Content>
          <Footer centerFooter={this.props.centerFooter} />
        </Layout>
        <SwitchAccountModal
          {...this.props}
          visible={this.state.switchAccountModalVisible}
          handleCancel={() => this.toggleSwitchAccountModal()}
          accounts={Object.values(user.user_domains)}
        />
        <CampaignStepsModal
          {...this.props}
          toggleCampaignStepsModal={() => this.toggleCampaignStepsModal()}
          visible={this.state.campaignStepsModal.visible}
        />
        <FeedbackWidget />
        <NewOrderNotifier {...this.props} domid={domain.id} />
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(Dashboard);
