import React, { Component } from "react";
import MainHeadingBar from "../Partials/MainHeadingBar";
import {
  Row,
  Skeleton,
  Alert,
  Button,
  Dropdown,
  Icon,
  Menu,
  Card,
  Input,
  Pagination,
} from "antd";
import { connect } from "react-redux";
import OrderCard from "./OrderCard";
import { ReactComponent as EmptyStateImage } from "../../../assets/images/noorders.svg";
import "./index.scss";
import Axios from "axios";
const { Search } = Input;

class Orders extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: "",
    orders: [],
    paging: {
      page: 1,
      total: 0,
      pageSize: 30,
      order: -1,
    },
    tz: "UTC",
    filters: {
      search: "",
    },
    stationModal: {
      visible: false,
    },
  };

  onSearch(search) {
    this.setState(
      {
        filters: { search },
        paging: {
          page: 1,
          total: 0,
          pageSize: 30,
          order: -1,
        },
      },
      () => this.fetchOrders()
    );
  }

  fetchOrderRequest() {
    return Axios({
      url: "/storeapi/get_orders",
      method: "post",
      data: {
        page: this.state.paging.page,
        pageSize: this.state.paging.pageSize,
        order: this.state.paging.order,
        domid: this.props.match.params.domid,
        filters: this.state.filters,
      },
    });
  }

  fetchOrders() {
    this.setState(
      {
        loading: true,
      },
      () => {
        this.fetchOrderRequest()
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                orders: res.data.data,
                paging: { ...this.state.paging, total: res.data.total },
                tz: res.data.tz,
              });
            } else {
              this.setState({
                loading: false,
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
              error: true,
              errorMsg: err.message,
            });
          });
      }
    );
  }

  componentDidMount = () => {
    this.fetchOrders();
  };

  goToPage(pagination) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          page: pagination.current,
        },
      },
      () => this.fetchOrders()
    );
  }

  setSortOrder(order) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          order: order === "asc" ? 1 : -1,
        },
      },
      () => this.fetchOrders()
    );
  }

  render() {
    const { loading, error, errorMsg } = this.state;
    const sortMenu = (
      <Menu>
        <Menu.Item key={0}>
          <a href="#/" onClick={() => this.setSortOrder("asc")}>
            Asc. Order
          </a>
        </Menu.Item>
        <Menu.Item key={1}>
          <a href="#/" onClick={() => this.setSortOrder("desc")}>
            Desc. Order
          </a>
        </Menu.Item>
      </Menu>
    );

    const PageWrapper = (children) => (
      <div className="categories-container">
        <MainHeadingBar
          title="Order Listing"
          pageCTA={
            <div style={{ width: "100%" }}>
              <span className="sort">
                Sort By Date:{" "}
                <Dropdown overlay={sortMenu}>
                  <Button size="default">
                    {this.state.paging.order === 1 ? "Asc." : "Desc."} Order{" "}
                    <Icon type="down" />
                  </Button>
                </Dropdown>
              </span>
              <span>
                <Search
                  onSearch={(search) => this.onSearch(search)}
                  style={{
                    display: "inline-block",
                    width: 300,
                    float: "right",
                  }}
                />
              </span>
            </div>
          }
          cta={<span />}
          {...this.props}
        />
        {children}
      </div>
    );

    if (loading) {
      return PageWrapper(
        <Card style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </Card>
      );
    }

    if (!loading && error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }
    if (!loading && !error && !this.state.orders.length > 0) {
      return PageWrapper(
        <Card>
          <div className="order-empty-state" style={{ textAlign: "center" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1, textAlign: "left", paddingLeft: 80 }}>
                <h2>Nothing to show here!</h2>
                <p>
                  Looks like you have not received any
                  <br />
                  orders yet. This page stays empty when
                  <br />
                  There are no orders to show.
                </p>
              </div>
              <div style={{ flex: 1 }}>
                <EmptyStateImage style={{ padding: "23px 0 54px 0" }} />
              </div>
            </div>
          </div>
        </Card>
      );
    }

    const pagination = {
      current: this.state.paging.page,
      pageSize: this.state.paging.pageSize,
      total: this.state.paging.total,
      onChange: (page) => {
        this.goToPage({ current: page });
      },
    };

    return PageWrapper(
      <div className="category-list">
        {this.state.orders.map((o) => (
          <Row key={o._id["$id"]}>
            <OrderCard
              {...this.props}
              tz={this.state.tz}
              order={o}
              reloadList={() => this.fetchOrders()}
            />
          </Row>
        ))}
        <Pagination {...pagination} style={{ textAlign: "center" }} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(Orders);
