import React, { Component } from "react";
import MainHeadingBar from "../Partials/MainHeadingBar";
import {
  Row,
  Skeleton,
  Alert,
  Button,
  Dropdown,
  Icon,
  Menu,
  Card,
  Select,
  Table,
  Switch,
  Tooltip,
  message,
  Modal,
} from "antd";
import { ReactComponent as eye } from "../../../assets/images/icons/eye.svg";
import { connect } from "react-redux";
import "./index.scss";
import { formatNumber } from "../../../helpers/numbers";
import { CDN_URL } from "../../../config";
import Axios from "axios";
import qs from "query-string";
import { Redirect } from "react-router-dom";
import Dragger from "antd/lib/upload/Dragger";
const { Option } = Select;

function AddonsModal({ visible, data, toggleAddonsModal }) {
  const columns = [
    {
      title: "Addon Details",
      dataIndex: "name",
      render: (_, row) => {
        return (
          <div className="product-details">
            <div className="product-info">
              <h4>{row.name}</h4>
              <p>{row.code}</p>
            </div>
          </div>
        );
      },
    },
    {
      title: "Addon Cost",
      dataIndex: "cost",
      render: (cost, row) => (
        <p className="product-cost">
          {row.currency} {formatNumber(cost)}
        </p>
      ),
    },
  ];
  return (
    <Modal
      title="Addons"
      className="product-addons-modal"
      visible={visible}
      footer={null}
      closable={false}
    >
      <Table
        columns={columns || []}
        rowKey={(i) => i.id}
        dataSource={data}
        pagination={false}
      />
      <Button onClick={toggleAddonsModal}>Close</Button>
    </Modal>
  );
}

function ImportProductModal({ visible, domid, toggleImportProductModal }) {
  const props = {
    name: "file",
    accept: "application/csv",
    action: `/storeapi/import_product_csv`,
    listType: "picture",
    className: "upload-list-inline",
    data: {
      domid,
    },
    onChange(info) {
      const status = info.file.status;
      if (status === "done") {
        if (info.file.response.success === 1) {
          message.success(
            `${
              info.file.name
            } file uploaded successfully. Import in Progress...`
          );
          toggleImportProductModal();
        } else {
          message.error(`${info.file.name}: ${info.file.response.msg}`);
        }
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  return (
    <Modal
      title="Import Products from CSV"
      className="product-csv-modal"
      visible={visible}
      onCancel={() => toggleImportProductModal()}
      footer={null}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 15,
        }}
      >
        <a
          href="https://docs.google.com/document/d/1f56K0mx-BBYKIU-EkG6EJ6mRHlzIQes8QLg4T9UCRG8/edit#"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </a>
        <a
          href="/downloads/product_csv"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Sample CSV
        </a>
      </div>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <Icon type="inbox" />
        </p>
        <p className="ant-upload-text">
          Click or drag CSV file to this area to upload
        </p>
      </Dragger>
    </Modal>
  );
}
class Products extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: "",
    products: [],
    paging: {
      page: 1,
      total: 0,
      pageSize: 10,
      order: -1,
    },
    filters: {
      category: "",
    },
    addonsModal: {
      visible: false,
      data: null,
    },
    importProductModal: {
      visible: false,
    },
  };

  toggleImportProductModal() {
    this.setState((prevState) => ({
      importProductModal: {
        ...prevState.importProductModal,
        visible: !prevState.importProductModal.visible,
      },
    }));
  }

  fetchProducts() {
    this.setState(
      {
        loading: true,
      },
      () => {
        Axios({
          url: "/storeapi/get_products",
          method: "post",
          data: {
            filters: this.state.filters,
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
            order: this.state.paging.order,
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                products: res.data.data,
                paging: {
                  ...this.state.paging,
                  total: res.data.total,
                },
              });
            } else {
              this.setState({
                loading: false,
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
              error: true,
              errorMsg: err.message,
            });
          });
      }
    );
  }

  componentDidMount = () => {
    const params = qs.parse(window.location.search) || {};
    this.setState(
      (state) => ({
        filters: {
          ...state.filters,
          ...params,
        },
      }),
      () => {
        this.fetchProducts();
      }
    );
  };

  goToPage(pagination) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          page: pagination.current,
        },
      },
      () => this.fetchProducts()
    );
  }

  setSortOrder(order) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          order: order === "asc" ? 1 : -1,
        },
      },
      () => this.fetchProducts()
    );
  }

  setProductStatus(id, status) {
    Modal.confirm({
      content: (
        <div style={{ textAlign: "center" }}>
          <Icon type="warning" theme="filled" style={{ color: "#FFCC5C" }} />
          <h3 className="heading">
            {status === 1 ? "Enable" : "Disable"} this Product?
          </h3>
          <p className="content">
            Are you sure you want to {status === 1 ? "enable" : "disable"} this
            product?{" "}
            {status === 1
              ? ""
              : "This will prevent this product from showing up in your catalog!"}
          </p>
        </div>
      ),
      className: "product-status-change-confirm-modal",
      icon: <span />,
      centered: true,
      okText: `${status === 1 ? "Enable" : "Disable"} Product`,
      cancelText: "Cancel",
      onOk: () => {
        Axios({
          url: "/storeapi/set_product_status",
          method: "post",
          data: {
            id,
            status,
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState((state) => ({
                products: state.products.map((p) => {
                  if (p._id["$id"] === id) {
                    return { ...p, product_status: status };
                  } else {
                    return p;
                  }
                }),
              }));
              message.success("Status updated successfully!");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((err) => {
            message.error("There was an error!");
            console.log(err);
          });
      },
    });
  }

  deleteProduct(id) {
    Modal.confirm({
      content: (
        <div style={{ textAlign: "center" }}>
          <Icon type="warning" theme="filled" style={{ color: "#FFCC5C" }} />
          <h3 className="heading">Delete this Product?</h3>
          <p className="content">
            Are you sure you want to delete this product? This can not be
            undone.
          </p>
        </div>
      ),
      className: "product-status-change-confirm-modal",
      icon: <span />,
      centered: true,
      okText: `Delete Product`,
      cancelText: "Cancel",
      onOk: () => {
        Axios({
          url: "/storeapi/delete_product",
          method: "post",
          data: {
            id,
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState((state) => ({
                products: state.products.filter((p) => p._id["$id"] !== id),
                paging: { ...state.paging, total: state.paging.total - 1 },
              }));
              message.success("Deleted successfully!");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((err) => {
            message.error("There was an error!");
            console.log(err);
          });
      },
    });
  }

  toggleAddonsModal(data = null) {
    this.setState({
      addonsModal: {
        visible: !this.state.addonsModal.visible,
        data,
      },
    });
  }

  render() {
    const { loading, error, errorMsg } = this.state;
    if (this.props.auth.domain.role === "store_user") {
      return <Redirect to={`/${this.props.auth.domain.id}/orders`} />;
    }
    const sortMenu = (
      <Menu>
        <Menu.Item key={0}>
          <a href="#/" onClick={() => this.setSortOrder("asc")}>
            Asc. Order
          </a>
        </Menu.Item>
        <Menu.Item key={1}>
          <a href="#/" onClick={() => this.setSortOrder("desc")}>
            Desc. Order
          </a>
        </Menu.Item>
      </Menu>
    );

    const PageWrapper = (children) => (
      <div className="product-container">
        <MainHeadingBar
          title="Products"
          pageCTA={
            <span className="sort">
              Sort By Date:{" "}
              <Dropdown overlay={sortMenu}>
                <Button size="default">
                  {this.state.paging.order === 1 ? "Asc." : "Desc."} Order{" "}
                  <Icon type="down" />
                </Button>
              </Dropdown>
            </span>
          }
          cta={
            <>
              <div className="actions">
                <Dropdown.Button
                  overlay={
                    <Menu onClick={() => this.toggleImportProductModal()}>
                      <Menu.Item key="1">Import Products from CSV</Menu.Item>
                    </Menu>
                  }
                  size="large"
                  onClick={() =>
                    this.props.history.push(
                      `/${this.props.match.params.domid}/products/create`
                    )
                  }
                >
                  Add Product
                </Dropdown.Button>
              </div>
            </>
          }
          {...this.props}
        />
        {children}
        <ImportProductModal
          visible={this.state.importProductModal.visible}
          domid={this.props.match.params.domid}
          toggleImportProductModal={() => this.toggleImportProductModal()}
        />
      </div>
    );

    if (loading) {
      return PageWrapper(
        <Card style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </Card>
      );
    }

    if (!loading && error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }
    if (!loading && !error && !this.state.products.length > 0) {
      return PageWrapper(
        <div className="campaign-empty-state">
          <h4>Here for the first time?</h4>
          <p>Looks like you have not created any campaign yet. </p>
          <p>No worries! We've got you covered! </p>
          {this.props.auth.domain.role !== "analyst" && (
            <Button
              className="red-button"
              onClick={() =>
                this.props.history.push(
                  `/${this.props.match.params.domid}/products/create`
                )
              }
            >
              Add Product
            </Button>
          )}
        </div>
      );
    }
    const columns = [
      {
        title: "Product Details",
        dataIndex: "image",
        width: "400px",
        render: (_, row) => {
          const category = row.categories.filter((e, i) => i !== 0);
          return (
            <div className="product-details">
              <img
                src={`${CDN_URL}images/${row.featured_image}`}
                alt="product"
              />
              <div className="product-info">
                <h4>{row.name}</h4>
                <p>{row.code}</p>
                <div className="product-category">
                  {row.categories[0] ? (
                    <p>{row.categories[0]}</p>
                  ) : (
                    <p>No Category Assigned</p>
                  )}
                  {row.categories.length > 1 && (
                    <Select value={`+ ${category.length}`}>
                      {category.map((c, i) => (
                        <Option value={c} className="select-option" key={i}>
                          {c}
                        </Option>
                      ))}
                    </Select>
                  )}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: "Product Description",
        width: "400px",
        dataIndex: "description",
        render: (text) => (
          <div className="product-description">
            <p dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        ),
      },
      {
        title: "Product Cost",
        dataIndex: "cost",
        render: (cost, row) => (
          <p className="product-cost">
            {row.currency} {formatNumber(cost)}
          </p>
        ),
      },
      {
        title: "Actions",
        dataIndex: "product_status",
        render: (status, row) => (
          <div className="product-actions">
            {this.props.auth.domain.role !== "analyst" && (
              <>
                <Switch
                  checked={status === 1 ? true : false}
                  onChange={() =>
                    this.setProductStatus(row._id["$id"], status === 1 ? 0 : 1)
                  }
                />{" "}
                |{" "}
                <Tooltip
                  title="Edit Product"
                  onClick={() =>
                    this.props.history.push(
                      `/${this.props.match.params.domid}/products/edit/${
                        row._id["$id"]
                      }`
                    )
                  }
                >
                  <Icon type="edit" style={{ fontSize: 28 }} />
                </Tooltip>
                |{" "}
                <Tooltip
                  title="Clone Product"
                  onClick={() =>
                    this.props.history.push(
                      `/${this.props.match.params.domid}/products/clone/${
                        row._id["$id"]
                      }`
                    )
                  }
                >
                  <Icon type="copy" style={{ fontSize: 28 }} />
                </Tooltip>
                |{" "}
                <Tooltip
                  title="Delete Product"
                  onClick={() => this.deleteProduct(row._id["$id"])}
                >
                  <Icon type="delete" style={{ fontSize: 28 }} />
                </Tooltip>
                |{" "}
              </>
            )}
            <Tooltip
              title="View Add-Ons"
              onClick={() => this.toggleAddonsModal(row.addons)}
            >
              <Icon component={eye} />{" "}
            </Tooltip>{" "}
          </div>
        ),
      },
    ];

    const pagination = {
      current: this.state.paging.page,
      pageSize: this.state.paging.pageSize,
      total: this.state.paging.total,
    };

    return PageWrapper(
      <div className="product-list">
        <Row gutter={30}>
          <Table
            columns={columns}
            rowKey={(_, i) => i}
            dataSource={this.state.products}
            pagination={{
              ...pagination,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            loading={this.state.loading}
            onChange={(pagination) => this.goToPage(pagination)}
          />
        </Row>
        <AddonsModal
          {...this.state.addonsModal}
          toggleAddonsModal={() => this.toggleAddonsModal()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(Products);
