import React, { Component } from "react";
import MainHeadingBar from "../Partials/MainHeadingBar";
import { Row, Skeleton, Alert, Icon, Card, Table, Modal, message } from "antd";
import { connect } from "react-redux";
import "./index.scss";
import Axios from "axios";

const symbols = {
  star: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.449"
      height="19.5"
      viewBox="0 0 20.449 19.5"
    >
      <g transform="translate(0 -11.877)">
        <path
          className="a"
          fill="#ffdc64"
          d="M19.966,18.921l-6.623-.614L10.711,12.2a.531.531,0,0,0-.975,0L7.106,18.307l-6.623.614a.531.531,0,0,0-.3.927l5,4.39L3.716,30.728A.531.531,0,0,0,4.5,31.3l5.72-3.4,5.72,3.4a.531.531,0,0,0,.789-.573L15.27,24.239l5-4.39A.531.531,0,0,0,19.966,18.921Z"
          transform="translate(0)"
        />
        <path
          className="b"
          fill="#ffc850"
          d="M10.711,12.2a.531.531,0,0,0-.975,0L7.106,18.307l-6.623.614a.531.531,0,0,0-.3.927l5,4.39L3.716,30.728A.531.531,0,0,0,4.5,31.3l1.277-.758a23.409,23.409,0,0,1,6.231-15.324Z"
          transform="translate(0)"
        />
      </g>
    </svg>
  ),

  emoji: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
    >
      <circle class="a" fill="#ffca28" cx="11.5" cy="11.5" r="11.5" />
      <g transform="translate(4.204 5.638)">
        <path
          class="b"
          fill="#6d4c41"
          d="M292.6,131.835a.7.7,0,0,1-.7-.7,1.406,1.406,0,0,0-2.812,0,.7.7,0,1,1-1.406,0,2.812,2.812,0,1,1,5.624,0A.7.7,0,0,1,292.6,131.835Z"
          transform="translate(-279.244 -128.32)"
        />
        <path
          class="b"
          fill="#6d4c41"
          d="M100.6,131.835a.7.7,0,0,1-.7-.7,1.406,1.406,0,0,0-2.812,0,.7.7,0,0,1-1.406,0,2.812,2.812,0,1,1,5.624,0A.7.7,0,0,1,100.6,131.835Z"
          transform="translate(-95.68 -128.32)"
        />
      </g>
      <path
        class="c"
        fill="#fafafa"
        d="M86.523,288.6a.7.7,0,0,0-.56-.28H71.087a.705.705,0,0,0-.678.894,8.433,8.433,0,0,0,16.229,0A.7.7,0,0,0,86.523,288.6Z"
        transform="translate(-67.291 -275.651)"
      />
    </svg>
  ),

  thumbs_up: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="23.893"
      viewBox="0 0 21 23.893"
    >
      <g transform="translate(-31)">
        <path
          class="a"
          fill="#fedbab"
          d="M180.7,101.293a1.4,1.4,0,0,0-1.4-1.4,1.4,1.4,0,1,0,0-2.8h-5.74a12.405,12.405,0,0,0,.84-4.993,2.093,2.093,0,0,0-1.4-1.974,1.94,1.94,0,0,0-.7-.126h-.42a.724.724,0,0,0-.672.532l-.392,1.526c-.588,2.282-2.478,5.035-4.816,5.035v9.8l3.122,1.036a6.979,6.979,0,0,0,2.212.364H177.9a1.4,1.4,0,0,0,0-2.8h1.4a1.4,1.4,0,1,0,0-2.8A1.4,1.4,0,0,0,180.7,101.293Z"
          transform="translate(-128.7 -85.8)"
        />
        <path
          class="b"
          fill="#fec478"
          d="M306.6,108.293a1.4,1.4,0,1,0,0-2.8H308a1.4,1.4,0,0,0,0-2.8,1.4,1.4,0,0,0,0-2.8,1.4,1.4,0,0,0,0-2.8h-5.74a12.4,12.4,0,0,0,.84-4.993,2.093,2.093,0,0,0-1.4-1.974A1.94,1.94,0,0,0,301,90v18.293Z"
          transform="translate(-257.4 -85.8)"
        />
        <g transform="translate(31)">
          <path
            class="c"
            fill="#1689fc"
            d="M35.9,226H31.7a.7.7,0,0,1-.7-.7V212.7a.7.7,0,0,1,.7-.7h4.2a2.1,2.1,0,0,1,2.1,2.1v9.8A2.1,2.1,0,0,1,35.9,226Z"
            transform="translate(-31 -202.107)"
          />
          <circle
            class="c"
            fill="#1689fc"
            cx="0.5"
            cy="0.5"
            r="0.5"
            transform="translate(4 18.88)"
          />
          <path
            class="c"
            fill="#1689fc"
            d="M287.4.7V2.1a.7.7,0,1,1-1.4,0V.7a.7.7,0,1,1,1.4,0Z"
            transform="translate(-274.1)"
          />
          <path
            class="c"
            fill="#1689fc"
            d="M191.732,41.722l-.99-.99a.7.7,0,1,1,.99-.99l.99.99a.7.7,0,0,1-.99.99Z"
            transform="translate(-183.092 -37.692)"
          />
        </g>
        <g transform="translate(43.6)">
          <path
            class="d"
            fill="#136ef1"
            d="M360.458,41.722a.7.7,0,0,1,0-.99l.99-.99a.7.7,0,1,1,.99.99l-.99.99A.7.7,0,0,1,360.458,41.722Z"
            transform="translate(-357.488 -37.692)"
          />
          <path
            class="d"
            fill="#136ef1"
            d="M301.7.7V2.1a.693.693,0,0,1-.7.7V0A.693.693,0,0,1,301.7.7Z"
            transform="translate(-301)"
          />
        </g>
      </g>
    </svg>
  ),
  thumbs_down: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="23.893"
      viewBox="0 0 21 23.893"
      style={{ transform: "rotate(180deg)" }}
    >
      <g transform="translate(-31)">
        <path
          class="a"
          fill="#fedbab"
          d="M180.7,101.293a1.4,1.4,0,0,0-1.4-1.4,1.4,1.4,0,1,0,0-2.8h-5.74a12.405,12.405,0,0,0,.84-4.993,2.093,2.093,0,0,0-1.4-1.974,1.94,1.94,0,0,0-.7-.126h-.42a.724.724,0,0,0-.672.532l-.392,1.526c-.588,2.282-2.478,5.035-4.816,5.035v9.8l3.122,1.036a6.979,6.979,0,0,0,2.212.364H177.9a1.4,1.4,0,0,0,0-2.8h1.4a1.4,1.4,0,1,0,0-2.8A1.4,1.4,0,0,0,180.7,101.293Z"
          transform="translate(-128.7 -85.8)"
        />
        <path
          class="b"
          fill="#fec478"
          d="M306.6,108.293a1.4,1.4,0,1,0,0-2.8H308a1.4,1.4,0,0,0,0-2.8,1.4,1.4,0,0,0,0-2.8,1.4,1.4,0,0,0,0-2.8h-5.74a12.4,12.4,0,0,0,.84-4.993,2.093,2.093,0,0,0-1.4-1.974A1.94,1.94,0,0,0,301,90v18.293Z"
          transform="translate(-257.4 -85.8)"
        />
        <g transform="translate(31)">
          <path
            class="c"
            fill="#1689fc"
            d="M35.9,226H31.7a.7.7,0,0,1-.7-.7V212.7a.7.7,0,0,1,.7-.7h4.2a2.1,2.1,0,0,1,2.1,2.1v9.8A2.1,2.1,0,0,1,35.9,226Z"
            transform="translate(-31 -202.107)"
          />
          <circle
            class="c"
            fill="#1689fc"
            cx="0.5"
            cy="0.5"
            r="0.5"
            transform="translate(4 18.88)"
          />
          <path
            class="c"
            fill="#1689fc"
            d="M287.4.7V2.1a.7.7,0,1,1-1.4,0V.7a.7.7,0,1,1,1.4,0Z"
            transform="translate(-274.1)"
          />
          <path
            class="c"
            fill="#1689fc"
            d="M191.732,41.722l-.99-.99a.7.7,0,1,1,.99-.99l.99.99a.7.7,0,0,1-.99.99Z"
            transform="translate(-183.092 -37.692)"
          />
        </g>
        <g transform="translate(43.6)">
          <path
            class="d"
            fill="#136ef1"
            d="M360.458,41.722a.7.7,0,0,1,0-.99l.99-.99a.7.7,0,1,1,.99.99l-.99.99A.7.7,0,0,1,360.458,41.722Z"
            transform="translate(-357.488 -37.692)"
          />
          <path
            class="d"
            fill="#136ef1"
            d="M301.7.7V2.1a.693.693,0,0,1-.7.7V0A.693.693,0,0,1,301.7.7Z"
            transform="translate(-301)"
          />
        </g>
      </g>
    </svg>
  ),
};

class NPSAnswers extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: "",
    nps: [],
    question: {},
    paging: {
      page: 1,
      total: 0,
      pageSize: 10,
      order: -1,
    },
    aggregate: {},
    catalogs: [],
    npsModal: {
      visible: false,
      data: {},
    },
  };

  toggleNPSModal(data = {}) {
    this.setState({
      npsModal: {
        visible: !this.state.npsModal.visible,
        data,
      },
    });
  }

  fetchNPS() {
    this.setState(
      {
        loading: true,
      },
      () => {
        Axios({
          url: "/storeapi/get_nps_records",
          method: "post",
          data: {
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
            npsid: this.props.match.params.npsid,
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                nps: res.data.data,
                aggregate: res.data.aggregate,
                question: res.data.question,
                paging: { ...this.state.paging, total: res.data.total },
              });
            } else {
              this.setState({
                loading: false,
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
              error: true,
              errorMsg: err.message,
            });
          });
      }
    );
  }

  componentDidMount = () => {
    this.fetchNPS();
  };

  goToPage(pagination) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          page: pagination.current,
        },
      },
      () => this.fetchNPS()
    );
  }

  setNPSStatus(id, status) {
    Modal.confirm({
      content: (
        <div style={{ textAlign: "center" }}>
          <Icon type="warning" theme="filled" style={{ color: "#FFCC5C" }} />
          <h3 className="heading">
            {status === 1 ? "Enable" : "Disable"} This NPS?
          </h3>
          <p className="content">
            Are you sure you want to {status === 1 ? "enable" : "disable"} this
            nps?
          </p>
        </div>
      ),
      className: "product-status-change-confirm-modal",
      icon: <span />,
      centered: true,
      okText: `${status === 1 ? "Enable" : "Disable"} NPS`,
      cancelText: "Cancel",
      onOk: () => {
        Axios({
          url: "/storeapi/toggle_nps_status",
          method: "post",
          data: {
            id,
            status,
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.fetchNPS();
              message.success("Status updated successfully!");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((err) => {
            message.error("There was an error!");
            console.log(err);
          });
      },
    });
  }

  render() {
    const { loading, error, errorMsg } = this.state;

    const PageWrapper = (children) => (
      <div className="categories-container">
        <MainHeadingBar
          title={`NPS Answers ${
            this.state.question.question
              ? `(${this.state.question.question})`
              : ""
          }`}
          cta={<span />}
          {...this.props}
        />
        {children}
      </div>
    );

    if (loading) {
      return PageWrapper(
        <Card style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </Card>
      );
    }

    if (!loading && error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }
    if (!loading && !error && this.state.nps.length <= 0) {
      return PageWrapper(
        <Card>
          <div className="campaign-empty-state" style={{ textAlign: "center" }}>
            <h3>No Data</h3>
            <p>Looks like you have not received any responses yet. </p>
          </div>
        </Card>
      );
    }
    const columns = [
      {
        title: "Customer",
        dataIndex: "customer",
        render: (customer) => {
          return (
            <div>
              <b>{customer.name}</b>
              <p style={{ margin: 0 }}>{customer.email}</p>
              <p style={{ margin: 0 }}>{customer.phone}</p>
            </div>
          );
        },
      },
      {
        title: "Answer",
        dataIndex: "answer",
        render: (answer, row) => {
          if (row.type === "stars") {
            return (
              <div>
                {Array.apply(null, Array(Number(answer))).map(
                  (_, index) => symbols.star
                )}
              </div>
            );
          } else if (row.type === "emoji") {
            return (
              <div>
                {Array.apply(null, Array(Number(answer))).map(
                  (_, index) => symbols.emoji
                )}
              </div>
            );
          } else if (row.type === "rating") {
            return (
              <div
                style={{
                  width: 40,
                  height: 40,
                  color: "white",
                  background: "rgb(16, 71, 151)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {answer}
              </div>
            );
          } else if (row.type === "thumbs") {
            if (Number(answer) === 1) {
              return symbols.thumbs_up;
            } else {
              return symbols.thumbs_down;
            }
          } else {
            return null;
          }
        },
      },
    ];

    const pagination = {
      current: this.state.paging.page,
      pageSize: this.state.paging.pageSize,
      total: this.state.paging.total,
    };

    return PageWrapper(
      <div className="category-list">
        <Row gutter={30} style={{ margin: "0 0 30px 0" }}>
          <h1>Overall Stats</h1>
          <Table
            columns={[
              {
                title: "Answer",
                dataIndex: "answer",
                render: (answer) => {
                  if (this.state.question.type === "stars") {
                    return (
                      <div>
                        {Array.apply(null, Array(Number(answer))).map(
                          (_, index) => symbols.star
                        )}
                      </div>
                    );
                  } else if (this.state.question.type === "emoji") {
                    return (
                      <div>
                        {Array.apply(null, Array(Number(answer))).map(
                          (_, index) => symbols.emoji
                        )}
                      </div>
                    );
                  } else if (this.state.question.type === "rating") {
                    return (
                      <div
                        style={{
                          width: 40,
                          height: 40,
                          color: "white",
                          background: "rgb(16, 71, 151)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {answer}
                      </div>
                    );
                  } else if (this.state.question.type === "thumbs") {
                    if (Number(answer) === 1) {
                      return symbols.thumbs_up;
                    } else {
                      return symbols.thumbs_down;
                    }
                  } else {
                    return null;
                  }
                },
              },
              {
                title: "Count",
                dataIndex: "count",
              },
            ]}
            rowKey={(_, i) => i}
            dataSource={this.state.aggregate}
            pagination={false}
          />
        </Row>
        <Row gutter={30} style={{ margin: "0 0 30px 0" }}>
          <h1>Records</h1>
          <Table
            columns={columns}
            rowKey={(_, i) => i}
            dataSource={this.state.nps}
            pagination={{
              ...pagination,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            loading={this.state.loading}
            onChange={(pagination) => this.goToPage(pagination)}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(NPSAnswers);
