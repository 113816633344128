import React, { Component } from "react";
import "./index.scss";
import { Row, Col, Icon, Card } from "antd";
import { ReactComponent as Rocket } from "../../../../assets/images/icons/rocket.svg";
import { getOverallStats } from "../../../../helpers/ajax/snapshotAjax";
import { formatNumber } from "../../../../helpers/numbers";
import { connect } from "react-redux";

class OverallStats extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: "",
    data: {
      catalogs: 0,
      catalogs_today: 0,
      customers: 0,
      customers_today: 0,
      orders: 0,
      orders_today: 0,
      sales: 0,
      sales_today: 0,
    },
  };

  componentDidMount() {
    getOverallStats(this.props.match.params.domid)
      .then((res) => {
        this.setState({
          loading: false,
          data: {
            ...this.state.data,
            ...res.data.data,
          },
        });
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMsg: e.message,
        });
      });
  }

  render() {
    return (
      <div className="overall-stats">
        <Row gutter={24}>
          <Col xs={24} md={6} className="column">
            <Card
              className="stat-card"
              loading={this.state.loading}
              title={
                <div>
                  <Icon component={Rocket} /> Total Catalogs{" "}
                  <span style={{ float: "right" }}>
                    {formatNumber(this.state.data.catalogs, 0)}
                  </span>
                </div>
              }
            >
              <p>
                <span>Today</span>
                <span style={{ float: "right" }}>
                  {formatNumber(this.state.data.catalogs_today, 0)}
                </span>
              </p>
            </Card>
          </Col>

          <Col xs={24} md={6} className="column">
            <Card
              className="stat-card"
              loading={this.state.loading}
              title={
                <div>
                  <Icon component={Rocket} /> Total Customers{" "}
                  <span style={{ float: "right" }}>
                    {formatNumber(this.state.data.customers, 0)}
                  </span>
                </div>
              }
            >
              <p>
                <span>Today</span>
                <span style={{ float: "right" }}>
                  {formatNumber(this.state.data.customers_today, 0)}
                </span>
              </p>
            </Card>
          </Col>

          <Col xs={24} md={6} className="column">
            <Card
              className="stat-card"
              loading={this.state.loading}
              title={
                <div>
                  <Icon component={Rocket} /> Total Orders{" "}
                  <span style={{ float: "right" }}>
                    {formatNumber(this.state.data.orders, 0)}
                  </span>
                </div>
              }
            >
              <p>
                <span>Today</span>
                <span style={{ float: "right" }}>
                  {formatNumber(this.state.data.orders_today, 0)}
                </span>
              </p>
            </Card>
          </Col>

          <Col xs={24} md={6} className="column">
            <Card
              className="stat-card"
              loading={this.state.loading}
              title={
                <div>
                  <Icon component={Rocket} /> Total Sales{" "}
                  <span style={{ float: "right" }}>
                    {formatNumber(this.state.data.sales, 2)}
                  </span>
                </div>
              }
            >
              <p>
                <span>Today</span>
                <span style={{ float: "right" }}>
                  {formatNumber(this.state.data.sales_today, 2)}
                </span>
              </p>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};
export default connect(mapStateToProps)(OverallStats);
