import React, { Component } from "react";
import {
  Row,
  Skeleton,
  Alert,
  Button,
  Icon,
  Table,
  Tooltip,
  Divider,
  Switch,
  message,
} from "antd";
import { connect } from "react-redux";
import AddWebhookModal from "./AddWebhookModal";
import "./index.scss";
import axios from "axios";

class WebhookSettings extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: "",
    webhooks: [],
    paging: {
      page: 1,
      total: 0,
      pageSize: 10,
      order: -1,
    },
    webhookModal: {
      visible: false,
      data: null,
    },
  };

  fetch() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: "/storeapi/get_webhooks",
          method: "post",
          data: {
            page: this.state.paging.page,
            pageSize: this.state.paging.pageSize,
            order: this.state.paging.order,
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                loading: false,
                webhooks: res.data.data,
              });
            } else {
              this.setState({
                loading: false,
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
              error: true,
              errorMsg: err.message,
            });
          });
      }
    );
  }

  componentDidMount = () => {
    this.fetch();
  };

  goToPage(pagination) {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          page: pagination.current,
        },
      },
      () => this.fetch()
    );
  }

  toggleWebhookModal(data = null) {
    this.setState({
      webhookModal: {
        visible: !this.state.webhookModal.visible,
        data,
      },
    });
  }

  setWebhookStatus(id, status) {
    axios({
      url: "/storeapi/set_webhook_status",
      method: "post",
      data: {
        id,
        status,
        domid: this.props.match.params.domid,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          message.success("Updated Successfully!");
          this.setState({
            webhooks: this.state.webhooks.map((g) => {
              if (g._id["$id"] === id) {
                return { ...g, active: status };
              } else {
                return { ...g };
              }
            }),
          });
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        message.error("There was an error!");
      });
  }

  render() {
    const { loading, error, errorMsg } = this.state;

    if (loading) {
      return <Skeleton active />;
    }

    if (!loading && error) {
      return (
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    const columns = [
      {
        title: "Event Type",
        dataIndex: "type",
        width: "400px",
        render: (type) => {
          const types = {
            ALL: "All",
            ORDER_CREATED: "Order Created",
            ORDER_UPDATED: "Order Status Updated",
            ORDER_PAYMENT_UPDATED: "Order Payment Status Updated",
          };
          return types[type] ? types[type] : type;
        },
      },
      {
        title: "URL",
        dataIndex: "url",
        width: "400px",
      },
      {
        title: "Actions",
        width: "400px",
        dataIndex: null,
        render: (_, row) => (
          <div>
            <Button
              style={{
                background: "#37c627",
                borderColor: "#37c627",
                color: "white",
                marginRight: 15,
              }}
              onClick={() =>
                this.toggleWebhookModal({
                  type: row.type,
                  url: row.url,
                  edit: row._id["$id"],
                })
              }
            >
              Edit
            </Button>
            <Switch
              checked={row.active === 1}
              onChange={(checked) =>
                this.setWebhookStatus(row._id["$id"], Number(checked))
              }
            />{" "}
            {row.active ? "Enabled" : "Disabled"}
          </div>
        ),
      },
    ];

    const pagination = {
      current: this.state.paging.page,
      pageSize: this.state.paging.pageSize,
      total: this.state.paging.total,
    };

    return (
      <div className="user-settings">
        <h3 className="heading">
          <span className="text">
            Webhooks{" "}
            <Tooltip title={`Here you can add and manage your webhooks.`}>
              <Icon type="info-circle" style={{ fontSize: "1.5rem" }} />
            </Tooltip>
          </span>

          {!this.state.loading && this.state.webhooks.length < 3 && (
            <Button
              className="add-user-btn"
              onClick={() => this.toggleWebhookModal()}
            >
              <Icon type="plus" size="large" />
              Add New Webhook
            </Button>
          )}
        </h3>

        <Divider />
        <Table
          columns={columns}
          rowKey={(_, i) => i}
          dataSource={this.state.webhooks}
          pagination={{
            ...pagination,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          loading={this.state.loading}
          onChange={(pagination) => this.goToPage(pagination)}
        />
        <AddWebhookModal
          {...this.props}
          {...this.state.webhookModal}
          reloadList={() => this.fetch()}
          toggleWebhookModal={() => this.toggleWebhookModal()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(WebhookSettings);
