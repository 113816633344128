import React, { Component } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import Login from "./Components/Auth/Login/";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import AddUserSignup from "./Components/Auth/AddUserSignup";
import ResetPassword from "./Components/Auth/ResetPassword";
// import Signup from './Components/Auth/Signup';
import Logout from "./Components/Auth/Logout";
import Dashboard from "./Components/Dashboard";
import Snapshot from "./Components/Dashboard/Snapshot";
import Library from "./Components/Dashboard/Library";
import ProfileForm from "./Components/Dashboard/ProfileSettings";
import ManageAccountsMain from "./Components/Dashboard/ManageAccountsMain";
// import BonusPage from "./Components/Dashboard/BonusPage";
// import Agency from "./Components/Dashboard/Agency";
// import BonusCredentials from "./Components/Dashboard/BonusCredentials";
import Coupons from "./Components/Dashboard/Coupons";
import NotFound404 from "./Components/NotFound404";
import Onboarding from "./Components/Onboarding";
import SignupQuestions from "./Components/SignupQuestions";
import SwitchToAccount from "./Components/Dashboard/SwitchToAccount";
import UserSettings from "./Components/Dashboard/Settings/UserSettings";
import Settings from "./Components/Dashboard/Settings/";
import SearchMedia from "./Components/Dashboard/SearchMedia";

import { connect } from "react-redux";
import { getHostData } from "./store/actions/authActions";
import { CDN_URL } from "./config";
import AutoresponderNoAccess from "./Components/Dashboard/AutoresponderNoAccess";
import Products from "./Components/Dashboard/Products";
import Announcements from "./Components/Dashboard/Announcements";
import CreateProduct from "./Components/Dashboard/Products/CreateProduct";
import Catalog from "./Components/Dashboard/Catalog";
import CreateCatalog from "./Components/Dashboard/Catalog/CreateCatalog";
import Categories from "./Components/Dashboard/Categories";
import Stations from "./Components/Dashboard/Stations";
import Orders from "./Components/Dashboard/Orders";
import Customers from "./Components/Dashboard/Customers";
import NPS from "./Components/Dashboard/NPS";
import AnnouncementTickers from "./Components/Dashboard/AnnouncementTickers";
import NPSAnswers from "./Components/Dashboard/NPSAnswers";
// import QRTool from "./Components/Dashboard/QRTool";
// import DFYCueCards from "./Components/Dashboard/DFYCueCards";
// import DFYCatalog from "./Components/Dashboard/DFYCatalog";

class App extends Component {
  state = {
    loading: true,
  };
  componentDidMount() {
    this.props.getHostDataRequest();
  }

  addScript(src) {
    const s = document.createElement("script");
    if (src.match(/^<script.*src=['"](.*)['"].*><\/script>$/)) {
      s.src = src.match(/^<script.*src=['"](.*)['"].*><\/script>$/)[1];
    } else {
      s.innerHTML = src.replace(/<\/?script.*?>/g, "");
    }
    const body = document.querySelector("body");
    body.appendChild(s);
  }

  componentDidUpdate() {
    if (this.props.auth.host.franchise_name && this.state.loading) {
      document.querySelector(
        "link[rel*='icon']"
      ).href = `${CDN_URL}global/images/${this.props.auth.host.logos.favicon}`;
      if (this.props.auth.host.submitticket_pixel) {
        this.addScript(this.props.auth.host.submitticket_pixel);
      }
      if (this.props.auth.host.livechat_pixel) {
        this.addScript(this.props.auth.host.livechat_pixel);
      }
      if (this.props.auth.host.color_scheme) {
        document.documentElement.style.setProperty(
          "--themecolor",
          this.props.auth.host.color_scheme
        );
      }
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    return (
      <BrowserRouter>
        <div className="content-body">
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={this.props.auth.host.franchise_name}
                />
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/"
              render={(props) => (
                <Dashboard
                  {...props}
                  centerFooter={true}
                  title={`${this.props.auth.host.franchise_name} | Home`}
                />
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/snapshot"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${this.props.auth.host.franchise_name} | Snapshot`}
                >
                  <Snapshot {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/login"
              render={(props) => (
                <Login
                  {...{
                    ...props,
                    title: `${this.props.auth.host.franchise_name} | Login`,
                  }}
                />
              )}
            />
            <Route
              path="/login/forgot/:hash"
              render={(props) => (
                <ResetPassword
                  {...{
                    ...props,
                    title: `${
                      this.props.auth.host.franchise_name
                    } | Reset Password`,
                  }}
                />
              )}
            />
            <Route
              path="/login/adduser/:hash"
              render={(props) => (
                <AddUserSignup
                  {...{
                    ...props,
                    title: `${this.props.auth.host.franchise_name} | Signup`,
                  }}
                />
              )}
            />
            <Route
              path="/forgot-password"
              render={(props) => (
                <ForgotPassword
                  {...{
                    ...props,
                    title: `${
                      this.props.auth.host.franchise_name
                    } | Forgot Password`,
                  }}
                />
              )}
            />
            {/* <Route path='/signup' render={(props) => <Signup {...{ ...props, title: `${this.props.auth.host.franchise_name} | Signup` }} />} />
						<Route path='/signup_invite' render={(props) => <Signup {...{ ...props, title: `${this.props.auth.host.franchise_name} | Signup` }} />} />
						<Route path='/pitchground' render={(props) => <Signup {...{ ...props, title: `${this.props.auth.host.franchise_name} | Signup` }} />} /> */}

            <Route
              exact
              path="/onboarding"
              render={(props) => (
                <Onboarding
                  title={`${this.props.auth.host.franchise_name} | Onboarding`}
                  {...props}
                />
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/adduser"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${this.props.auth.host.franchise_name} | Users`}
                >
                  <UserSettings {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/complete_profile"
              render={(props) => (
                <SignupQuestions
                  title={`${
                    this.props.auth.host.franchise_name
                  } | Complete Profile`}
                  {...props}
                />
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/library"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${this.props.auth.host.franchise_name} | My Library`}
                  openKeys={["submenu_library"]}
                >
                  <Library {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/settings"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${
                    this.props.auth.host.franchise_name
                  } | Profile Settings`}
                >
                  <ProfileForm {...props} />
                </Dashboard>
              )}
            />

            {/* <Route
              exact
              path="/bonus"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${this.props.auth.host.franchise_name} | Bonus`}
                >
                  <BonusPage {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/dfy-cue-cards"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${
                    this.props.auth.host.franchise_name
                  } | DFY Cue Cards`}
                >
                  <DFYCueCards {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/dfy-catalog"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${this.props.auth.host.franchise_name} | DFY Catalog`}
                >
                  <DFYCatalog {...props} />
                </Dashboard>
              )}
            /> */}

            <Route
              path="/:domid([a-fA-F0-9]{24})/library/search-gifs"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${this.props.auth.host.franchise_name} | Search GIFs`}
                  openKeys={["submenu_library"]}
                >
                  <SearchMedia {...props} type="gifs" />
                </Dashboard>
              )}
            />

            {/* <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/agency-toolkit"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${this.props.auth.host.franchise_name} | Agency`}
                >
                  <Agency {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/bonus-access-credentials"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${this.props.auth.host.franchise_name} | Agency`}
                >
                  <BonusCredentials {...props} />
                </Dashboard>
              )}
            /> */}

            <Route
              exact
              path="/manage-accounts"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${
                    this.props.auth.host.franchise_name
                  } | Manage Accounts`}
                >
                  <ManageAccountsMain {...props} />
                </Dashboard>
              )}
            />

            <Route
              path="/switch-account/:domid([a-fA-F0-9]{24})?"
              render={(props) => <SwitchToAccount {...props} />}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/autoresponder/no-access"
              render={(props) => (
                <Dashboard {...props} title={`Autoresponder | Upgrade`}>
                  <AutoresponderNoAccess {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/products"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${this.props.auth.host.franchise_name} | Products`}
                >
                  <Products {...props} />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/categories"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${this.props.auth.host.franchise_name} | Categories`}
                >
                  <Categories {...props} />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/coupons"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${this.props.auth.host.franchise_name} | Coupons`}
                >
                  <Coupons {...props} />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/announcements"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${
                    this.props.auth.host.franchise_name
                  } | Announcements`}
                >
                  <Announcements {...props} />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/stations"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${this.props.auth.host.franchise_name} | Stations`}
                >
                  <Stations {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/customers"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${this.props.auth.host.franchise_name} | Customers`}
                >
                  <Customers {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/orders"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${
                    this.props.auth.host.franchise_name
                  } | Order Listing`}
                >
                  <Orders {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/products/create"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${
                    this.props.auth.host.franchise_name
                  } | Create Product`}
                >
                  <CreateProduct {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/products/edit/:id([a-fA-F0-9]{24})"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${
                    this.props.auth.host.franchise_name
                  } | Edit Product`}
                >
                  <CreateProduct {...props} edit={true} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/products/clone/:id([a-fA-F0-9]{24})"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${
                    this.props.auth.host.franchise_name
                  } | Clone Product`}
                >
                  <CreateProduct {...props} clone={true} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/catalog"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${this.props.auth.host.franchise_name} | Catalog`}
                >
                  <Catalog {...props} />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/catalog/create"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${
                    this.props.auth.host.franchise_name
                  } | Create Catalog`}
                >
                  <CreateCatalog {...props} />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/catalog/edit/:id([a-fA-F0-9]{24})"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${
                    this.props.auth.host.franchise_name
                  } | Edit Catalog`}
                >
                  <CreateCatalog {...props} edit={true} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/nps"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${this.props.auth.host.franchise_name} | NPS`}
                >
                  <NPS {...props} />
                </Dashboard>
              )}
            />

            {/* <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/tools/qrcode"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${
                    this.props.auth.host.franchise_name
                  } | QR Code Generator`}
                >
                  <QRTool {...props} />
                </Dashboard>
              )}
            /> */}

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/nps_answers/:npsid([a-fA-F0-9]{24})"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${this.props.auth.host.franchise_name} | Answers`}
                >
                  <NPSAnswers {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/announcement_tickers"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${
                    this.props.auth.host.franchise_name
                  } | Announcement Tickers`}
                >
                  <AnnouncementTickers {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/settings/:tab?"
              render={(props) => (
                <Dashboard
                  {...props}
                  title={`${this.props.auth.host.franchise_name} | Settings`}
                >
                  <Settings {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/logout"
              render={(props) => <Logout {...props} />}
            />

            <Route path="*" render={() => <NotFound404 />} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHostDataRequest: () => dispatch(getHostData()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
