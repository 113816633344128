import React, { Component } from "react";
import "./index.scss";
import {
  Button,
  Divider,
  Row,
  Col,
  Input,
  Icon,
  Skeleton,
  Alert,
  message,
  Tooltip,
  Switch,
} from "antd";
import { CDN_URL } from "../../../../config";
import LibraryModal from "../../Library/LibraryModal";
import { connect } from "react-redux";
import axios from "axios";
import TextArea from "antd/lib/input/TextArea";

class InvoiceSettings extends Component {
  state = {
    error: false,
    errorMsg: "",
    loading: false,
    saving: false,
    domain: {
      dom_invoice: {
        company_name: "",
        company_logo: "",
        company_address: "",
        company_taxid: "",
        company_invoiceprefix: "",
        tax_settings: {
          enabled: 0,
          tax_value: "",
          tax_inclusive_price: 0,
        },
        sendasemail: {
          enabled: 0,
          smtp_host: "",
          smtp_port: "",
          smtp_username: "",
          smtp_password: "",
          smtp_fromname: "",
          smtp_fromemail: "",
          smtp_replytoemail: "",
        },
      },
    },
    errors: {},
    libraryModal: {
      visible: false,
      tab: "upload",
    },
  };

  toggleLibraryModal(tab = "upload") {
    this.setState((state) => ({
      libraryModal: {
        ...state.libraryModal,
        visible: !state.libraryModal.visible,
        tab,
      },
    }));
  }

  onChange = (k, v) => {
    this.setState((state) => ({
      domain: {
        ...state.domain,
        dom_invoice: {
          ...state.domain.dom_invoice,
          [k]: v,
        },
      },
    }));
  };

  onChangeSMTP = (k, v) => {
    this.setState((state) => ({
      domain: {
        ...state.domain,
        dom_invoice: {
          ...state.domain.dom_invoice,
          sendasemail: {
            ...state.domain.dom_invoice.sendasemail,
            [k]: v,
          },
        },
      },
    }));
  };

  onChangeTax = (k, v) => {
    this.setState((state) => ({
      domain: {
        ...state.domain,
        dom_invoice: {
          ...state.domain.dom_invoice,
          tax_settings: {
            ...state.domain.dom_invoice.tax_settings,
            [k]: v,
          },
        },
      },
    }));
  };

  onChoose(data) {
    this.onChange("company_logo", data.media_filename);
    this.toggleLibraryModal();
  }

  fetchSettings() {
    this.setState(
      {
        loading: true,
      },
      () => {
        axios({
          url: "/storeapi/get_store_settings",
          method: "post",
          data: {
            domid: this.props.match.params.domid,
          },
        })
          .then((res) => {
            this.setState({ loading: false });
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                domain: {
                  ...this.state.domain,
                  ...res.data.data,
                },
              });
            } else {
              this.setState({
                error: true,
                errorMsg: res.data.msg,
              });
            }
          })
          .catch((err) => {
            this.setState({
              error: true,
              errorMsg: "There was an error!",
            });
            console.log(err);
          });
      }
    );
  }

  validate() {
    const settings = this.state.domain.dom_invoice;
    const errors = {};
    if (!settings.company_name) {
      errors.company_name = "This is a required field";
    }
    if (!settings.company_logo) {
      errors.company_logo = "This is a required field";
    }
    if (!settings.company_address) {
      errors.company_address = "This is a required field";
    }
    if (!settings.company_taxid) {
      errors.company_taxid = "This is a required field";
    }
    if (!settings.company_invoiceprefix) {
      errors.company_invoiceprefix = "This is a required field";
    }
    if (settings.tax_settings.enabled === 1) {
      if (!settings.tax_settings.tax_value) {
        errors.tax_value = "This is a required field!";
      }
    }
    if (settings.sendasemail.enabled === 1) {
      if (!settings.sendasemail.smtp_host) {
        errors.smtp_host = "This is a required field!";
      }
      if (!settings.sendasemail.smtp_port) {
        errors.smtp_port = "This is a required field!";
      }
      if (!settings.sendasemail.smtp_username) {
        errors.smtp_username = "This is a required field!";
      }
      if (!settings.sendasemail.smtp_password) {
        errors.smtp_password = "This is a required field!";
      }
      if (!settings.sendasemail.smtp_fromname) {
        errors.smtp_fromname = "This is a required field!";
      }
      if (!settings.sendasemail.smtp_fromemail) {
        errors.smtp_fromemail = "This is a required field!";
      }
      if (!settings.sendasemail.smtp_replytoemail) {
        errors.smtp_replytoemail = "This is a required field!";
      }
    }

    this.setState({
      errors,
    });

    if (Object.keys(errors).length) {
      return false;
    }
    return true;
  }

  saveSettings() {
    if (this.validate()) {
      this.setState(
        {
          saving: true,
        },
        () => {
          axios({
            url: "/storeapi/save_invoice_settings",
            method: "post",
            data: {
              domid: this.props.match.params.domid,
              data: this.state.domain.dom_invoice,
            },
          })
            .then((res) => {
              this.setState({ saving: false });
              return res;
            })
            .then((res) => {
              if (res.data.success === 1) {
                message.success("Settings Saved Successfully!");
              } else {
                message.error(res.data.msg);
              }
            })
            .catch((err) => {
              message.error("There was an error!");
              console.log(err);
            });
        }
      );
    }
  }

  componentDidMount = () => {
    this.fetchSettings();
  };

  render() {
    const { error, errorMsg, loading } = this.state;
    if (loading) {
      return (
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!loading && error) {
      return (
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }
    const domain = this.state.domain.dom_invoice;
    return (
      <div className="account-settings">
        <h3 className="heading">
          <span className="text">
            Invoice Settings{" "}
            <Tooltip title="Here you can manage the invoice settings of your account.">
              <Icon type="info-circle" style={{ fontSize: "1.5rem" }} />
            </Tooltip>
          </span>
          <Button
            type="default"
            onClick={() => this.saveSettings()}
            loading={this.state.saving}
          >
            Save Settings
          </Button>
        </h3>

        <Divider />

        <Row gutter={24}>
          <Col xs={24}>
            <div className="input-container">
              <p className="label">Company Name:</p>
              <Input
                value={domain.company_name}
                onChange={(e) => this.onChange("company_name", e.target.value)}
              />
              {this.state.errors.company_name && (
                <p className="error-label">{this.state.errors.company_name}</p>
              )}
            </div>

            <div className="input-container">
              <p className="label">Select Logo:</p>
              <div className="upload-actions">
                {domain.company_logo && (
                  <img
                    alt="store logo"
                    src={`${CDN_URL}images/${domain.company_logo}`}
                    style={{
                      maxWidth: "7.14rem",
                      marginRight: 10,
                      border: "1px solid #ccc",
                    }}
                  />
                )}
                <div
                  className="upload-action"
                  onClick={() => this.toggleLibraryModal("upload")}
                >
                  <p>
                    <Icon type="upload" style={{ color: "#D9D9D9" }} /> <br />
                    Upload
                  </p>
                </div>
                <div
                  className="upload-action"
                  onClick={() => this.toggleLibraryModal("library")}
                >
                  <p>
                    <Icon type="folder-open" style={{ color: "#D9D9D9" }} />{" "}
                    <br />
                    Library
                  </p>
                </div>
              </div>
              {this.state.errors.company_logo && (
                <p className="error-label">{this.state.errors.company_logo}</p>
              )}
            </div>

            <div className="input-container">
              <p className="label">Company Address:</p>
              <TextArea
                value={domain.company_address}
                onChange={(e) =>
                  this.onChange("company_address", e.target.value)
                }
              />
              {this.state.errors.company_address && (
                <p className="error-label">
                  {this.state.errors.company_address}
                </p>
              )}
            </div>

            <div className="input-container">
              <p className="label">Tax ID:</p>
              <Input
                value={domain.company_taxid}
                onChange={(e) => this.onChange("company_taxid", e.target.value)}
              />
              {this.state.errors.company_taxid && (
                <p className="error-label">{this.state.errors.company_taxid}</p>
              )}
            </div>

            <div className="input-container">
              <p className="label">Invoice Prefix:</p>
              <Input
                value={domain.company_invoiceprefix}
                onChange={(e) =>
                  this.onChange("company_invoiceprefix", e.target.value)
                }
              />
              {this.state.errors.company_invoiceprefix && (
                <p className="error-label">
                  {this.state.errors.company_invoiceprefix}
                </p>
              )}
            </div>

            <div className="input-container">
              <label>
                <Switch
                  checked={domain.tax_settings.enabled === 1}
                  style={{ color: "green" }}
                  onChange={(checked) =>
                    this.onChangeTax("enabled", Number(checked))
                  }
                />{" "}
                Tax Collection is{" "}
                {domain.tax_settings.enabled === 1 ? "Enabled" : "Disabled"}
              </label>
            </div>
            {domain.tax_settings.enabled === 1 && (
              <>
                <div className="input-container">
                  <p className="label">Tax Value:</p>
                  <Input
                    value={domain.tax_settings.tax_value}
                    addonAfter="%"
                    onChange={(e) =>
                      this.onChangeTax("tax_value", e.target.value)
                    }
                  />
                  {this.state.errors.tax_value && (
                    <p className="error-label">{this.state.errors.tax_value}</p>
                  )}
                </div>
                <div className="input-container">
                  <label>
                    <Switch
                      checked={domain.tax_settings.tax_inclusive_price === 1}
                      style={{ color: "green" }}
                      onChange={(checked) =>
                        this.onChangeTax("tax_inclusive_price", Number(checked))
                      }
                    />{" "}
                    Prices are{" "}
                    {domain.tax_settings.tax_inclusive_price === 1
                      ? "inclusive"
                      : "exclusive"}{" "}
                    of tax
                  </label>
                </div>
              </>
            )}

            <div className="input-container">
              <label>
                <Switch
                  checked={domain.sendasemail.enabled === 1}
                  style={{ color: "green" }}
                  onChange={(checked) =>
                    this.onChangeSMTP("enabled", Number(checked))
                  }
                />{" "}
                Send Invoice as Email{" "}
                {domain.sendasemail.enabled === 1 ? "Enabled" : "Disabled"}
              </label>
            </div>
            {domain.sendasemail.enabled === 1 && (
              <>
                <div className="input-container">
                  <p className="label">SMTP Host:</p>
                  <Input
                    value={domain.sendasemail.smtp_host}
                    onChange={(e) =>
                      this.onChangeSMTP("smtp_host", e.target.value)
                    }
                  />
                  {this.state.errors.smtp_host && (
                    <p className="error-label">{this.state.errors.smtp_host}</p>
                  )}
                </div>
                <div className="input-container">
                  <p className="label">SMTP Port:</p>
                  <Input
                    value={domain.sendasemail.smtp_port}
                    onChange={(e) =>
                      this.onChangeSMTP(
                        "smtp_port",
                        e.target.value.replace(/[\D]/g, "")
                      )
                    }
                  />
                  {this.state.errors.smtp_port && (
                    <p className="error-label">{this.state.errors.smtp_port}</p>
                  )}
                </div>
                <div className="input-container">
                  <p className="label">SMTP Username:</p>
                  <Input
                    value={domain.sendasemail.smtp_username}
                    onChange={(e) =>
                      this.onChangeSMTP("smtp_username", e.target.value)
                    }
                  />
                  {this.state.errors.smtp_username && (
                    <p className="error-label">
                      {this.state.errors.smtp_username}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <p className="label">SMTP Password:</p>
                  <Input.Password
                    value={domain.sendasemail.smtp_password}
                    onChange={(e) =>
                      this.onChangeSMTP("smtp_password", e.target.value)
                    }
                  />
                  {this.state.errors.smtp_password && (
                    <p className="error-label">
                      {this.state.errors.smtp_password}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <p className="label">SMTP From Name:</p>
                  <Input
                    value={domain.sendasemail.smtp_fromname}
                    onChange={(e) =>
                      this.onChangeSMTP("smtp_fromname", e.target.value)
                    }
                  />
                  {this.state.errors.smtp_fromname && (
                    <p className="error-label">
                      {this.state.errors.smtp_fromname}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <p className="label">SMTP From Email:</p>
                  <Input
                    value={domain.sendasemail.smtp_fromemail}
                    onChange={(e) =>
                      this.onChangeSMTP("smtp_fromemail", e.target.value)
                    }
                  />
                  {this.state.errors.smtp_fromemail && (
                    <p className="error-label">
                      {this.state.errors.smtp_fromemail}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <p className="label">SMTP Reply To Email:</p>
                  <Input
                    value={domain.sendasemail.smtp_replytoemail}
                    onChange={(e) =>
                      this.onChangeSMTP("smtp_replytoemail", e.target.value)
                    }
                  />
                  {this.state.errors.smtp_replytoemail && (
                    <p className="error-label">
                      {this.state.errors.smtp_replytoemail}
                    </p>
                  )}
                </div>
              </>
            )}
          </Col>
        </Row>

        <LibraryModal
          {...this.props}
          tab={this.state.libraryModal.tab}
          onCancel={() => this.toggleLibraryModal()}
          visible={this.state.libraryModal.visible}
          onChoose={(data) => this.onChoose(data)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(InvoiceSettings);
