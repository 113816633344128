import React, { Component } from "react";
import "./index.scss";
import { Card, Tabs, DatePicker, Skeleton } from "antd";
import { Chart, Geom, Axis, Tooltip } from "bizcharts";
import moment from "moment";
import NoData from "../../Partials/NoData";
import { ReactComponent as NoGraph } from "../../../../assets/images/icons/no-graph.svg";
import {
  getDaywiseOrders,
  getDaywiseSales,
} from "../../../../helpers/ajax/snapshotAjax";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

class Last30DayStats extends Component {
  state = {
    loading: false,
    start: moment().subtract(30, "days"),
    end: moment(),
    orders: {},
    sales: {},
  };

  changeDates(dates) {
    this.setState({ start: dates[0], end: dates[1] }, () => this.fetch());
  }

  fetch() {
    let count = 2;
    this.setState({ loading: true }, () => {
      getDaywiseOrders(
        this.props.match.params.domid,
        this.state.start ? this.state.start.format("YYYY-MM-DD") : undefined,
        this.state.end ? this.state.end.format("YYYY-MM-DD") : undefined
      )
        .then((res) => {
          count--;
          if (count <= 0) {
            this.setState({
              loading: false,
            });
          }
          return res;
        })
        .then((res) => {
          this.setState({
            orders: res.data.data,
          });
        })
        .catch((e) => console.log(e));
      getDaywiseSales(
        this.props.match.params.domid,
        this.state.start ? this.state.start.format("YYYY-MM-DD") : undefined,
        this.state.end ? this.state.end.format("YYYY-MM-DD") : undefined
      )
        .then((res) => {
          count--;
          if (count <= 0) {
            this.setState({
              loading: false,
            });
          }
          return res;
        })
        .then((res) => {
          this.setState({
            sales: res.data.data,
          });
        })
        .catch((e) => console.log(e));
    });
  }

  componentDidMount() {
    this.fetch();
  }

  render() {
    const uniqueOrdersToUse = Object.entries(this.state.orders).map(
      (thisData) => {
        return {
          value: thisData[1],
          date: moment(thisData[0]).format("MMM DD, YYYY"),
        };
      }
    );

    const uniqueOrderCols = {
      date: {
        alias: "date",
        tickCount: 10,
      },
      value: {
        alias: "Orders:",
      },
    };

    const uniqueSalesToUse = Object.entries(this.state.sales).map(
      (thisData) => {
        return {
          value: thisData[1],
          date: moment(thisData[0]).format("MMM DD, YYYY"),
        };
      }
    );

    const uniqueSaleCols = {
      date: {
        alias: "date",
        tickCount: 10,
      },
      value: {
        alias: "Sales:",
      },
    };

    const placeholder = (
      <NoData
        heading="Not enough data!"
        content="We could not find enough data to build a graph!"
        image={NoGraph}
      />
    );
    return (
      <div className="last-30-day-stats">
        <Card>
          <Tabs
            defaultActiveKey="orders"
            tabBarExtraContent={
              <div>
                <RangePicker
                  style={{ marginLeft: "10px" }}
                  defaultValue={[moment().subtract(30, "days"), moment()]}
                  ranges={{
                    Today: [moment(), moment()],
                    "This Week": [
                      moment().startOf("week"),
                      moment().endOf("week"),
                    ],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                    "Last Month": [
                      moment()
                        .subtract(1, "month")
                        .startOf("month"),
                      moment()
                        .subtract(1, "month")
                        .endOf("month"),
                    ],
                  }}
                  onChange={(dates) => this.changeDates(dates)}
                />
              </div>
            }
          >
            <TabPane tab="Daily Orders" key="orders">
              {this.state.loading ? (
                <Skeleton active />
              ) : (
                <Chart
                  height={400}
                  data={uniqueOrdersToUse}
                  scale={uniqueOrderCols}
                  forceFit
                  placeholder={placeholder}
                >
                  <Axis
                    visible={uniqueOrdersToUse.length < 15 ? true : false}
                    name="date"
                    tickLine={{
                      lineWidth: 2,
                      stroke: "#E9E9E9",
                      length: 7,
                    }}
                    line={{
                      stroke: "#E9E9E9",
                    }}
                    label={{
                      formatter: (text, item, index) => {
                        return text.split(",")[0];
                      },
                    }}
                  />
                  <Axis
                    name="value"
                    grid={{
                      align: "bottom",
                      type: "line",
                      lineStyle: {
                        stroke: "#E9E9E9",
                        lineWidth: 1,
                        lineDash: [4, 4],
                      },
                    }}
                  />
                  <Tooltip
                    crosshairs={{
                      type: "y",
                      style: {
                        lineWidth: 2,
                        stroke: "#000",
                        strokeOpacity: 0.65,
                      },
                    }}
                    containerTpl='<div class="g2-tooltip"><p class="g2-tooltip-title"></p><table class="g2-tooltip-list"></table></div>'
                    itemTpl='<tr class="g2-tooltip-list-item"><td style="color:{color}"><span class="g2-tooltip-list-item-dot"></span>{name}</td><td>{value}</td></tr>'
                    offset={50}
                    g2-tooltip={{
                      position: "absolute",
                      visibility: "hidden",
                      border: "1px solid #000",
                      borderRadius: "4px",
                      backgroundColor: "rgba(0, 0, 0, 0.9)",
                      color: "#fff",
                      opacity: "1",
                      padding: "1rem 1.25rem",
                      transition: "top 200ms,left 200ms",
                      fontSize: "1rem",
                    }}
                    g2-tooltip-list={{
                      marginTop: "0.5rem",
                    }}
                  />
                  <Geom
                    type="line"
                    position="date*value"
                    size={2}
                    color="l (270) 0:rgba(255, 47, 47, 1) .5:rgba(255, 47, 47, 1) 1:rgba(255, 47, 47, 1)"
                    style={{
                      shadowColor: "l (270) 0:rgba(21, 146, 255, 0)",
                      shadowBlur: 60,
                      shadowOffsetY: 6,
                    }}
                  />
                </Chart>
              )}
            </TabPane>

            <TabPane tab="Daily Sales" key="sales">
              {this.state.loading ? (
                <Skeleton active />
              ) : (
                <Chart
                  height={400}
                  data={uniqueSalesToUse}
                  scale={uniqueSaleCols}
                  forceFit
                  placeholder={placeholder}
                >
                  <Axis
                    visible={uniqueSalesToUse.length < 15 ? true : false}
                    name="date"
                    tickLine={{
                      lineWidth: 2,
                      stroke: "#E9E9E9",
                      length: 7,
                    }}
                    line={{
                      stroke: "#E9E9E9",
                    }}
                    label={{
                      formatter: (text, item, index) => {
                        return text.split(",")[0];
                      },
                    }}
                  />
                  <Axis
                    name="value"
                    grid={{
                      align: "bottom",
                      type: "line",
                      lineStyle: {
                        stroke: "#E9E9E9",
                        lineWidth: 1,
                        lineDash: [4, 4],
                      },
                    }}
                  />
                  <Tooltip
                    crosshairs={{
                      type: "y",
                      style: {
                        lineWidth: 2,
                        stroke: "#000",
                        strokeOpacity: 0.65,
                      },
                    }}
                    containerTpl='<div class="g2-tooltip"><p class="g2-tooltip-title"></p><table class="g2-tooltip-list"></table></div>'
                    itemTpl='<tr class="g2-tooltip-list-item"><td style="color:{color}"><span class="g2-tooltip-list-item-dot"></span>{name}</td><td>{value}</td></tr>'
                    offset={50}
                    g2-tooltip={{
                      position: "absolute",
                      visibility: "hidden",
                      border: "1px solid #000",
                      borderRadius: "4px",
                      backgroundColor: "rgba(0, 0, 0, 0.9)",
                      color: "#fff",
                      opacity: "1",
                      padding: "1rem 1.25rem",
                      transition: "top 200ms,left 200ms",
                      fontSize: "1rem",
                    }}
                    g2-tooltip-list={{
                      marginTop: "0.5rem",
                    }}
                  />
                  <Geom
                    type="line"
                    position="date*value"
                    size={2}
                    color="l (270) 0:rgba(255, 47, 47, 1) .5:rgba(255, 47, 47, 1) 1:rgba(255, 47, 47, 1)"
                    style={{
                      shadowColor: "l (270) 0:rgba(21, 146, 255, 0)",
                      shadowBlur: 60,
                      shadowOffsetY: 6,
                    }}
                  />
                </Chart>
              )}
            </TabPane>
          </Tabs>
        </Card>
      </div>
    );
  }
}

export default Last30DayStats;
