import React, { Component } from 'react';
import { Input, message, Row, Col, Button } from 'antd';
import { fetchVideo } from '../../../../helpers/ajax/libraryAjax';

class YoutubeS3Uploader extends Component {
  state = {
    loading: true,
    url: '',
    start: 0,
    end: 10,
    saving: false,
    errors: {}
  }

  matchYoutubeUrl(url) {
    // eslint-disable-next-line
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  }

  saveVideo() {
    let errors = {};
    if (!this.state.url.trim().match(/https?:\/\/([\w]+\.)\w+\/?[a-zA-Z0-9-/]*\??.*/)) {
      errors.url = "Please enter a valid URL!";
    }
    if (this.state.start === undefined || this.state.start < 0) {
      errors.start = 'Please enter a valid start time!';
    }
    if (this.state.end === undefined || this.state.end < 0) {
      errors.end = 'Please enter a valid end time!';
    }
    if (parseInt(this.state.end) < parseInt(this.state.start)) {
      errors.end = 'Start time should be less than the end time.';
    }
    if (this.state.end - this.state.start > 10) {
      errors.end = 'Clipped video length cannot be more than 10 seconds.';
    }
    if (Object.keys(errors).length === 0) {
      this.setState({
        errors: {},
        saving: true,
      }, () => {
        let type = "s3";
        let url = this.state.url;
        if (this.matchYoutubeUrl(this.state.url)) {
          type = "youtube";
          url = this.matchYoutubeUrl(this.state.url);
        }
        fetchVideo(this.props.match.params.domid, url, type, this.state.start, this.state.end).then((res) => {
          if (res.data.success === 1) {
            this.props.onChoose(res.data.media);
          } else {
            message.error(res.data.msg);
          }
        })
      });
    } else {
      this.setState({ errors })
    }
  }

  render() {
    return (
      <div className="youtube-s3-uploader">
        <div className={`ant-form-item-control ${this.state.errors.url ? 'has-error' : ''}`}>
          <p className="form-labels">Youtube / S3 URL
          <Input
              value={this.state.url}
              onChange={(e) => { this.setState({ url: e.target.value.match(/https?:\/\/.*/) ? e.target.value : `http://${e.target.value}` }) }} />
          </p>
          {this.state.errors.url && <div className="ant-form-explain">{this.state.errors.url}</div>}
        </div>
        <p style={{ margin: "1em 0 0.5em 0" }}><b>Clip Video</b></p>
        <Row gutter={30}>
          <Col xs={12}>
            <div className={`ant-form-item-control ${this.state.errors.start ? 'has-error' : ''}`}>
              <Input type="number" placeholder="Start Time" addonAfter="Seconds" style={{ width: "100%" }} value={this.state.start} onChange={(e) => this.setState({ start: e.target.value })} />
              {this.state.errors.start && <div className="ant-form-explain">{this.state.errors.start}</div>}
            </div>
          </Col>
          <Col xs={12}>
            <div className={`ant-form-item-control ${this.state.errors.end ? 'has-error' : ''}`}>
              <Input type="number" placeholder="End Time" addonAfter="Seconds" style={{ width: "100%" }} value={this.state.end} onChange={(e) => this.setState({ end: e.target.value })} />
              {this.state.errors.end && <div className="ant-form-explain">{this.state.errors.end}</div>}
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Button className="btn-green" size="large" style={{ width: "100%" }} onClick={() => this.saveVideo()} loading={this.state.saving}>Upload Video</Button>
        </Row>
      </div>
    );
  }

}

export default YoutubeS3Uploader;