import React, { Component } from 'react';
import { Skeleton, Row, Col, Icon } from 'antd';
import { CDN_URL, BASE_URL } from '../../../../config';
import { getLibraryMedia } from '../../../../helpers/ajax/libraryAjax';

class ChooseImageFromLibrary extends Component {
  state = {
    loading: true,
    type: this.props.uploadType ? this.props.uploadType : 'campaigns',
    media: []
  }

  componentDidMount() {
    getLibraryMedia(this.props.match.params.domid, undefined, undefined, this.props.uploadType)
      .then((res) => this.setState(res));
  }

  render() {
    if (this.state.loading) {
      return <Skeleton active />
    }
    return (
      <div className="choose-image-from-library">
        <Row gutter={16}>
          {this.state.media.length > 0 ? (
            this.state.media.map(media => {
              let media_link = '';
              if (media.media_type === "link") {
                media_link = `${BASE_URL}uploads/links/${media.media_filename}`;
              } else if (media.media_type === "image") {
                media_link = `${BASE_URL}uploads/images/${media.media_filename}`;
              } else {
                if (media.processed === 1 || media.processed === '1') {
                  if (media.media_type === "video") {
                    media_link = `${CDN_URL}images/gifs/${media._id}.gif`;
                  } else {
                    media_link = `${CDN_URL}images/thumbs/${media._id}.png`;
                  }
                } else {
                  media_link = `${CDN_URL}global/images/processing.gif`;
                }
              }
              return (
                <Col xs={6} key={media._id}>
                  <div
                    style={{
                      width: "100%",
                      height: 0,
                      paddingTop: "100%",
                      background: `url(${media_link})`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      border: '1px solid rgba(0,0,0,0.15)',
                      marginBottom: '10px',
                      cursor: 'pointer'
                    }}
                    onClick={() => this.props.onChoose(media)} />
                </Col>
              )
            })) : (
              <Col xs={24} style={{ textAlign: "center" }}>
                <p className="ant-upload-drag-icon" style={{ fontSize: "3rem", marginBottom: 0 }}>
                  <Icon type="inbox" size="large" />
                </p>
                <p className="ant-upload-text">No Images Found</p>
              </Col>
            )
          }
        </Row>
      </div>
    )
  }

}

export default ChooseImageFromLibrary;