import React, { Component } from "react";
import "./index.scss";
import {
  Card,
  Button,
  Row,
  Col,
  message,
  Checkbox,
  Icon,
  Skeleton,
  Alert,
} from "antd";
import { connect } from "react-redux";
import { searchMedia, bulkFetch } from "../../../helpers/ajax/libraryAjax";
import { ReactComponent as NoDataIcon } from "../../../assets/images/icons/no_data_found.svg";
import MainHeadingBar from "../Partials/MainHeadingBar";
import { ReactComponent as TenorLogo } from "../../../assets/images/logos/tenor.svg";
import SearchField from "../Partials/SearchField";
const { Meta } = Card;

class SearchMedia extends Component {
  state = {
    type: this.props.type ? this.props.type : "gifs",
    selectedMedia: [],
    media: [],
    next: undefined,
    total: 0,
    loading: true,
    error: false,
    errorMsg: "",
    filters: {
      search: "",
    },
    paging: {
      pageSize: 12,
      page: 1,
    },
  };

  componentWillReceiveProps(props) {
    this.setState(
      {
        type: props.type ? props.type : "gifs",
        selectedMedia: [],
        media: [],
        next: undefined,
        total: 0,
        loading: true,
        error: false,
        errorMsg: "",
        filters: {
          search: "",
        },
        uploading: false,
        paging: {
          pageSize: 12,
          page: 1,
        },
      },
      () => this.fetchLibraryMedia()
    );
  }

  setFilter(k, v) {
    this.setState(
      {
        selectedMedia: [],
        media: [],
        next: undefined,
        loading: true,
        filters: {
          ...this.state.filters,
          [k]: v,
        },
      },
      () => this.fetchLibraryMedia()
    );
  }

  onSelectChange = (selectedMedia) => {
    this.setState({ selectedMedia });
  };

  fetchLibraryMedia() {
    searchMedia(
      this.state.type,
      this.props.match.params.domid,
      this.state.filters.search,
      this.state.paging.page,
      this.state.paging.pageSize,
      this.state.next
    )
      .then((res) => {
        this.setState({
          media: [...this.state.media, ...res.data.images],
          next: res.data.next,
          selectedMedia: [],
          loading: false,
        });
      })
      .catch((e) => {
        return {
          error: true,
          errorMsg: e.message,
          loading: false,
        };
      });
  }

  componentDidMount() {
    this.fetchLibraryMedia();
  }

  importSelectedMedia(ids) {
    this.setState(
      {
        uploading: true,
      },
      () => {
        bulkFetch(this.props.match.params.domid, ids)
          .then((res) => {
            this.setState({
              uploading: false,
            });
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                selectedMedia: [],
              });
              message.success("Image(s) added to library successfully!");
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              uploading: false,
            });
            message.error(e.message);
          });
      }
    );
  }

  onSelect(id) {
    const checked =
      this.state.selectedMedia.findIndex((m) => m === id) > -1 ? false : true;
    if (checked) {
      this.setState({
        selectedMedia: [...this.state.selectedMedia, id],
      });
    } else {
      this.setState({
        selectedMedia: this.state.selectedMedia.filter((m) => m !== id),
      });
    }
  }

  goToNextPage() {
    this.setState(
      {
        loading: true,
        paging: {
          ...this.state.paging,
          page: this.state.paging.page + 1,
        },
      },
      () => this.fetchLibraryMedia()
    );
  }

  render() {
    const PageWrapper = (children) => (
      <div className="library">
        <MainHeadingBar
          title={this.state.type === "gifs" ? "Search GIFs" : "Search Pixabay"}
          helpText={`Here you can search and manage your ${
            this.state.type === "gifs" ? "GIFs" : "Pixabay Images"
          } .`}
          hideCTA
          {...this.props}
        />
        <Card style={{ marginTop: "10px" }}>
          <Row style={{ marginBottom: "2.5rem" }}>
            <Col xs={14}>
              {this.state.selectedMedia.length > 0 && (
                <Button
                  style={{
                    background: "#F84B3D",
                    color: "white",
                    marginLeft: "1rem",
                  }}
                  onClick={() =>
                    this.importSelectedMedia(this.state.selectedMedia)
                  }
                  loading={this.state.uploading}
                >
                  Import Selected
                </Button>
              )}
            </Col>
            <Col xs={10} style={{ textAlign: "right" }}>
              <SearchField
                placeholder={`Search for ${
                  this.state.type === "gifs" ? "gifs" : "icons"
                }`}
                value={this.state.filters.search}
                onChange={(val) =>
                  this.setState({
                    filters: { ...this.state.filters, search: val },
                  })
                }
                onSearch={(value) => this.setFilter("search", value)}
                style={{ width: "20rem", marginLeft: "15px" }}
              />
            </Col>
          </Row>
          {children}
        </Card>
      </div>
    );

    if (this.state.type === "gifs") {
      if (
        this.props.auth &&
        this.props.auth.plan &&
        this.props.auth.plan.plan_features &&
        this.props.auth.plan.plan_features.TENOR !== 1
      ) {
        return (
          <Card style={{ marginTop: 30, textAlign: "center" }}>
            <Icon
              type="exclamation-circle"
              style={{ fontSize: 40, marginBottom: 10 }}
            />
            <h1>Your plan does not allow you to use Tenor GIF Search</h1>
          </Card>
        );
      }
    }
    if (this.state.type === "pixabay") {
      if (
        this.props.auth &&
        this.props.auth.plan &&
        this.props.auth.plan.plan_features &&
        this.props.auth.plan.plan_features.PIXABAY !== 1
      ) {
        return (
          <Card style={{ marginTop: 30, textAlign: "center" }}>
            <Icon
              type="exclamation-circle"
              style={{ fontSize: 40, marginBottom: 10 }}
            />
            <h1>Your plan does not allow you to use Pixabay Image Search</h1>
          </Card>
        );
      }
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <Alert
          message="Error"
          description={this.state.errorMsg}
          type="error"
          showIcon
        />
      );
    }

    if (!this.state.loading && this.state.media.length === 0) {
      return PageWrapper(
        <Row style={{ textAlign: "center" }}>
          <NoDataIcon />
          <p style={{ margin: 0 }}>No Images Found!</p>
        </Row>
      );
    }

    return PageWrapper(
      <React.Fragment>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {this.state.media.map((media) => (
            <Col xs={24} sm={12} md={6} key={media.previewImg}>
              <Card
                style={{ margin: "0 auto 2.9rem auto", cursor: "pointer" }}
                cover={
                  <div
                    style={{
                      background: `url(${media.previewImg})`,
                      height: "100%",
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                }
                onClick={() => this.onSelect(media.previewImg)}
              >
                {this.state.selectedMedia.indexOf(media.previewImg) !== -1 && (
                  <div className="selected-overlay" />
                )}
                <Meta
                  title={
                    <div className="media-meta">
                      <Checkbox
                        size="large"
                        checked={
                          this.state.selectedMedia.indexOf(media.previewImg) !==
                          -1
                        }
                      />
                    </div>
                  }
                />
              </Card>
            </Col>
          ))}
          {this.state.loading && <Skeleton active />}
        </Row>
        {this.state.media.length > 0 && (
          <Row style={{ textAlign: "center" }}>
            <Button type="primary" onClick={() => this.goToNextPage()}>
              Load More {this.state.loading && <Icon type="loading" />}
            </Button>
            {this.state.type === "gifs" && (
              <div className="tenor-logo">
                <TenorLogo />
              </div>
            )}
          </Row>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};
export default connect(mapStateToProps)(SearchMedia);
