import React from 'react';
import ManageAccounts from '../ManageAccounts';
import ManageAccountsPG from '../ManageAccountsPG';
import { connect } from 'react-redux';

const ManageAccountsMain = (props) => {
  return props.auth.user.origin === "PG" ? (<ManageAccountsPG {...props} />) : (<ManageAccounts {...props} />)
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth
  }
}

export default connect(mapStateToProps)(ManageAccountsMain);